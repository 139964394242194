import React from "react"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import { Modal, Button } from "react-bootstrap"
	
const DeleteModal = ({ isShown, handleClose, title, handleDelete,isDeleting }) => (
	<Modal show={isShown} onHide={handleClose} centered className="modal-delete">
		<Modal.Header closeButton>
			<Modal.Title><FormattedMessage id="labels.delete" />?</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<strong>{title}</strong><FormattedMessage id="messages.will_be_deleted" />!
		</Modal.Body>
		<Modal.Footer>
			<Button variant="danger" disabled={isDeleting} onClick={handleDelete}>
				<FormattedMessage id="labels.delete" />
			</Button>
			<Button variant="outline-secondary" onClick={handleClose}>
				<FormattedMessage id="labels.close" />
			</Button>
		</Modal.Footer>
	</Modal>
)

DeleteModal.propTypes = {
	isShown: PropTypes.bool.isRequired,
	isDeleting: PropTypes.bool.isRequired,
	handleClose:PropTypes.func.isRequired,
	handleDelete:PropTypes.func.isRequired,
	title: PropTypes.string.isRequired
}
export default DeleteModal
