import React from "react"
import { Link } from "react-router-dom"

const PublicFormHeader = (props) => (
	<div>
		<div className="logo-section">
			<Link to="/" title="task warp"> <h1>CV<strong>Generator</strong></h1> </Link>
		</div>
	</div>
)
PublicFormHeader.propTypes = {
}
export default PublicFormHeader
