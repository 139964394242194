import { resumes } from "../actions/types"

const defaultResumesData = {
	data: [],
	redirectUrl: null,
	isLoading: true,
	hasError: false,
	error: null,
	isShowing: false,
	isLoadingActions: false,
	isSavingPermissions: false,
	isRedirecting: false,
	isExporting: false,
	isDeleting: false,
	isRestoring:false,
	isValidating:false,
	filters: [],
	meta: {
		pagination: {
			total: 0,
			count: 0,
			per_page: 15,
			current_page: 1,
			total_pages: 1,
		}
	}
}

const resumesReducer = (state = defaultResumesData, action = []) => {
	switch (action.type) {

	case resumes.GET_RESUMES_STARTED:
		return { ...state, isLoading: true }

	case resumes.GET_RESUMES_SUCCESS:
		if (action.payload)
			return { ...state, ...action.payload, isLoading: false }
		return state

	case resumes.GET_RESUMES_ERROR:
		return { ...state, isLoading: false, hasError: true }

	case resumes.SHOW_RESUMES_STARTED:
		return { ...state, isShowing: true }

	case resumes.SHOW_RESUMES_SUCCESS:
		if (!action.payload.id)
			return state
		if (state.data.find(item => (item.id === action.payload.id)))
			return { ...state, isShowing: false, data: state.data.map(item => (item.id === action.payload.id ? action.payload : item)) }
		return { ...state, data: [...state.data, action.payload], isShowing: false }

	case resumes.SHOW_RESUMES_ERROR:
		return { ...state, isShowing: false, hasError: true }

	case resumes.CREATE_RESUMES_STARTED:
		return { ...state, isSaving: true }

	case resumes.CREATE_RESUMES_SUCCESS:
		if (action.payload)
			return {
				...state,
				data: [...state.data, { ...action.payload.data }],
				isSaving: false
			}
		return state

	case resumes.CREATE_RESUMES_ERROR:
		return { ...state, isSaving: false, hasError: true }

	case resumes.DELETE_RESUMES_STARTED:
		return {...state, isDeleting:true}

	case resumes.DELETE_RESUMES_SUCCESS:
		if (action.payload)
			return {...state, data: state.data.filter(item => (item.id !== action.payload.id)), isDeleting: false}
		return state

	case resumes.DELETE_RESUMES_ERROR:
		return { ...state, isDeleting:false, hasError:true }
	default:
		return state
	}
}


export default resumesReducer
