import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Editor } from "@tinymce/tinymce-react";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import { monthAndYearDropdown } from "../../../helpers/dateUtils";
import React, { useEffect, useRef, useState } from "react";
import FormikInput from "../../inputs/FormikInput";
import DeleteModal from "../../modals/DeleteModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const EmployeeExperiences = ({
	isSubmitting,
	errors,
	touched,
	handleChange,
	values,
	setFieldValue,
	setFieldTouched,
}) => {
	const [focused, setFocused] = useState({});
	const [showDelete, setShowDeleteModal] = useState(false);
	const [Selected, setSelected] = useState({}); // to store what edu user wanna delete
	const [monthSelected, setMonthSelected] = useState(null);
	const [yearSelected, setYearSelected] = useState(null);
	const scrollRef = useRef(null);
	const [checkedPresentCourse, setCheckedPresentCourse] = useState(false);
	const [showButtonPlus, setShowButtonPlus] = useState(0);
	useEffect(() => {
		if (values?.experiences?.length > 0) {
			setShowButtonPlus(values.experiences.length - 1);
		} else {
			console.log("Failed showButtonPlus");
		}
	});
	const handleStartDateChange = (id, value, type) => {
		if (value) {
			setFieldValue(
				`${type}[${id}][start_date]`,
				value.format("YYYY-MM-DD")
			);
		} else {
			setFieldValue(`${type}[${id}][start_date]`, value);
		}
	};

	const handleEndDateChange = (id, value, type) => {
		if (value) {
			setFieldValue(
				`${type}[${id}][end_date]`,
				value.format("YYYY-MM-DD")
			);
		} else {
			setFieldValue(`${type}[${id}][end_date]`, value);
		}
	};

	const handleStartDateChangeFocus = (id, focus, type) => {
		setFocused({ ...focused, ...{ [`${type}StartDate${id}`]: focus } });
	};

	const handleEndDateChangeFocus = (id, focus, type) => {
		setFocused({ ...focused, ...{ [`${type}EndDate${id}`]: focus } });
	};

	const handleRemoveData = () => {
		if (values.experiences.length > 0) {
			setShowButtonPlus(showButtonPlus - 1);
		} else {
			setShowButtonPlus(0);
		}
		const valueFields = values[Selected.Type];
		valueFields.splice(Selected.id, 1);
		setFieldValue(Selected.Type, [...valueFields]);
		setShowDeleteModal(false);
	};

	const handleAddExperience = () => {
		if (values?.experiences?.length > 0) {
			setShowButtonPlus(values.experiences.length);
		} else {
			setShowButtonPlus(0);
		}
		const valueFields = values.experiences ? values.experiences : [];

		valueFields.push({
			title: "",
			description: "",
			employer_name: "",
			start_date: "",
			end_date: "",
			present: false,
		});
		setFieldValue("experiences", [...valueFields]);

		setTimeout(() => {
			// window.scroll({
			// 	top: document.body.offsetHeight,
			// 	behavior: "smooth",
			// })
			scrollRef.current.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		}, 50);
	};
	const handleCloseDelete = () => {
		setShowDeleteModal(false);
	};
	const handleChecked = (e, id, type) => {
		setCheckedPresentCourse(e.target.checked);
		if (e.target.checked) {
			setFieldValue(`experiences[${id}].present`, true);
			setFieldValue(`${type}[${id}][end_date]`, null);
		} else {
			setFieldValue(`${type}[${id}][present]`, false);
			setFieldValue(`${type}[${id}][end_date]`, "");
		}
	};
	const handleDrageEnd = (result) => {
		const items = [...values.experiences];
		console.log(items);
		let itemsIDs = items.map((itm) => itm.id);
		console.log(itemsIDs);
		const [removedItem] = items.splice(result.source.index, 1);
		let distnatIndex = result.destination
			? result.destination.index
			: result.source.index;
		items.splice(distnatIndex, 0, removedItem);

		// fore reorder items by id
		for (let i = 0; i < items.length; i++) {
			items[i].id = itemsIDs[i];
		}
		setFieldValue("experiences", items);
	};

	return (
		<>
			{showDelete && (
				<DeleteModal
					isShown={showDelete}
					handleClose={handleCloseDelete}
					title={`Experience ${Selected.id + 1}`}
					handleDelete={handleRemoveData}
					// isDeleting={client_resumes.isDeleting}
				/>
			)}
			<Row>
				<Col>
					<h4 className="respnsiveHeader">
						<FormattedMessage id="labels.experiences" />
					</h4>
				</Col>
				{!values?.experiences?.length && (
					<Col className="mb-3">
						<Button
							onClick={handleAddExperience}
							disabled={isSubmitting}
							variant="outline-primary"
							className="btn-plus"
						>
							+
						</Button>
					</Col>
				)}
			</Row>
			<div
				className={`select-input ${errors.experiences && "is-invalid"}`}
			>
				{" "}
			</div>
			<Form.Control.Feedback type="invalid">
				{errors.experiences}
			</Form.Control.Feedback>

			{!isSubmitting && values?.experiences?.length > 0 && (
				<DragDropContext onDragEnd={handleDrageEnd}>
					<Droppable droppableId="employee-data">
						{(provider) => (
							<div
								className="form__large employee-form employee-data"
								{...provider.droppableProps}
								ref={provider.innerRef}
							>
								{values.experiences.map((experience, id) => {
									return (
										<Draggable
											key={id}
											draggableId={id.toString()}
											index={id}
										>
											{(provider) => (
												<div
													className="counter-spacing skill-block"
													{...provider.draggableProps}
													{...provider.dragHandleProps}
													ref={provider.innerRef}
												>
													<div
														key={`experience-${id}`}
														ref={scrollRef}
														className="counter-spacing"
													>
														<Row className="align-items-bottom mb-3">
															<Col>
																<h5
																	style={{
																		color: "#007bff",
																	}}
																>
																	<FormattedMessage id="labels.experience" />{" "}
																	{id + 1}
																</h5>
															</Col>

															<Col className="col-auto ml-auto">
																<Button
																	onClick={() => {
																		setSelected(
																			{
																				id,
																				Type: "experiences",
																			}
																		);
																		setShowDeleteModal(
																			true
																		);
																	}}
																	variant="danger"
																>
																	<FormattedMessage id="buttons.remove_experience" />
																</Button>
															</Col>
														</Row>
														<Row>
															<Col className="m-a col-6">
																<FormikInput
																	type="text"
																	name={`experiences[${id}].title`}
																	label="Title"
																/>
															</Col>
															<Col className="m-a col-6">
																<FormikInput
																	type="text"
																	name={`experiences[${id}].employer_name`}
																	label="Employer name"
																/>
															</Col>
														</Row>
														<Row>
															<Col>
																<FormikInput
																	type="text"
																	name={`experiences[${id}].location`}
																	label="Location"
																/>
															</Col>
														</Row>
														<Row>
															<Col className="m-a col-12">
																<Form.Group>
																	<Form.Label>
																		Description
																	</Form.Label>
																	<Editor
																		apiKey={
																			process
																				.env
																				.REACT_APP_TINY_API_KEY
																		}
																		value={
																			experience.description
																		}
																		init={{
																			plugins:
																				"paste save code visualblocks visualchars image link media codesample table charmap hr lists advlist anchor autolink preview imagetools noneditable help charmap quickbars",
																			menubar: false,
																			toolbar:
																				"undo redo | styleselect | fontselect fontsizeselect formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
																			toolbar_mode:
																				"sliding",
																		}}
																		onEditorChange={(
																			e
																		) =>
																			setFieldValue(
																				`experiences[${id}][description]`,
																				e
																			)
																		}
																	/>
																</Form.Group>
															</Col>
														</Row>
														<Row>
															<Col className="m-a col-xl-6 col-lg-12 col-sm-6 col-12 calendar">
																<Form.Group
																	controlId="input_birthday"
																	className={`select-input ${
																		!!errors.birthday &&
																		touched.birthday &&
																		"is-invalid"
																	}`}
																>
																	<SingleDatePicker
																		placeholder="Select start date"
																		displayFormat="DD/MM/YYYY"
																		numberOfMonths={
																			1
																		}
																		date={
																			experience &&
																			experience.start_date &&
																			moment(
																				experience.start_date
																			)
																		}
																		id="birthday"
																		openDirection="up"
																		showDefaultInputIcon
																		onDateChange={(
																			e
																		) =>
																			handleStartDateChange(
																				id,
																				e,
																				"experiences"
																			)
																		}
																		focused={
																			focused[
																				`experiencesStartDate${id}`
																			]
																		}
																		navNext={
																			monthSelected ===
																				0 &&
																			yearSelected ===
																				(2024 ||
																					2025 ||
																					2026) ? (
																				<div></div>
																			) : (
																				""
																			)
																		}
																		isOutsideRange={() =>
																			false
																		}
																		onFocusChange={({
																			focused,
																		}) =>
																			handleStartDateChangeFocus(
																				id,
																				focused,
																				"experiences"
																			)
																		}
																		renderMonthElement={({
																			month,
																			onMonthSelect,
																			onYearSelect,
																		}) => {
																			setMonthSelected(
																				month.month()
																			);
																			setYearSelected(
																				month.year()
																			);
																			return monthAndYearDropdown(
																				month,
																				onMonthSelect,
																				onYearSelect,
																				id,
																				"start_date"
																			);
																		}}
																		required
																		readOnly={
																			true
																		}
																	/>
																	<Form.Control.Feedback type="invalid">
																		{
																			errors.birthday
																		}
																	</Form.Control.Feedback>
																</Form.Group>
															</Col>

															<Col className="m-a col-xl-6 col-lg-12 col-sm-6 col-12 calendar">
																{!experience.present && (
																	<Form.Group
																		controlId="input_birthday"
																		className={`select-input ${
																			!!errors.birthday &&
																			touched.birthday &&
																			"is-invalid"
																		}`}
																	>
																		<SingleDatePicker
																			placeholder="Select end date"
																			displayFormat="DD/MM/YYYY"
																			numberOfMonths={
																				1
																			}
																			date={
																				experience &&
																				experience.end_date &&
																				moment(
																					experience.end_date
																				)
																			}
																			id="birthday"
																			openDirection="up"
																			showDefaultInputIcon
																			onDateChange={(
																				e
																			) =>
																				handleEndDateChange(
																					id,
																					e,
																					"experiences"
																				)
																			}
																			focused={
																				focused[
																					`experiencesEndDate${id}`
																				]
																			}
																			navNext={
																				monthSelected ===
																					0 &&
																				yearSelected ===
																					(2024 ||
																						2025 ||
																						2026) ? (
																					<div></div>
																				) : (
																					""
																				)
																			}
																			isOutsideRange={() =>
																				false
																			}
																			onFocusChange={({
																				focused,
																			}) =>
																				handleEndDateChangeFocus(
																					id,
																					focused,
																					"experiences"
																				)
																			}
																			renderMonthElement={({
																				month,
																				onMonthSelect,
																				onYearSelect,
																			}) => {
																				setMonthSelected(
																					month.month()
																				);
																				setYearSelected(
																					month.year()
																				);
																				return monthAndYearDropdown(
																					month,
																					onMonthSelect,
																					onYearSelect,
																					id,
																					"end_date"
																				);
																			}}
																			required
																			readOnly={
																				true
																			}
																		/>
																		<Form.Control.Feedback type="invalid">
																			{
																				errors.birthday
																			}
																		</Form.Control.Feedback>
																	</Form.Group>
																)}
																<Form.Group
																	className="mb-1"
																	controlId={`formBasicCheckbox2${id}`}
																>
																	<Form.Check
																		type="checkbox"
																		checked={
																			experience.present
																		}
																		onChange={(
																			e
																		) =>
																			handleChecked(
																				e,
																				id,
																				"experiences"
																			)
																		}
																		label="Present"
																	/>
																</Form.Group>
															</Col>
															{showButtonPlus ===
																id && (
																<Col className="mb-3">
																	<Button
																		onClick={() =>
																			handleAddExperience(
																				id
																			)
																		}
																		disabled={
																			isSubmitting
																		}
																		variant="outline-primary"
																		className="btn-plus"
																	>
																		+
																	</Button>
																</Col>
															)}
														</Row>
													</div>
												</div>
											)}
										</Draggable>
									);
								})}
								{provider.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			)}
		</>
	);
};

EmployeeExperiences.propTypes = {
	values: PropTypes.shape({
		educations: PropTypes.array,
	}).isRequired,
	errors: PropTypes.shape({
		educations: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		educations: PropTypes.bool,
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	employees: state.employees,
});

export default connect(mapStateToProps, {})(EmployeeExperiences);
