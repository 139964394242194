import cogoToast from "cogo-toast"
import history from "../routes/history"
import ResumesService from "../services/ResumesService"
import { employeeResumes, clientResumes, resumes, employees } from "./types"
import EmployeesService from "../services/EmployeesServices"
import getTransformedFilters from "../helpers/filterTransformer"
import HandleError from "../services/HandleError"
import Axios from "axios"
import { getEmployeesAction } from "./employeesActions"

const resumesService = new ResumesService(this)

const employeesService = new EmployeesService(this)

export const getResumesAction = ({ pageSize, sorted, filters, page }) => async dispatch => {
	const query = `per_page=${pageSize}&${getTransformedFilters(filters)}`
	dispatch({ type: resumes.GET_RESUMES_STARTED })
	try {
		const response = await resumesService.index(page, query)
		dispatch({ type: resumes.GET_RESUMES_SUCCESS, payload: { ...response.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: resumes.GET_RESUMES_ERROR })
	}
}

export const showResumeAction = (id, handleFinish) => async dispatch => {
	dispatch({ type: resumes.SHOW_RESUMES_STARTED })
	try {
		const response = await resumesService.show(id)
		dispatch({ type: resumes.SHOW_RESUMES_SUCCESS, payload: { ...response.data.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: resumes.SHOW_RESUMES_ERROR })
	} finally {
		handleFinish()
	}
}

export const createResumeAction = (values, id = null, type = null, datesError) => async dispatch => {
	console.log("CREATE RESUME ERROR", datesError)
	if (!datesError) {
		dispatch({ type: resumes.CREATE_RESUMES_STARTED })
		console.log("FROM CREATW RESUME ACTION")
		// console.log({REsumeValues:values})
		try {
			const response = await resumesService.store(values, true )

			dispatch({ type: resumes.CREATE_RESUMES_SUCCESS, payload: response.data })

			if (type) {
				history.push(`/${type}/${id}/resumes`) // When user create a relation between client and an employee on Employee page, after creation user redirect on Atis Resumes page. User should redirect to View resume page of respective employee

			} else {
				history.push("/resumes")
			}
			cogoToast.success("Resume created successfully.", { position: "top-center" })

		} catch (err) {
			dispatch({ type: resumes.CREATE_RESUMES_ERROR })

			HandleError(err)
		}
	} else {
		dispatch({ type: resumes.CREATE_RESUMES_ERROR })
		cogoToast.error("Start date must be before end date!", { position: "top-right" })
	}
}

export const updateResumeAction = (datesError, id, values, setSubmitting, setErrors , props, typeId = null, type = null ) => async dispatch => {
	console.log("FROM UPDATE RESUME ACTION", datesError)
	if (!datesError) {
		dispatch({ type: resumes.UPDATE_RESUMES_STARTED })
		try {
			const response = await resumesService.update(id, values)
			dispatch({ type: resumes.UPDATE_RESUMES_SUCCESS, payload: { ...response.data } })
			if (type) {
				history.push(`/${type}/${id}/resumes`)
			} else if (props.CurrentNav === 'clients'){
				history.push(`/clients/${props.fromOther.client_id}/resumes`)
			} else if(history.location.pathname.includes("/employees/resume")) {
				history.push(`/employees/${props.id}/resumes`)
			}else {
				history.push("/resumes")
			}

			cogoToast.success("Resume saved successfully.", { position: "top-center" })
		} catch (err) {
			HandleError(err, setErrors)
			dispatch({ type: resumes.UPDATE_RESUMES_ERROR })
		} finally {
			setSubmitting(false)
		}
	} else {

		dispatch({ type: resumes.UPDATE_RESUMES_ERROR })
		setSubmitting(false)
		cogoToast.error("Start date must be before end date!", { position: "top-right" })
	}

}

export const ArchiveResume = (id , handleFinish) => async dispatch => {
	dispatch({type:employees.ARCHIVE_EMPLOYEES_STARTED});
	try {
		await employeesService.Archive(id).then(Res => console.log(Res));
		handleFinish();
		dispatch({ type: employees.ARCHIVE_EMPLOYEES_SUCCESS, payload: { id } });
		cogoToast.success("Employee archived successfully.", { position: "top-center" })

	} catch (err) {
		handleFinish()
		HandleError(err)
		dispatch({ type: employees.ARCHIVE_EMPLOYEES_ERROR })
	}
}

export const UnArchiveResume = (id , handleFinish) => async dispatch => {
	console.log({id});
	dispatch({type:employees.UNARCHIVE_EMPLOYEES_STARTED});
	try {
		await employeesService.UnArchive(id).then(Res => console.log(Res));
		handleFinish();
		dispatch({ type: employees.UNARCHIVE_EMPLOYEES_SUCCESS, payload: { id } });
		cogoToast.success("Employee unarchived successfully.", { position: "top-center" })

	} catch (err) {
		handleFinish()
		HandleError(err)
		dispatch({ type: employees.UNARCHIVE_EMPLOYEES_ERROR })
	}
}

export const deleteResumeAction = (id, handleFinish, typeComponent=false) => async dispatch => {

	if(typeComponent) {

		dispatch({ type: employees.DELETE_EMPLOYEES_STARTED })
		try {
			await employeesService.delete(id)
			handleFinish()
			dispatch({ type: employees.DELETE_EMPLOYEES_SUCCESS, payload: { id } })
			cogoToast.success("Employee deleted successfully.", { position: "top-center" })
		} catch (err) {
			handleFinish()
			HandleError(err)
			dispatch({ type: employees.DELETE_EMPLOYEES_ERROR })
		}	
	} else {

		dispatch({ type: resumes.DELETE_RESUMES_STARTED })
		console.log("FROM DELETE RESUME ACTION")
		try {
			await resumesService.delete(id)
			handleFinish()
			dispatch({ type: resumes.DELETE_RESUMES_SUCCESS, payload: { id } })
			dispatch({ type: clientResumes.DELETE_CLIENT_RESUMES_SUCCESS, payload: { id } })
			dispatch({ type: employeeResumes.DELETE_EMPLOYEE_RESUMES_SUCCESS, payload: { id } })
			cogoToast.success("Resume deleted successfully.", { position: "top-center" })
		} catch (err) {
			handleFinish()
			HandleError(err)
			dispatch({ type: resumes.DELETE_RESUMES_ERROR })
		}
	}

	
}

// advanced search by area , skills , languages 
export const AdvancedSearch = (area=null , skills=null ,languages=null,countries=null, pageSizeFilter=15 ) => async dispatch =>  {
	dispatch({ type: employees.GET_EMPLOYEES_STARTED });
	
	try {
		console.log("area", area);
		console.log("skills", skills);
		console.log("languages", languages);
		console.log("countries", countries);

		if(area  || skills || (languages.length > 0) || (countries.length > 0)) {
			let response = await Axios.post(`${process.env.REACT_APP_API_URL}/employees/search`,{
				areas:area?[area.name]:[],
				skills:skills?skills.map(skill => skill.name) :[],
				languages:languages?languages.map(lang => lang.name):[],
				country: countries?countries.map(count => count.label):[]
			});
			console.log(response)
			dispatch({ type: employees.GET_EMPLOYEES_SUCCESS, payload: { ...response } });
		} else {
			let deleted = false;
			let archive = false;
			let query = `per_page=${pageSizeFilter}&${getTransformedFilters([])}${archive?"archive=true":"archive=false"}${deleted!== undefined ? deleted === false ? '&deleted=false' : '&deleted=true': ''}`;

			const response = await employeesService.index(1, query);
			dispatch({ type: employees.GET_EMPLOYEES_SUCCESS, payload: { ...response.data } })
		// getEmployeesAction({ pageSize: 15, sorted: null, filters: [], page: 1 , archive: false ,deleted: false })
		}
	} catch (error) {
		HandleError(error);
		dispatch({ type: employees.GET_EMPLOYEES_ERROR })
	}
}

export const FilterEmployees = (inputValues , archived) => async dispatch => {
	dispatch({ type: employees.GET_EMPLOYEES_STARTED });
	console.log("inputValues", inputValues);
	let firstName = inputValues.find(first => first.id == 'first_name');
	let lastname = inputValues.find(last => last.id == 'last_name' );
	let email = inputValues.find(email => email.id == 'email' );
	let skill_id = inputValues.find(skill => skill.id == 'skill_id' );
	let employeelevel = inputValues.find(level => level.id == 'employee_level' );

	try {
		const response = await	Axios.get(`${process.env.REACT_APP_API_URL}/employees?show_archive=${archived}&first_name=${firstName?firstName.value:""}&last_name=${lastname?lastname.value:""}&email=${email?email.value:""}&employee_level=${employeelevel?employeelevel.value:""}&skill_id=${skill_id?skill_id.value:""}`)
		if(history.location.pathname.includes('archive')) {
			const filterSear = response.data.filter(date => date.deleted === false)
			console.log("filterSear", filterSear);
			console.log("response", response);
		dispatch({ type: employees.GET_EMPLOYEES_SUCCESS, payload: { ...response, data: filterSear, filterClick: true } });
		} else {

			dispatch({ type: employees.GET_EMPLOYEES_SUCCESS, payload: { ...response, filterClick: true } });
			console.log({...response})
		}
	} catch (error) {
		HandleError(error);
		dispatch({ type: employees.GET_EMPLOYEES_ERROR })
	}
}

export const searchEmployees = (inputValue) => async () => {
	try {
		const response = await employeesService.search(inputValue || " ")
		return response.data.map((item) => ({
			id: item.id,
			full_name: item.full_name,
		}))
	} catch (error) {
		HandleError(error)
	}
}
