import moment from "moment";
import React from "react";

export const isBeforeDay = (a, b) => {
	if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

	const aYear = a.year();
	const aMonth = a.month();

	const bYear = b.year();
	const bMonth = b.month();

	const isSameYear = aYear === bYear;
	const isSameMonth = aMonth === bMonth;

	if (isSameYear && isSameMonth) return a.date() <= b.date();
	if (isSameYear) return aMonth < bMonth;
	return aYear < bYear;
};

export const isInclusivelyAfterDay = (a, b) => {
	if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
	return !isBeforeDay(a, b);
};

const returnYears = () => {
	let years = [];
	for (let i = moment().year() - 100; i <= moment().year() + 1; i++) {
		years.push(<option value={i}>{i}</option>);
	}
	return years;
};

export const monthAndYearDropdown = (
	month,
	onMonthSelect,
	onYearSelect,
	id,
	dateType
) => {
	return (
		<div
			style={{ display: "flex", justifyContent: "center" }}
			key={`${dateType}${id}`}
		>
			<div>
				<select
					value={month.month()}
					onChange={(e) => {
						onMonthSelect(month, e.target.value);
					}}
				>
					{moment.months().map((label, value) => (
						<option value={value}>{label}</option>
					))}
				</select>
			</div>
			<div>
				<select
					value={month.year()}
					onChange={(e) => onYearSelect(month, e.target.value)}
				>
					{returnYears()}
				</select>
			</div>
		</div>
	);
};
