import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { showLanguageAction } from "../../actions/languagesActions"
import LanguageForm from "../../components/forms/LanguageForm"
import MaterialIcon from "../../components/elements/MaterialIcon"

class LanguageEditPage extends Component {

	state = {
		itemLanguagesIndex: -1
	}

	componentDidMount() {

		const { match, history } = this.props

		if (!match.params.id)
			history.push("/languages")

		this.props.showLanguageAction(match.params.id, this.handleFinish)
	}

	handleFinish = () => {
		const { languages, match, history } = this.props
		const itemLanguagesIndex = languages.data.findIndex(item => (item.id === parseInt(match.params.id, 10)))
		if (itemLanguagesIndex === -1)
			history.push("/languages")

		this.setState({ itemLanguagesIndex })
	}

	render() {
		const { itemLanguagesIndex } = this.state
		const { languages, match } = this.props
		const languageData = itemLanguagesIndex !== -1 ? languages.data[itemLanguagesIndex] : null
		return (
			<div className="client-edit-page private-page">
				<div className="top-actions-row">
					<Link to="/languages" className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /><FormattedMessage id="buttons.back_to_language" /></Link>
				</div>
				<Card className="client-card fadeInLoadUp">
					<Card.Body>
						<LanguageForm language={languageData} id={match.params.id} />
					</Card.Body>
				</Card>
			</div>
		)
	}

}
LanguageEditPage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string
		})
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func
	}).isRequired,
	showLanguageAction: PropTypes.func.isRequired,
	languages: PropTypes.shape({
		isLoading: PropTypes.bool,
		data: PropTypes.array
	}).isRequired,
}

const mapStateToProps = (state) => ({
	languages: state.languages,
})

export default connect(mapStateToProps, { showLanguageAction })(LanguageEditPage)
