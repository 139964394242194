import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Editor } from "@tinymce/tinymce-react";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import { connect } from "react-redux";
import { monthAndYearDropdown } from "../../../helpers/dateUtils";
import FormikInput from "../../inputs/FormikInput";
import PropTypes from "prop-types";
import DeleteModal from "../../modals/DeleteModal";

const EmployeeCourses = ({
	isSubmitting,
	errors,
	touched,
	handleChange,
	values,
	setFieldValue,
	setFieldTouched,
}) => {
	const [focused, setFocused] = useState({});
	const [showDelete, setShowDeleteModal] = useState(false);
	const [Selected, setSelected] = useState({}); // to store what edu user wanna delete
	const [monthSelected, setMonthSelected] = useState(null);
	const [yearSelected, setYearSelected] = useState(null);
	const [checkedPresentCourse, setCheckedPresentCourse] = useState(false);
	const [showButtonPlus, setShowButtonPlus] = useState(0);

	useEffect(() => {
		if (values?.courses?.length > 0) {
			setShowButtonPlus(values.courses.length - 1);
		} else {
			console.log("Failed showButtonPlus");
		}
	});
	const scrollRef = useRef(null);

	const handleStartDateChange = (id, value, type) => {
		if (value) {
			setFieldValue(
				`${type}[${id}][start_date]`,
				value.format("YYYY-MM-DD")
			);
			console.log("id", id, "value", value, "type", type);
		} else {
			setFieldValue(`${type}[${id}][start_date]`, value);
		}
	};

	const handleEndDateChange = (id, value, type) => {
		if (value) {
			setFieldValue(
				`${type}[${id}][end_date]`,
				value.format("YYYY-MM-DD")
			);
		} else {
			setFieldValue(`${type}[${id}][end_date]`, value);
		}
	};

	const handleStartDateChangeFocus = (id, focus, type) => {
		setFocused({ ...focused, ...{ [`${type}StartDate${id}`]: focus } });
	};

	const handleEndDateChangeFocus = (id, focus, type) => {
		setFocused({ ...focused, ...{ [`${type}EndDate${id}`]: focus } });
	};

	const handleRemoveData = () => {
		if (values.courses.length > 0) {
			setShowButtonPlus(showButtonPlus - 1);
		} else {
			setShowButtonPlus(0);
		}
		const valueFields = values[Selected.Type];
		valueFields.splice(Selected.id, 1);
		setFieldValue(Selected.Type, [...valueFields]);
		setShowDeleteModal(false);
	};

	const handleAddCourse = (id) => {
		if (values?.courses?.length > 0) {
			setShowButtonPlus(values.courses.length);
		} else {
			setShowButtonPlus(0);
		}
		const valueFields = values.courses ? values.courses : [];

		valueFields.push({
			description: "",
			title: "",
			start_date: "",
			end_date: "",
			present: false,
		});
		setFieldValue("courses", [...valueFields]);

		setTimeout(() => {
			scrollRef.current.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		}, 50);
	};
	const handleCloseDelete = () => {
		setShowDeleteModal(false);
	};
	const handleChecked = (e, id, type) => {
		setCheckedPresentCourse(e.target.checked);
		if (e.target.checked) {
			setFieldValue(`courses[${id}].present`, true);
			setFieldValue(`${type}[${id}][end_date]`, null);
		} else {
			setFieldValue(`${type}[${id}][present]`, false);
			setFieldValue(`${type}[${id}][end_date]`, "");
		}
	};
	return (
		<>
			{showDelete && (
				<DeleteModal
					isShown={showDelete}
					handleClose={handleCloseDelete}
					title={`Course ${Selected.id + 1}`}
					handleDelete={handleRemoveData}
					// isDeleting={client_resumes.isDeleting}
				/>
			)}
			<Row>
				<Col>
					<h4 className="respnsiveHeader">
						<FormattedMessage id="labels.courses" />
					</h4>
				</Col>
				{!values?.courses?.length && (
					<Col className="mb-3">
						<Button
							onClick={handleAddCourse}
							disabled={isSubmitting}
							variant="outline-primary"
							className="btn-plus"
						>
							+
						</Button>
					</Col>
				)}
			</Row>
			<div className={`select-input ${errors.courses && "is-invalid"}`}>
				{" "}
			</div>
			<Form.Control.Feedback type="invalid">
				{errors.courses}
			</Form.Control.Feedback>

			{!isSubmitting && values?.courses?.length > 0 && (
				<div className="form__large employee-form employee-data">
					<div>
						{values.courses.map((course, id) => {
							console.log(course);
							return (
								<div
									key={`course-${id}`}
									ref={scrollRef}
									className="counter-spacing"
								>
									<Row className="align-items-bottom mb-3">
										<Col>
											<h5 style={{ color: "#007bff" }}>
												<FormattedMessage id="labels.course" />{" "}
												{id + 1}
											</h5>
										</Col>
										<Col className="col-auto ml-auto">
											<Button
												onClick={() => {
													setSelected({
														id,
														Type: "courses",
													});
													setShowDeleteModal(true);
												}}
												variant="danger"
											>
												<FormattedMessage id="buttons.remove_course" />
											</Button>
										</Col>
									</Row>
									<Row>
										<Col className="m-a col-12">
											<FormikInput
												type="text"
												name={`courses[${id}].title`}
												label="Course name"
												required
											/>
										</Col>
									</Row>
									<Row>
										<Col className="m-a col-12">
											<FormikInput
												type="text"
												name={`courses[${id}].location`}
												label="Course location"
												required
											/>
										</Col>
									</Row>
									{/* <Row>
                                            <Col className="m-a col-6">
                                                <FormikInput type="text" name={`courses[${id}].degree`} label="Degree" required />
                                            </Col>
                                            <Col className="m-a col-6">
                                                <FormikInput type="text" name={`courses[${id}].field`} label="Field" required />
                                            </Col>
                                        </Row> */}
									<Row>
										<Col className="m-a col-12">
											<Form.Group>
												<Form.Label>
													Description
												</Form.Label>
												<Editor
													apiKey={
														process.env
															.REACT_APP_TINY_API_KEY
													}
													value={course.description}
													init={{
														plugins:
															"paste save code visualblocks visualchars image link media codesample table charmap hr lists advlist anchor autolink preview imagetools noneditable help charmap quickbars",
														menubar: false,
														toolbar:
															"undo redo | styleselect | fontselect fontsizeselect formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
														toolbar_mode: "sliding",
													}}
													onEditorChange={(e) =>
														setFieldValue(
															`courses[${id}][description]`,
															e
														)
													}
												/>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col className="m-a col-xl-6 col-lg-12 col-sm-6 col-12 calendar">
											<Form.Group
												controlId="input_birthday"
												className={`select-input ${
													!!errors.birthday &&
													touched.birthday &&
													"is-invalid"
												}`}
											>
												<SingleDatePicker
													placeholder="Select start date"
													displayFormat="DD/MM/YYYY"
													numberOfMonths={1}
													date={
														course &&
														course.start_date &&
														moment(
															course.start_date
														)
													}
													id="birthday"
													showDefaultInputIcon
													onDateChange={(e) =>
														handleStartDateChange(
															id,
															e,
															"courses"
														)
													}
													focused={
														focused[
															`coursesStartDate${id}`
														]
													}
													isOutsideRange={() => false}
													navNext={
														monthSelected === 0 &&
														yearSelected ===
															(2024 ||
																2025 ||
																2026) ? (
															<div></div>
														) : (
															""
														)
													}
													openDirection="up"
													// renderNavNextButton = {() => }
													// keepOpenOnDateSelect={() => true}
													onFocusChange={({
														focused,
													}) => {
														handleStartDateChangeFocus(
															id,
															focused,
															"courses"
														);
														console.log(focused);
													}}
													renderMonthElement={({
														month,
														onMonthSelect,
														onYearSelect,
													}) => {
														setMonthSelected(
															month.month()
														);
														setYearSelected(
															month.year()
														);
														return monthAndYearDropdown(
															month,
															onMonthSelect,
															onYearSelect,
															id,
															"start_date"
														);
													}}
													required
													readOnly={true}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.birthday}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>

										<Col className="m-a col-xl-6 col-lg-12 col-sm-6 col-12 calendar">
											{!course.present && (
												<Form.Group
													controlId="input_birthday"
													className={`select-input ${
														!!errors.birthday &&
														touched.birthday &&
														"is-invalid"
													}`}
												>
													<SingleDatePicker
														placeholder="Select end date"
														displayFormat="DD/MM/YYYY"
														numberOfMonths={1}
														date={
															course &&
															course.end_date &&
															moment(
																course.end_date
															)
														}
														id="birthday"
														showDefaultInputIcon
														onDateChange={(e) =>
															handleEndDateChange(
																id,
																e,
																"courses"
															)
														}
														focused={
															focused[
																`coursesEndDate${id}`
															]
														}
														isOutsideRange={() =>
															false
														}
														navNext={
															monthSelected ===
																0 &&
															yearSelected ===
																(2024 ||
																	2025 ||
																	2026) ? (
																<div></div>
															) : (
																""
															)
														}
														onFocusChange={({
															focused,
														}) =>
															handleEndDateChangeFocus(
																id,
																focused,
																"courses"
															)
														}
														openDirection="up"
														renderMonthElement={({
															month,
															onMonthSelect,
															onYearSelect,
														}) => {
															setMonthSelected(
																month.month()
															);
															setYearSelected(
																month.year()
															);
															return monthAndYearDropdown(
																month,
																onMonthSelect,
																onYearSelect,
																id,
																"end_date"
															);
														}}
														required
														readOnly={true}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.birthday}
													</Form.Control.Feedback>
												</Form.Group>
											)}
											<Form.Group
												className="mb-1"
												controlId={`formBasicCheckbox2${id}`}
											>
												<Form.Check
													type="checkbox"
													checked={course.present}
													onChange={(e) =>
														handleChecked(
															e,
															id,
															"courses"
														)
													}
													label="Present"
												/>
											</Form.Group>
										</Col>
										{showButtonPlus === id && (
											<Col>
												<Button
													onClick={handleAddCourse}
													disabled={isSubmitting}
													variant="outline-primary"
													className="btn-plus"
												>
													+
												</Button>
											</Col>
										)}
									</Row>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</>
	);
};

EmployeeCourses.propTypes = {
	values: PropTypes.shape({
		courses: PropTypes.array,
	}).isRequired,
	errors: PropTypes.shape({
		courses: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		courses: PropTypes.bool,
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	employees: state.employees,
});

export default connect(mapStateToProps, {})(EmployeeCourses);
