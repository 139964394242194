import Axios from "axios"
import API from "./APIService"
import BaseService from "./BaseService"

class EmployeesService extends BaseService {
	component = null

	constructor(component = null) {
		super()
		this.component = component
	}

	baseServicePath = () => {
		return API.url("employees")
	}
	Archive = (id) => {
		return Axios.put(`${process.env.REACT_APP_API_URL}/employees/${id}/archive`,{archive:true})
	}
	UnArchive = (id) => {
		return Axios.put(`${process.env.REACT_APP_API_URL}/employees/${id}/archive`,{archive:false})
	}
}

export default EmployeesService
