import React from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import ApplicationRouter from "./routes/ApplicationRouter"
import * as serviceWorker from "./serviceWorker"
import {users} from "./actions/types"
import "react-table/react-table.css"
import "react-picky/dist/picky.css"
import "./styles/main.scss"
import {store} from "./store"
import IntlWrapper from "./IntlWrapper"

if (localStorage.loggedInUserData) {
	store.dispatch({ type: users.USER_LOGGED_IN, payload: JSON.parse(localStorage.loggedInUserData) })
}

ReactDOM.render(
	<Provider store={store}>
		<IntlWrapper>
			<ApplicationRouter/>
		</IntlWrapper>
	</Provider>, 
	document.querySelector("#root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.d
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
