import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Col, Form, Row } from "react-bootstrap"
import AsyncSelect from "react-select/async"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"
import { searchEmployees, getEmployeeAction } from "../../actions/employeesActions"
import { searchClients } from "../../actions/clientsActions"
import { fields } from "../../enum/EmployeeData"
import FormikInput from "../inputs/FormikInput"
import history from "../../routes/history"


const EmployeeClientDropdowns = ({ isSubmitting, errors, touched, handleChange, values, setFieldValue, setFieldTouched, searchEmployees, searchClients, showEmployee, showClient, getEmployeeAction, fromOther }) => {
	const [clientsOptions, setClientsOptions] = useState("")
	const [employeesOptions, setEmployeesOptions] = useState("")



	useEffect(() => {
		const getDefaultEmployeeOptions = async () => {
			const employeesOptions = await searchEmployees("")
			setEmployeesOptions(employeesOptions)
		}
		getDefaultEmployeeOptions()

		const getDefaultClientOptions = async () => {
			const clientsOptions = await searchClients("")
			setClientsOptions(clientsOptions)
		}
		getDefaultClientOptions()
	}, [])

	useEffect(() => {
		if (fromOther?.employee) {
			setFieldValue("employee", fromOther.employee)
		}

		if (fromOther?.client) {
			setFieldValue("client", fromOther.client)
		}

	}, [fromOther?.employee, fromOther?.client])

	const setEmployeeData = async (id) => {
		const employeeDataOptions = await getEmployeeAction(id)

		Object.keys(employeeDataOptions).forEach(field => {
			setFieldValue(field, employeeDataOptions[field])
		})


	}


	const handleChangeEmployee = async (value) => {
		if (value) {
			setFieldValue("employee", value)
			setFieldValue("employee_shown", true)

			await setEmployeeData(value.id)
		} else {
			fields.forEach(field => {
				setFieldValue(field, "")
			})
			setFieldValue("employee_shown", false)

			setFieldValue("employee", "")

			setFieldTouched("employee", true)
		}
	}

	const handleChangeClient = (value) => {
		console.log(value)
		if(value !== null) {
			setFieldValue("client", value)
		} else {
			setFieldValue("client", "")
			setFieldTouched("client", true)
		}

	}

	return (
		<>
			{!isSubmitting && <div className="inputs-wrapper form__short">
				{showEmployee && <Row>
					<Col>
						<Form.Group controlId="input_employee_source_id" className="floating-label">
							<AsyncSelect
								id="api_source_id"
								cacheOptions
								loadOptions={searchEmployees} //debounce(searchEmployees, 100)
								defaultOptions={employeesOptions}
								isDisabled={history.location.pathname.includes(`resumes/create`) ? false : true}
								// isClearable
								getOptionValue={(item) => (item.id)}
								getOptionLabel={(item) => (item.full_name)}
								onChange={(value) => handleChangeEmployee(value)}
								onBlur={() => setFieldTouched("employee", true)}
								value={values.employee}
								placeholder="Employee"
								noOptionsMessage={() => ("Type to search")}
								className={`select-input ${(!!errors.employee && touched.employee) && "is-invalid"}`}
							/>
							<Form.Control.Feedback type='invalid'>{errors.employee}</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>}

				{showClient && <Row>
					<Col>
						<Form.Group controlId="input_employee_source_id" className="floating-label">
							<AsyncSelect
								id="api_source_id"
								cacheOptions
								loadOptions={debounce(searchClients, 100)}
								defaultOptions={clientsOptions}
								isClearable
								getOptionValue={(item) => (item.id)}
								getOptionLabel={(item) => (item.full_name)}
								onChange={(value) => handleChangeClient(value)}
								onBlur={() => setFieldTouched("client", true)}
								value={values.client}
								placeholder="Client"
								noOptionsMessage={() => ("Type to search")}
								className={`select-input ${(!!errors.client && touched.client) && "is-invalid"}`}
								isRequired={true}
							/>
							<div className={`select-input ${errors.client && "is-invalid"}`}> </div>
							<Form.Control.Feedback type='invalid'>{errors.client}</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>}
				<FormikInput type="text" name="tariff" label="Tariff" error={errors.tariff} required />
			</div>}
		</>
	)
}

EmployeeClientDropdowns.propTypes = {
	values: PropTypes.shape({
		client: PropTypes.shape({}),
		employee: PropTypes.shape({}),
		tariff: PropTypes.string,
	}).isRequired,
	errors: PropTypes.shape({
		client: PropTypes.string,
		employee: PropTypes.string,
		tariff: PropTypes.string
	}).isRequired,
	touched: PropTypes.shape({
		client: PropTypes.bool,
		employee: PropTypes.bool,
		tariff: PropTypes.bool
	}).isRequired,
	showEmployee: PropTypes.bool.isRequired,
	showClient: PropTypes.bool.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	searchEmployees: PropTypes.func.isRequired,
	searchClients: PropTypes.func.isRequired,
	getEmployeeAction: PropTypes.func.isRequired,
	fromOther: PropTypes.shape({
		employee: PropTypes.shape({}),
		client: PropTypes.shape({}),
	}).isRequired
}

const mapStateToProps = (state) => ({
	resumes: state.resumes
})

export default connect(mapStateToProps, { searchEmployees, searchClients, getEmployeeAction })(EmployeeClientDropdowns)

