import axios from "axios"
import API from "./APIService"

class AuthService {
	component = null

	constructor(component = null) {
		this.component = component
	}

	/**
	 * Get logged in user data from local storage
	 * @return userLogin data
	 */
	getLoggedInUser = () => JSON.parse(localStorage.getItem("loggedInUserData"))

	/**
	 * Set logged in user data to local storage
	 * @param {JSON} userLogin - data
	 */
	setLoggedInUser = (userLogin) => {
		localStorage.setItem("loggedInUserData", JSON.stringify(userLogin))
	};

	/**
	 * Update logged in user jwt
	 * @param {string} newToken - to save
	 */
	updateJWT = (newToken) => {
		const userLoginData = this.getLoggedInUser()
		userLoginData.token = newToken
		this.setLoggedInUser(userLoginData)
	};

	/**
	 * Update logged in user jwt
	 * @param {string} newToken - to save
	 */
	updateJWTUserData = (newUserData) => {
		const userLoginData = this.getLoggedInUser()
		userLoginData.user = newUserData
		this.setLoggedInUser(userLoginData)
	};

	/**
	 * Try to login a user with given credentials and if credentials are correct will redirect to /shop
	 * @param credentials object with "email" & "password" in it
	 */

	login = (data) => axios.post(API.url("auth/login"), data);

	logout = (data) => axios.post(API.url(`logout?token=${data.token}`), data)

	forgot_password = (data) => axios.post(API.url("passwords/reset-mail"), data);

	reset_password = (data) => axios.post(API.url("passwords/reset"), data);

}

export default AuthService
