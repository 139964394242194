import cogoToast from "cogo-toast"
import history from "../routes/history"
import LanguagesService from "../services/LanguagesService"
import { languages } from "./types"
import getTransformedFilters from "../helpers/filterTransformer"
import HandleError from "../services/HandleError"
import Axios from "axios"

const languagesService = new LanguagesService(this)

export const getLanguagesAction = ({ pageSize, sorted, filters, page }) => async dispatch => {
	const query = `per_page=${pageSize}&${getTransformedFilters(filters)}`
	dispatch({ type: languages.GET_LANGUAGES_STARTED })
	try {
		const response = await languagesService.index(page, query)
		dispatch({ type: languages.GET_LANGUAGES_SUCCESS, payload: { ...response.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: languages.GET_LANGUAGES_ERROR })
	}
}

export const showLanguageAction = (id, handleFinish) => async dispatch => {
	dispatch({ type: languages.SHOW_LANGUAGES_STARTED })
	try {
		const response = await languagesService.show(id)
		dispatch({ type: languages.SHOW_LANGUAGES_SUCCESS, payload: { ...response.data.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: languages.SHOW_LANGUAGES_ERROR })
	} finally {
		handleFinish()
	}
}

export const createLanguageAction = (values, setSubmitting) => async dispatch => {

	dispatch({ type: languages.CREATE_LANGUAGES_STARTED })

	try {
		const response = await languagesService.store(values, false)

		dispatch({ type: languages.CREATE_LANGUAGES_SUCCESS, payload: response.data })
		history.push("/languages")
	} catch (err) {
		dispatch({ type: languages.CREATE_LANGUAGES_ERROR })

		HandleError(err)
	}finally {
		setSubmitting(false)
	}
}

export const updateLanguageAction = (id, values, setSubmitting, setErrors) => async dispatch => {
	dispatch({ type: languages.UPDATE_LANGUAGES_STARTED })
	try {
		const response = await languagesService.update(id, values)
		dispatch({ type: languages.UPDATE_LANGUAGES_SUCCESS, payload: { ...response.data } })
		cogoToast.success("Language saved successfully.", { position: "top-center" })
		history.push("/languages")
	} catch (err) {
		HandleError(err, setErrors)
		dispatch({ type: languages.UPDATE_LANGUAGES_ERROR })
	} finally {
		setSubmitting(false)
	}
}

export const deleteLanguageAction = (id, handleFinish) => async dispatch => {

	dispatch({ type: languages.DELETE_LANGUAGES_STARTED })
	try {
		await languagesService.delete(id)
		handleFinish()
		dispatch({ type: languages.DELETE_LANGUAGES_SUCCESS, payload: { id } })
		cogoToast.success("Language deleted successfully.", { position: "top-center" })
	} catch (err) {
		handleFinish()
		HandleError(err)
		dispatch({ type: languages.DELETE_LANGUAGES_ERROR })
	}
}

export const searchLanguages = (inputValue) => async () => {
	try {
		const response = await languagesService.search(inputValue || " ")
		return response.data.map((item) => ({
			id: item.id,
			name: item.name,
		}))
	} catch (error) {
		HandleError(error)
	}
}


export const FilterLanguages = (inputValue) => async dispatch => {
	dispatch({ type: languages.GET_LANGUAGES_STARTED })
	try {
		const response = await Axios.get(`${process.env.REACT_APP_API_URL}/languages?term=${inputValue}`)
		dispatch({ type: languages.GET_LANGUAGES_SUCCESS, payload: { ...response} })
	} catch (error) {
		HandleError(error);
		dispatch({ type: languages.GET_LANGUAGES_ERROR })
	}
}
