import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { Card } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import ClientsTable from "../../components/tables/ClientsTable"
import MaterialIcon from "../../components/elements/MaterialIcon"
import { getClientsAction } from "../../actions/clientsActions"

class ClientsPage extends Component {
	state={
		search:"",
		searchToogle: false
	}
	componentDidMount() {
		this.props.getClientsAction({ pageSize: 15, sorted: null, filters: [], page: 1 })
	}

	handelClick() {
		this.setState({searchToogle : !this.state.searchToogle})
		console.log(this.state.searchToogle);
		if(this.state.searchToogle) {
			this.setState({search:''});
		}
	}

	render() {
		return (
			<div>
				<div className="top-actions-row flex-between">
					<Link to="clients/create" className="btn btn-primary btn-sm btn-roundet btn-dropshadow"><MaterialIcon icon="add" /><FormattedMessage id="buttons.new_client" /></Link>
					{/* <div className="border flex-align-center">
						<input id="generalSearch" placeholder="Search" className="pl-2 border-0" onChange={(e) => this.setState({search:e.target.value}) } />
						<label htmlFor='generalSearch' className="mb-0 p-1"><MaterialIcon icon="search" /></label>
					</div> */}

					<div className={`border flex-align-center outer-Table-Search ${this.state.searchToogle && 'open-parent-search-outer'} `}>
						<input id="generalSearch" placeholder="Search" className={`pl-2 border-0 ${this.state.searchToogle && 'open-search'}`} onChange={(e) => this.setState({search:e.target.value}) } />
						<label htmlFor='generalSearch' className="mb-0 p-1" onClick={() => this.handelClick()} ><MaterialIcon icon={`${this.state.searchToogle ? 'close': 'search'}`} /></label>
					</div>
				</div>
				<Card className="fadeInLoadUp" >
					<Card.Body>
						<ClientsTable SearchRow={this.state.search} />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

ClientsPage.propTypes = {
	getClientsAction: PropTypes.func.isRequired,
	clients: PropTypes.shape({
		isLoading: PropTypes.bool,
		isExporting: PropTypes.bool,
		hasError: PropTypes.bool,
		filters: PropTypes.arrayOf(PropTypes.object),
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number.isRequired
			})
		})
	}).isRequired
}

const mapStateToProps = (state) => ({
	clients: state.clients
})

export default connect(mapStateToProps, { getClientsAction })(ClientsPage)
