import { Button, Modal, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PDF.scss";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfModal({ status }) {
	const [show, setShow] = useState(status.ShowPdf.status);
	const handleClose = async () => {
		status.setShowPdf({...status.ShowPdf , status:false});
		setPdfPath("");
		await axios
			.delete(
				`${process.env.REACT_APP_API_URL}/pdf/resumes/remove`
			)
			.then((res) => console.log(res.data))
			.catch((err) => console.log(err.response.data));
	};
	const [pdfPath, setPdfPath] = useState("");
	useEffect(() => {
		setShow(status.ShowPdf.status);
		if (status.ShowPdf.rowId) {
			(async () => {
				await axios
					.get(
						`${process.env.REACT_APP_API_URL}/pdf/resumes/${status.ShowPdf.rowId}/create/view/${status.ShowPdf.Type}`
					)
					.then((res) => {
						setPdfPath(res.data);
						console.log(res.data);
					})
					.catch((err) => console.log(err));
			})();
		}
	}, [status.ShowPdf.status]);

	return (
		<div>
			<Modal show={show} onHide={handleClose} dialogClassName="PdfModal">
				<Modal.Header closeButton>
					{/* <Modal.Title>Modal heading</Modal.Title> */}
				</Modal.Header>
				<Modal.Body>
					{pdfPath.length ? (
						<object
							data={pdfPath}
							type="application/pdf"
							width="100%"
							height="100%"
						>
							<p>
								Alternative text - include a link{" "}
								<a href={pdfPath}>to the PDF!</a>
							</p>
						</object>
			
					) : (
						<Spinner animation="border" role="status"></Spinner>
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default PdfModal;
