import cogoToast from "cogo-toast"
import history from "../routes/history"
import AreasService from "../services/AreasService"
import { areas } from "./types"
import getTransformedFilters from "../helpers/filterTransformer"
import HandleError from "../services/HandleError"
import Axios from "axios"

const areasService = new AreasService(this)

export const getAreasAction = ({ pageSize, sorted, filters, page }) => async dispatch => {
	const query = `per_page=${pageSize}&${getTransformedFilters(filters)}`
	dispatch({ type: areas.GET_AREAS_STARTED })
	try {
		const response = await areasService.index(page, query);
		dispatch({ type: areas.GET_AREAS_SUCCESS, payload: { ...response.data } });
	} catch (err) {
		HandleError(err)
		dispatch({ type: areas.GET_AREAS_ERROR })
	}
}

export const showAreaAction = (id, handleFinish) => async dispatch => {
	dispatch({ type: areas.SHOW_AREAS_STARTED })
	try {
		const response = await areasService.show(id)
		dispatch({ type: areas.SHOW_AREAS_SUCCESS, payload: { ...response.data.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: areas.SHOW_AREAS_ERROR })
	} finally {
		handleFinish()
	}
}

export const createAreaAction = (values, setSubmitting) => async dispatch => {

	dispatch({ type: areas.CREATE_AREAS_STARTED })

	try {
		const response = await areasService.store(values, false)

		dispatch({ type: areas.CREATE_AREAS_SUCCESS, payload: response.data })
		cogoToast.success("Area created successfully.", { position: "top-center" })
		history.push("/areas")
	} catch (err) {
		HandleError(err)
		dispatch({ type: areas.CREATE_AREAS_ERROR })
	}	finally {
		setSubmitting(false)
	}
}

export const updateAreaAction = (id, values, setSubmitting, setErrors) => async dispatch => {
	dispatch({ type: areas.UPDATE_AREAS_STARTED })
	try {
		const response = await areasService.update(id, values)
		dispatch({ type: areas.UPDATE_AREAS_SUCCESS, payload: { ...response.data } })
		cogoToast.success("Area saved successfully.", { position: "top-center" })
		history.push("/areas")
	} catch (err) {
		HandleError(err, setErrors)
		dispatch({ type: areas.UPDATE_AREAS_ERROR })
	}
	finally {
		setSubmitting(false)
	}
}

export const deleteAreaAction = (id, handleFinish) => async dispatch => {

	dispatch({ type: areas.DELETE_AREAS_STARTED })
	try {
		await areasService.delete(id)
		handleFinish()
		dispatch({ type: areas.DELETE_AREAS_SUCCESS, payload: { id } })
		cogoToast.success("Area deleted successfully.", { position: "top-center" })
	} catch (err) {
		handleFinish()
		HandleError(err)
		dispatch({ type: areas.DELETE_AREAS_ERROR })
	}
}

export const searchAreas = (inputValue) => async () => {
	try {
		const response = await areasService.search(inputValue || " ")
		return response.data.map((item) => ({
			id: item.id,
			name: item.name,
		}))
	} catch (error) {
		HandleError(error)
	}
}
// for search by name in backedn 
export const FilterAreas = (inputValue) => async dispatch => {
	dispatch({ type: areas.GET_AREAS_STARTED })
	try {
		const response = await Axios.get(`${process.env.REACT_APP_API_URL}/areas?term=${inputValue}`)
		dispatch({ type: areas.GET_AREAS_SUCCESS, payload: { ...response} })
	} catch (error) {
		HandleError(error);
		dispatch({ type: areas.GET_AREAS_ERROR })
	}
}


export const searchAreaSkills = (id) => async dispatch => {
	// TODO: Save data to redux and remove return null
	dispatch({ type: areas.SEARCH_SKILLS_STARTED })
	try {

		const response = await areasService.areaSkills(id);
		dispatch({ type: areas.SEARCH_SKILLS_SUCCESS, payload: { ...response.data } })
		// console.log({response})
		return response.data
	} catch (error) {
		HandleError(error)
		dispatch({ type: areas.SEARCH_SKILLS_ERROR })
	}
	return null
}

export const AllAreaSkills = (id) => async dispatch => {
	try {
		const response = await Axios.get(`${process.env.REACT_APP_API_URL}/areas/${id}/skills/all`);
		return response.data
	} catch (error) {
		HandleError(error)
	}
}
