import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { showAreaAction } from "../../actions/areasActions"
import AreaForm from "../../components/forms/AreaForm"
import MaterialIcon from "../../components/elements/MaterialIcon"

class AreaEditPage extends Component {

	state = {
		itemAreasIndex: -1
	}

	componentDidMount() {

		const { match, history } = this.props

		if (!match.params.id)
			history.push("/areas")

		this.props.showAreaAction(match.params.id, this.handleFinish)
	}

	handleFinish = () => {
		const { areas, match, history } = this.props
		const itemAreasIndex = areas.data.findIndex(item => (item.id === parseInt(match.params.id, 10)))
		if (itemAreasIndex === -1)
			history.push("/areas")

		this.setState({ itemAreasIndex })
	}

	render() {
		const { itemAreasIndex } = this.state
		const { areas, match } = this.props
		const areaData = itemAreasIndex !== -1 ? areas.data[itemAreasIndex] : null
		return (
			<div className="client-edit-page private-page">
				<div className="top-actions-row">
					<Link to="/areas" className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /><FormattedMessage id="buttons.back_to_area" /></Link>
				</div>
				<Card className="client-card fadeInLoadUp">
					<Card.Body>
						<AreaForm area={areaData} id={match.params.id} />
					</Card.Body>
				</Card>
			</div>
		)
	}

}
AreaEditPage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string
		})
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func
	}).isRequired,
	showAreaAction: PropTypes.func.isRequired,
	areas: PropTypes.shape({
		isLoading: PropTypes.bool,
		data: PropTypes.array
	}).isRequired,
}
const mapStateToProps = (state) => ({
	areas: state.areas,
})
export default connect(mapStateToProps, { showAreaAction })(AreaEditPage)
