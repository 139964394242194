import {areasSkills} from "./types"
import AreaSkillsService from "../services/AreaSkillsService"
import HandleError from "../services/HandleError"

const areaSkillsService = new AreaSkillsService(this)

export const getAreaSkillsAction = (areaId,handleFinish)=> async dispatch => {

	dispatch({type: areasSkills.GET_AREAS_SKILLS_STARTED})
	try {
		const response = await areaSkillsService.getSkills(areaId)
		dispatch({type: areasSkills.GET_AREAS_SKILLS_SUCCESS, payload: response.data})
	} catch (error) {
		HandleError(error)
		dispatch({type: areasSkills.GET_AREAS_SKILLS_ERROR, error})
	} finally {
		handleFinish()
	}
}
