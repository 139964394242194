import { employeeResumes } from "./types";
import EmployeeResumesService from "../services/EmployeeResumesService";
import HandleError from "../services/HandleError";
import getTransformedFilters from "../helpers/filterTransformer";

const employeeResumesService = new EmployeeResumesService(this);

export const getEmployeeResumesAction =
	({ employeeId, pageSize, page, filters }) =>
	async (dispatch) => {
		dispatch({ type: employeeResumes.GET_EMPLOYEE_RESUMES_STARTED });
		const query = `&page=${page}per_page=${pageSize}&${getTransformedFilters(
			filters
		)}`;
		try {
			const response = await employeeResumesService.getResumes(
				employeeId,
				query
			);
			dispatch({
				type: employeeResumes.GET_EMPLOYEE_RESUMES_SUCCESS,
				payload: response.data,
			});
		} catch (error) {
			HandleError(error);
			dispatch({
				type: employeeResumes.GET_EMPLOYEE_RESUMES_ERROR,
				error,
			});
		}
	};
