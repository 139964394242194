import { Form as FormLink, withFormik } from "formik"
import PropTypes from "prop-types"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { connect } from "react-redux"
import * as Yup from "yup"
import {FormattedMessage} from "react-intl"
import { updateLanguageAction, createLanguageAction } from "../../actions/languagesActions"
import MaterialIcon from "../elements/MaterialIcon"

const LanguageForm = ({ isSubmitting, errors, touched, handleChange, values, setFieldValue, setFieldTouched }) => {

	return (
		<FormLink className='client-form form__loading form__short private-form'>
			{isSubmitting && <div className="loading-overlay">
				<h5>Loading...</h5>
			</div>}
			<div className="inputs-wrapper">
				<Form.Group controlId="input_name" className="floating-label">
					<Form.Control type="text" value={values.name} onChange={handleChange} name="name" isInvalid={!!errors.name && touched.name} />
					<Form.Label>Name</Form.Label>
					<Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
				</Form.Group>
			</div>
			<div className="submit-wrapper">
				<Button type="submit" variant="success save" disabled={isSubmitting}><MaterialIcon icon="save" /> <FormattedMessage id="buttons.save_language" /> </Button>
			</div>
		</FormLink>
	)
}

const Schema = Yup.object().shape({
	name: Yup.string().required("This field is required")
})

const FormikApp = withFormik({
	mapPropsToValues({ name, language }) {
		if (language) {
			return {
				name: language.name || ""
			}
		}
		return {
			name: name || ""
		}
	},
	validationSchema: Schema,
	enableReinitialize: true,
	handleSubmit(values, { props, setSubmitting, setErrors }) {
		if (props.language) {
			props.updateLanguageAction(props.id, values, setSubmitting, setErrors)
		} else {
			props.createLanguageAction(values, setSubmitting, setErrors)
		}
	},
})(LanguageForm)

LanguageForm.propTypes = {
	values: PropTypes.shape({
		name: PropTypes.string
	}).isRequired,
	errors: PropTypes.shape({
		name: PropTypes.string
	}).isRequired,
	touched: PropTypes.shape({
		name: PropTypes.bool
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	languages: state.languages
})
export default connect(mapStateToProps, { updateLanguageAction, createLanguageAction })(FormikApp)
