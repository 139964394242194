import React, {Component} from "react"
import {Link} from "react-router-dom"
import {FormattedMessage} from "react-intl"
import {Card} from "react-bootstrap"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import EmployeeForm from "../../components/forms/EmployeeForm"
import MaterialIcon from "../../components/elements/MaterialIcon"
import {showClientAction} from "../../actions/clientsActions"


class ClientResumesCreatePage extends Component {

	state = {
		itemClientsIndex: -1
	}

	componentDidMount() {

		const { match, history } = this.props

		if (!match.params.id)
			history.push("/clients")

		this.props.showClientAction(match.params.id, this.handleFinish)
	}

	handleFinish = () => {
		const { clients, match, history } = this.props
		const itemClientsIndex = clients.data.findIndex(item => (item.id === parseInt(match.params.id, 10)))
		if (itemClientsIndex === -1)
			history.push("/clients")

		this.setState({ itemClientsIndex })
	}

	render() {
		const { itemClientsIndex } = this.state
		const { clients } = this.props
		const clientData = itemClientsIndex !== -1 ? clients.data[itemClientsIndex] : null

		return (
			<div className="client-edit-page private-page">
				<div className="top-actions-row">
					<Link to="/clients" className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /><FormattedMessage id="buttons.back_to_client" /></Link>
				</div>
				<Card className="client-card fadeInLoadUp">
					<Card.Body>
						<EmployeeForm id={clientData?.id} component="ClientResumesCreatePage" showEmployee showClient={false} fromOther={{client: clientData}} />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

ClientResumesCreatePage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string
		})
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func
	}).isRequired,
	showClientAction: PropTypes.func.isRequired,
	clients: PropTypes.shape({
		isLoading: PropTypes.bool,
		data: PropTypes.array
	}).isRequired,
}
const mapStateToProps = (state) => ({
	clients: state.clients
})
export default connect(mapStateToProps, { showClientAction })(ClientResumesCreatePage)
