import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { connect, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
	getResumesAction,
	deleteResumeAction,
} from "../../actions/resumesActions";
import { getClientResumesAction } from "../../actions/clientResumesActions";
import ClientResumesTableActionsCell from "../elements/ClientResumesTableActionsCell";
import getStaticUrl from "../../helpers/api";
import DeleteModal from "../modals/DeleteModal";
import { onRowClick } from "../../helpers/ViewPdf";
import moment from "moment";
import PdfModal from "../modals/PdfModal";
import { useEffect } from "react";
import { FilterField, GeneralFilter } from "../../helpers/GeneralFillter";
import { useParams } from "react-router-dom";

const ClientResumesTable = ({
	client_resumes,
	getClientResumesAction,
	deleteResumeAction,
	SearchRow,
}) => {
	const state = useSelector((s) => s);
	const [ShowPdf, setShowPdf] = useState({
		status: false,
		rowId: "",
		Type: "resumes",
	});
	const [Fillter, setFillter] = useState(null);
	const [counterPages, setCounterPages] = useState(10);
	useEffect(() => {
		// fillter data if user type in general filter input
		GeneralFilter(SearchRow, client_resumes, setFillter);
	}, [SearchRow]);

	let index = -1;
	let pageNr = client_resumes.data.length
		? client_resumes.meta.pagination.current_page - 1
		: 0;
	let totalPages = client_resumes.data.length
		? client_resumes.meta.pagination.total_pages
		: 1;
	const [showDelete, setShowDeleteModal] = useState(-1);
	const intl = useIntl();
	const params = useParams();
	let clientId = params.id;

	useEffect(() => {
		pageNr = totalPages - 1;
		const { filters } = client_resumes;
		getClientResumesAction({
			clientId: clientId,
			page: 1,
			pageSize: counterPages,
			filters,
		});
		if (!client_resumes.data.length) {
			const { filters } = client_resumes;
			getClientResumesAction({
				clientId: clientId,
				page: 1,
				pageSize: counterPages,
				filters,
			});
		}
	}, [counterPages]);

	const columns = [
		{
			Header: "ID",
			filterable: true,
			Filter: FilterField,
			accessor: "id",
			Cell: (cellProps) => cellProps.value || "",
		},
		{
			Header: "Employee",
			filterable: true,
			Filter: FilterField,
			accessor: "employee",
			filterMethod: (filter, row) => {
				if (
					row[filter.id].full_name
						.toLowerCase()
						.includes(filter.value.toLowerCase())
				) {
					return row;
				}
			},
			Cell: (cellProps) => `${cellProps.value.full_name}` || "",
		},
		{
			Header: "Created at",
			filterable: true,
			Filter: FilterField,
			accessor: "created_at",
			Cell: (cellProps) =>
				moment(cellProps.value).format("YYYY-MM-DD") || "",
		},
		{
			Header: "Actions",
			filterable: false,
			Cell: (cellProps) => (
				<ClientResumesTableActionsCell
					Route="/clients/resumes"
					cellProps={cellProps}
					setShowDeleteModal={setShowDeleteModal}
				/>
			),
		},
	];

	const handleCloseDelete = () => {
		setShowDeleteModal(-1);
	};

	const handlePaginateFetch = (page) => {
		const { filters } = client_resumes;
		getClientResumesAction({
			clientId: clientId,
			page: page + 1,
			pageSize: counterPages,
			filters,
		});
	};

	const handleDelete = async () => {
		const { id } = client_resumes.data[index];
		await deleteResumeAction(id, handleCloseDelete);
		const { filters } = client_resumes;
		await getClientResumesAction({
			clientId: clientId,
			pageSize: counterPages,
			sorted: null,
			filters,
			page: pageNr + 1,
		});
	};

	if (showDelete >= 0) {
		index = showDelete;
	}

	return (
		<div className="table-style">
			{ShowPdf.status && <PdfModal status={{ ShowPdf, setShowPdf }} />}
			<ReactTable
				data={Fillter ? Fillter : client_resumes.data}
				loading={client_resumes.isLoading}
				columns={columns}
				defaultPageSize={10}
				pages={totalPages}
				page={pageNr}
				filterable
				manual
				defaultFilterMethod={(filter, row) => {
					if (
						row[filter.id]
							.toLowerCase()
							.includes(filter.value.toLowerCase())
					) {
						return row;
					}
				}}
				showPageSizeOptions={true}
				sortable={false}
				className="striped-highlight cl-ta cl-re-ta unique-filter-table"
				onPageChange={handlePaginateFetch}
				getTdProps={(state, rowInfo, column, instance) =>
					onRowClick(
						state,
						rowInfo,
						column,
						instance,
						setShowPdf,
						ShowPdf,
						setCounterPages
					)
				}
			/>

			<DeleteModal
				isShown={showDelete !== -1}
				handleClose={handleCloseDelete}
				title={
					client_resumes.data[index]
						? `Resume of ${client_resumes.data[index].employee.full_name}`
						: ""
				}
				handleDelete={handleDelete}
				isDeleting={client_resumes.isDeleting}
			/>
		</div>
	);
};

ClientResumesTable.propTypes = {
	getClientResumesAction: PropTypes.func.isRequired,
	deleteResumeAction: PropTypes.func.isRequired,
	client_resumes: PropTypes.shape({
		data: PropTypes.array,
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number,
				total_pages: PropTypes.number,
			}),
		}),
		filters: PropTypes.array,
		isLoading: PropTypes.bool,
		hasError: PropTypes.bool,
		isRestoring: PropTypes.bool,
		isDeleting: PropTypes.bool,
	}).isRequired,
};

const mapStateToProps = (state) => ({
	client_resumes: state.client_resumes,
});

export default connect(mapStateToProps, {
	getClientResumesAction,
	deleteResumeAction,
})(ClientResumesTable);
