import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { Editor } from "@tinymce/tinymce-react"
import { SingleDatePicker } from "react-dates"
import moment from "moment"
import { connect } from "react-redux"
import { monthAndYearDropdown } from "../../../helpers/dateUtils"
import FormikInput from "../../inputs/FormikInput"
import PropTypes from "prop-types"
import DeleteModal from "../../modals/DeleteModal"

const EmployeeLinks = ({ isSubmitting, errors, touched, handleChange, values, setFieldValue, setFieldTouched }) => {
    const [focused, setFocused] = useState({})
    const [showDelete, setShowDeleteModal] = useState(false)
	const [Selected , setSelected] = useState({}) // to store what edu user wanna delete 
    const [showButtonPlus, setShowButtonPlus] = useState(0)
    useEffect(() => {
        if(values?.links?.length > 0) {
            setShowButtonPlus(values.links.length - 1)
        } else {
            console.log("Failed showButtonPlus");
        }
    })
    // console.log("link", errors)
    const scrollRef = useRef(null)
    const handleStartDateChange = (id, value, type) => {
        setFieldValue(`${type}[${id}][start_date]`, value.format("YYYY-MM-DD"))
    }

    const handleEndDateChange = (id, value, type) => {
        setFieldValue(`${type}[${id}][end_date]`, value.format("YYYY-MM-DD"))
    }

    const handleStartDateChangeFocus = (id, focus, type) => {
        setFocused({ ...focused, ...{ [`${type}StartDate${id}`]: focus } })
    }

    const handleEndDateChangeFocus = (id, focus, type) => {
        setFocused({ ...focused, ...{ [`${type}EndDate${id}`]: focus } })
    }

	const handleRemoveData = () => {
        if(values.links.length > 0){
			setShowButtonPlus(showButtonPlus - 1)
			} else {
			setShowButtonPlus(0)
			}
		const valueFields = values[Selected.Type]
		valueFields.splice(Selected.id, 1)
		setFieldValue(Selected.Type, [...valueFields])
		setShowDeleteModal(false)
	}

    const handleAddLink = () => {
        if(values?.links?.length > 0){
            setShowButtonPlus(values.links.length);
        } else {
        setShowButtonPlus(0)
        }
        const valueFields = values.links ? values.links : []

        valueFields.push({
            name: "",
            link: "",
        })
        setFieldValue("links", [...valueFields])

        setTimeout(() => {
            scrollRef.current.scrollIntoView({ block: "center", behavior: "smooth" })
        }, 50)
    }
	const handleCloseDelete = () => {
		setShowDeleteModal(false)
	}
    return (
        <>
            {showDelete&&
				<DeleteModal
				isShown={showDelete}
				handleClose={handleCloseDelete}
				title={`Link ${Selected.id+1}`}
				handleDelete={handleRemoveData}
				// isDeleting={client_resumes.isDeleting}
				/>
			}
            <Row>
                <Col>
                    <h4 className="respnsiveHeader"><FormattedMessage id="labels.links" /></h4>
                </Col>
				{!values?.links?.length &&  
                    <Col className="mb-3">
                        <Button onClick={handleAddLink} disabled={isSubmitting}
                            variant="outline-primary" className="btn-plus">+
                        </Button>
                    </Col>
                }
            </Row>
            <div className={`select-input ${errors.links && "is-invalid"}`}> </div>
            <Form.Control.Feedback type='invalid'>{errors.links}</Form.Control.Feedback>

            {!isSubmitting && values?.links?.length > 0 &&
                <div className="form__large employee-form employee-data" >
                    <div>
                        {
                            values.links.map((link, id) => {
                                return (
                                    <div key={`link-${id}`} ref={scrollRef} className="counter-spacing">
                                        <Row className="align-items-bottom mb-3">
                                            <Col>
                                                <h5 style={{ color: "#007bff" }}><FormattedMessage id="labels.link" /> {id + 1}</h5>
                                            </Col>

                                            <Col className="col-auto ml-auto"><Button
                                                onClick={() =>  {setSelected({id , Type:"links"}) ; setShowDeleteModal(true)} }
                                                variant="danger"><FormattedMessage
                                                    id="buttons.remove_link" /></Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="m-a col-12">
                                                <FormikInput type="text" name={`links[${id}].name`} label="Name" required />
                                            </Col>
                                            <Col className="m-a col-12">
                                                <FormikInput type="text" name={`links[${id}].link`} label="URL" required />
                                            </Col>
                                            {
                                               showButtonPlus === id  &&                                             
                                            <Col className="mb-3">
                                                <Button onClick={handleAddLink} disabled={isSubmitting}
                                                    variant="outline-primary" className="btn-plus">+
                                                </Button>
                                            </Col>
                                            }
                                        </Row>
                                        {/* <Row>
                                            <Col className="m-a col-6">
                                                <FormikInput type="text" name={`links[${id}].degree`} label="Degree" required />
                                            </Col>
                                            <Col className="m-a col-6">
                                                <FormikInput type="text" name={`links[${id}].field`} label="Field" required />
                                            </Col>
                                        </Row> */}
                                        {/* <Row>

                                            <Col className="m-a col-12">
                                                <Form.Group>
                                                    <Form.Label>Description</Form.Label>
                                                    <Editor
                                                        apiKey={process.env.REACT_APP_TINY_API_KEY}
                                                        value={link.description}
                                                        init={{
                                                            plugins: "paste save code visualblocks visualchars image link media codesample table charmap hr lists imagetools textpattern noneditable help charmap quickbars",
                                                            menubar: false,
                                                            toolbar:
                                                                "undo redo | styleselect | fontselect fontsizeselect formatselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent",
                                                        }}
                                                        onEditorChange={(e) => setFieldValue(`links[${id}][description]`, e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row> */}
                                        {/* <Row>
                                            <Col className="m-a col-6 calendar">
                                                <Form.Group controlId="input_birthday"
                                                    className={`select-input ${(!!errors.birthday && touched.birthday) && "is-invalid"}`}>
                                                    <SingleDatePicker
                                                        placeholder="Select start date"
                                                        displayFormat="DD/MM/YYYY"
                                                        numberOfMonths={1}
                                                        date={link && link.start_date && moment(link.start_date)}
                                                        id="birthday"
                                                        showDefaultInputIcon
                                                        onDateChange={(e) => handleStartDateChange(id, e, "links")}
                                                        focused={focused[`linksStartDate${id}`]}
                                                        isOutsideRange={() => false}
                                                        onFocusChange={({ focused }) => handleStartDateChangeFocus(id, focused, "links")}
                                                        renderMonthElement={({
                                                            month,
                                                            onMonthSelect,
                                                            onYearSelect
                                                        }) => monthAndYearDropdown(month, onMonthSelect, onYearSelect, id, 'start_date')}
                                                        required
                                                    />
                                                    <Form.Control.Feedback
                                                        type='invalid'>{errors.birthday}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col className="m-a col-6 calendar">
                                                <Form.Group controlId="input_birthday"
                                                    className={`select-input ${(!!errors.birthday && touched.birthday) && "is-invalid"}`}>
                                                    <SingleDatePicker
                                                        placeholder="Select end date"
                                                        displayFormat="DD/MM/YYYY"
                                                        numberOfMonths={1}
                                                        date={link && link.end_date && moment(link.end_date)}
                                                        id="birthday"
                                                        showDefaultInputIcon
                                                        onDateChange={(e) => handleEndDateChange(id, e, "links")}
                                                        focused={focused[`linksEndDate${id}`]}
                                                        isOutsideRange={() => false}
                                                        onFocusChange={({ focused }) => handleEndDateChangeFocus(id, focused, "links")}
                                                        renderMonthElement={({
                                                            month,
                                                            onMonthSelect,
                                                            onYearSelect
                                                        }) => monthAndYearDropdown(month, onMonthSelect, onYearSelect, id, 'end_date')}
                                                        required
                                                    />
                                                    <Form.Control.Feedback
                                                        type='invalid'>{errors.birthday}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row> */}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </>
    )
}

EmployeeLinks.propTypes = {
    values: PropTypes.shape({
        links: PropTypes.array
    }).isRequired,
    errors: PropTypes.shape({
        links: PropTypes.string
    }).isRequired,
    touched: PropTypes.shape({
        links: PropTypes.bool
    }).isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    employees: state.employees
})

export default connect(mapStateToProps, {})(EmployeeLinks)
