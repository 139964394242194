import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import { NavLink, Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import MaterialIcon from "../elements/MaterialIcon"
import { logOutAction } from "../../actions/authActions"

const DashboardSidebar = ({ logOutAction }) => {

	return (
		<div className='sidebar' id="sidebar">
			<div className='sidebar-left mr-auto'>
				<Link className='sidebar-brand' to='/'> <h4>C.<strong>V</strong></h4> </Link >
			</div>

			<ul className="dashboard-menu" >

				<li><NavLink activeClassName="active" to="/dashboard"> <MaterialIcon icon='dashboard' /> <span>Dashboard</span></NavLink> </li>
				<li><NavLink activeClassName="active" to="/employees"> <MaterialIcon icon='people_outline' /> <span>Contracts</span></NavLink> </li>
				<li><NavLink activeClassName="active" to="/clients"> <MaterialIcon icon='recent_actors' /> <span>Clients</span></NavLink> </li>
				<li><NavLink activeClassName="active" to="/resumes"> <MaterialIcon icon='note_add' /> <span>ATIS Resumes</span></NavLink> </li>
				<li><NavLink activeClassName="active" to="/archive"> <MaterialIcon icon='archive' /> <span>Archived Resumes</span></NavLink> </li>
				<li><NavLink activeClassName="active" to="/areas"> <MaterialIcon icon='storage' /> <span>Areas + Skills</span></NavLink> </li>
				{/* <li><NavLink activeClassName="active" to="/skills"> <MaterialIcon icon='view_module' /> <span>Skills</span></NavLink> </li> */}
				<li><NavLink activeClassName="active" to="/languages"> <MaterialIcon icon='language' /> <span>Languages</span></NavLink> </li>

			</ul>
			<div className="pannel-logout"><MaterialIcon icon='exit_to_app' /><Button onClick={() => logOutAction()} variant="light"><FormattedMessage id="navigations.log_out" /></Button></div>
		</div>
	)
}


DashboardSidebar.propTypes = {
	logOutAction: PropTypes.func.isRequired
}

export default connect(null, { logOutAction })(DashboardSidebar)
