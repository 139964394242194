import React, {Component} from "react"
import { Card, Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import DashboardTable from "../components/tables/DashboardTable"


class DashboardPage extends Component {

	render() {
		return (

			<Row>
				<Col>
					<Card className="fadeInLoadUp unique-dashboard" >
						<Card.Body>
							<DashboardTable headerText="Top 10 Programming Language" areaName='Programming Languages' />
						</Card.Body>
					</Card>
					<Card className="fadeInLoadUp unique-dashboard" >
						<Card.Body>
							<DashboardTable headerText="Top 10 Frameworks" areaName="Frameworks" />
						</Card.Body>					
					</Card>	
				</Col>
				<Col>
					<Card className="fadeInLoadUp unique-dashboard" >
						<Card.Body>
							<DashboardTable headerText="Top 10 CMS" areaName="CMS" />
						</Card.Body>
					</Card>
					<Card className="fadeInLoadUp unique-dashboard" >
						<Card.Body>
							<DashboardTable headerText="Top 10 Libraries" areaName="Libraries" />
						</Card.Body>					
					</Card>						
				</Col>
			</Row>
		)
	}
}


const mapStateToProps = (state) => ({})

DashboardPage.propTypes = {
}

export default connect(mapStateToProps)(DashboardPage)
