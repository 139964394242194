import React, { Component } from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { FormattedMessage } from "react-intl"
import AreaForm from "../../components/forms/AreaForm"
import MaterialIcon from "../../components/elements/MaterialIcon"
import NewAreaSkill from "../../components/forms/NewAreaSkill"
import history from "../../routes/history"

class AreaCreatePage extends Component {
	render() {
		return (
			<div className="clients-create-page private-page">
				<div className="top-actions-row">
					<Link to="/areas" className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /> <FormattedMessage id="buttons.back_to_area" /> </Link>
				</div>
				<Card className="clients-card fadeInLoadUp">
					<Card.Body>
						{history.location.pathname === '/areas/create' &&
						<AreaForm />
						}
						{history.location.pathname === '/areas/skill/create' &&
						<NewAreaSkill />
						}
					</Card.Body>
				</Card>
			</div>
		)
	}
}

AreaCreatePage.propTypes = {
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(AreaCreatePage)
