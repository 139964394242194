import getStaticUrl from "./api"

// on row click will display the reume on new page to view resume 
export const onRowClick = (state, rowInfo , column , instance , showPDdf , ShowPdf, setCounterPages) => {
    if (rowInfo && rowInfo.row) {
        setCounterPages(rowInfo.pageSize)
        return {
        onClick: (e) => {
            console.log("column.Header", column.Header);
            if(column.Header !== 'Actions' && column.Header !== 'Top 4 Stacks' && column.Header !== 'Level'){
                showPDdf({...ShowPdf , status:true , rowId:rowInfo.row.id})
                // window.open(`${getStaticUrl()}/pdf/resumes/${rowInfo.row.id}/view`,'_blank');
            }
        },
        style: {
            'cursor': rowInfo ? 'pointer' : 'inherit',
            }
        }
    }else{
        return {}
    }
}