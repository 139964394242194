import PropTypes from "prop-types"
import React from "react"
import { Breadcrumb } from "react-bootstrap"
import DashboardHeader from "../navigation/DashboardHeader"
import DashboardSidebar from "../navigation/DashboardSidebar"

class Layout extends React.Component {
	state = {
		asideOpen: true
	}

	toggleSidebar = () => {
		const { asideOpen } = this.state
		if (asideOpen) {
			document.querySelector("#sidebar").className = "sidebar toggled"
			document.querySelector("#side-btn").className = " btn asideOpen"
		} else {
			document.querySelector("#sidebar").className = "sidebar"
		}
		this.setState({
			asideOpen: !asideOpen,
		})

	}

	render() {
		const { childComponent } = this.props
		
		const { asideOpen } = this.state

		const pageTitle = childComponent.props.name || ""

		return (
			<div className="dashboard-page">
				<DashboardSidebar onToggleSidebar={this.toggleSidebar} />
				<div className="main">
					<DashboardHeader asideOpen={asideOpen} onToggleSidebar={this.toggleSidebar} />
					<div>
						<Breadcrumb>
							<span>  {pageTitle} </span>
							{/* <Breadcrumb.Item className="ml-auto" active href="#">Dashboard</Breadcrumb.Item>
							<li className="breadcrumb-item">
								<Link to="/dashboard">Dashboard</Link>
							</li> */}
						</Breadcrumb>
					</div>
					<div className="dashbord-content-wrapper">
						{childComponent}
					</div >
				</div>
			</div>
		)
	}
}

Layout.propTypes = {
	childComponent: PropTypes.element.isRequired,
}

export default Layout
