import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import {FormattedMessage} from "react-intl"
import MaterialIcon from "./MaterialIcon"
import {useParams} from 'react-router-dom'
const SkillsTableActionsCell = ({ cellProps, setShowDeleteModal }) => {
	const {id} = useParams();
	return (
		<ButtonToolbar aria-label="Actions" className="table-actions-wrapper user-table-actions">

			<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-edit-${cellProps.index}`}><FormattedMessage id="buttons.edit_skill" /></Tooltip>}>
				<Link to={{pathname:`/skills/${cellProps.original.id}/edit` , state:id}} className="btn btn-primary btn-icons"><MaterialIcon icon="edit" /></Link>
			</OverlayTrigger>

			<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-delete-${cellProps.index}`}><FormattedMessage id="buttons.delete_skill" /></Tooltip>}>
				<Button variant="danger" onClick={() => setShowDeleteModal(cellProps.index)}  ><MaterialIcon icon="delete" /></Button>
			</OverlayTrigger>

		</ButtonToolbar>
	)
}

SkillsTableActionsCell.propTypes = {
	cellProps: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
		index: PropTypes.number,
	}).isRequired,
	setShowDeleteModal: PropTypes.func.isRequired,
}

export default SkillsTableActionsCell
