import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { Card } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import MaterialIcon from "../../components/elements/MaterialIcon"
import { getResumesAction } from "../../actions/resumesActions"
import ResumesTable from "../../components/tables/ResumesTable"
import axios from 'axios'
import AreaSkills from "../../components/forms/Areas_Skills_Search"
class ResumePage extends Component {
	state={
		search:"",
		SearchResults:{},
		searchToogle: false,
		pageSizeFilter: 15
	
	}
	// search for skills , areas , languages
	ASLSearch = (e , field) => {
		let fieldValues = e.target.value.split(',');
		this.setState({SearchValues:{...this.state.SearchValues , [field]:fieldValues[0].length >1  ? fieldValues : [] }});
	}
	// handle serach button 
	HandleSearch = async () =>{
		await axios.post(`${process.env.REACT_APP_API_URL}/employees/search`,this.state.SearchValues).then(res =>{
			this.setState({SearchResults:res.data});
		}).catch(err => console.log(err));
	}

	handelPageSizeFilter = (pageSizeReally) => {
		this.setState({pageSizeFilter : pageSizeReally})
		console.log("this.state.pageSizeFilter", this.state.pageSizeFilter);
	}

	componentDidMount() {
		this.props.getResumesAction({ pageSize: 15, sorted: null, filters: [], page: 1 });
		console.log(this.state.searchToogle);
	}
	updateSearchResult  = (SearchResults) =>{
		console.log("{SearchResults}", {SearchResults})
		this.setState({SearchResults})
	}
	handelClick() {
		this.setState({searchToogle : !this.state.searchToogle})
		console.log(this.state.searchToogle);
		if(this.state.searchToogle) {
			this.setState({search:''});
		}
	}
	render() {
		return (
			<div>
				<div className={`top-actions-row flex-between`}>
					<Link to="resumes/create" className="btn btn-primary btn-sm btn-roundet btn-dropshadow"><MaterialIcon icon="add" /><FormattedMessage id="buttons.new_resume" /></Link>
					{/* <Link to="resumes/create" className="btn btn-primary btn-sm btn-roundet btn-dropshadow"><FormattedMessage id="buttons.new_resume" /></Link> */}
					<div className={`border flex-align-center outer-Table-Search ${this.state.searchToogle && 'open-parent-search-outer'} `}>
						<input id="generalSearch" placeholder="Search" className={`pl-2 border-0 ${this.state.searchToogle && 'open-search'}`} onChange={(e) => this.setState({search:e.target.value}) } />
						<label htmlFor='generalSearch' className="mb-0 p-1" onClick={() => this.handelClick()} ><MaterialIcon icon={`${this.state.searchToogle ? 'close': 'search'}`} /></label>
						{/* icon={`${this.state.searchToogle ? 'close': 'search'}`} */}
					</div>
				</div>
				<div className='Advanced-Search'>
					<AreaSkills updateSearch={this.updateSearchResult} pageSizeFilter={this.state.pageSizeFilter} />
				</div>
				<Card className="fadeInLoadUp" >
					<Card.Body>
						<ResumesTable SearchRow={this.state.search} SearchResults={this.state.SearchResults} archive={false} handelPageSizeFilter={this.handelPageSizeFilter}/>
					</Card.Body>
				</Card>
			</div>
		)
	}
}

ResumePage.propTypes = {
	getResumesAction: PropTypes.func.isRequired,
	resumes: PropTypes.shape({
		isLoading: PropTypes.bool,
		isExporting: PropTypes.bool,
		hasError: PropTypes.bool,
		filters: PropTypes.arrayOf(PropTypes.object),
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number.isRequired
			})
		})
	}).isRequired
}

const mapStateToProps = (state) => ({
	resumes: state.resumes
})

export default connect(mapStateToProps, { getResumesAction })(ResumePage)
