import React from "react"
	
 
const NotFoundPage = (props) => (
	<div>
		NotFoundPage 404
	</div>
)
NotFoundPage.propTypes = {
}
export default NotFoundPage
