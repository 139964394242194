import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { Card } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import SkillsTable from "../../components/tables/SkillsTable"
import MaterialIcon from "../../components/elements/MaterialIcon"
import { getSkillsAction } from "../../actions/skillsActions"
import { searchAreaSkills } from "../../actions/areasActions"

class SkillPage extends Component {

	// componentDidMount() {
	// 	this.props.getSkillsAction({ pageSize: 15, sorted: null, filters: [], page: 1 });
	// }

	render() {
		return (
			<div>
				<div className="top-actions-row">
					<Link to="/areas" className="btn btn-primary btn-sm btn-roundet btn-dropshadow"><MaterialIcon icon="arrow_back" /><FormattedMessage id="buttons.back_to_areas" /></Link>
					<Link to="skills/create" className="btn btn-primary btn-sm btn-roundet btn-dropshadow ml-3"><MaterialIcon icon="add" /><FormattedMessage id="buttons.new_skill" /></Link>
				</div>
				<Card className="fadeInLoadUp" >
					<Card.Body>
						<SkillsTable />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

SkillPage.propTypes = {
	getSkillsAction: PropTypes.func.isRequired,
	searchAreaSkills: PropTypes.func.isRequired,
	skills: PropTypes.shape({
		isLoading: PropTypes.bool,
		isExporting: PropTypes.bool,
		hasError: PropTypes.bool,
		filters: PropTypes.arrayOf(PropTypes.object),
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number.isRequired
			})
		})
	}).isRequired,

}

const mapStateToProps = (state) => ({
	skills: state.skills
})

export default connect(mapStateToProps, { getSkillsAction, searchAreaSkills })(SkillPage)
