/**
 * Contains all API base URL-s, tokens, and other stuff
 */
class APIService {

	/**
	 * Creates a API url by concatenating Host url with given route
	 * @param route to get full api url
	 */
	// url = (route) => (`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/${route}`);
	url = (route) => (`${process.env.REACT_APP_API_URL}/${route}`);

	getStaticUrl = () =>  process.env.REACT_APP_API_URL;

}

export default new APIService()
