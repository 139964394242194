import React from "react"
import PropTypes from "prop-types"
import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap"
import {FormattedMessage} from "react-intl"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import MaterialIcon from "./MaterialIcon"

const ClientsTableActionsCell = ({ cellProps, setShowDeleteModal }) => {
	return (
		<ButtonToolbar aria-label="Actions" className="table-actions-wrapper user-table-actions">

			{/* <OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-new-resume-${cellProps.index}`}><FormattedMessage id="buttons.new_resume" /></Tooltip>}>
				<Link to={`/clients/${cellProps.original.id}/resumes/create`} className="btn btn-success btn-icons" ><MaterialIcon icon="add" /></Link>
			</OverlayTrigger> */}

			{/* <OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-resumes-${cellProps.index}`}><FormattedMessage id="buttons.view_resumes" /></Tooltip>}>
				<Link to={`/clients/${cellProps.original.id}/resumes`} className="btn btn-warning btn-icons"><MaterialIcon icon="visibility" /></Link>
			</OverlayTrigger> */}

			<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-edit-${cellProps.index}`}><FormattedMessage id="buttons.edit_client" /></Tooltip>}>
				<Link to={`/clients/${cellProps.original.id}/edit`} className="btn btn-primary btn-icons"><MaterialIcon icon="edit" /></Link>
			</OverlayTrigger>

			<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-delete-${cellProps.index}`}><FormattedMessage id="buttons.delete_client" /></Tooltip>}>
				<Button variant="danger" onClick={() => setShowDeleteModal(cellProps.index)}  ><MaterialIcon icon="delete" /></Button>
			</OverlayTrigger>

		</ButtonToolbar>
	)
}

ClientsTableActionsCell.propTypes = {
	cellProps: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.number,
		}),
		index: PropTypes.number,
	}).isRequired,
	setShowDeleteModal: PropTypes.func.isRequired
}
export default ClientsTableActionsCell
