import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import HandleError from "../../services/HandleError";
import MaterialIcon from "../elements/MaterialIcon";

const SkillsAreaDropdown = ({Skills}) => {

    const [skillsOptionsArea, setSkillsOptionsArea] = useState([])
    const [showSkills, setShowSkills] = useState(false)
    useEffect(() => {
        setSkillsOptionsArea(Skills);
    }, [])

    const handelClick = (e) => {
        e.stopPropagation()
    }

    return(
        <div className={`skills-dropdown ${showSkills ? 'show-skills-icon' : ''}`} onClick={(e) => handelClick(e)}>
            <h4 onClick={() => setShowSkills(!showSkills)} className='direct-icon'> <MaterialIcon icon="arrow_back_ios"/></h4>
            <ul className={`skills-menu ${showSkills ? 'show-skills' : ''}`}>
                    {skillsOptionsArea&&skillsOptionsArea.map((item,index) => !item.archived&& <li key={index}>{item.name}</li>)}
            </ul>
        </div>
    )
}
export default SkillsAreaDropdown;