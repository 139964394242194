import cogoToast from "cogo-toast"
import AuthService from "../services/AuthService"
import { users } from "./types"
import HandleError from "../services/HandleError"
import history from "../routes/history"

const authService = new AuthService()

export const loginAction = (values, setSubmitting, setErrors) => dispatch => {
	authService.login(values).then((res) => {
		dispatch({ type: users.USER_LOGGED_IN, payload: res.data.data })
	}).catch((error) => {
		HandleError(error, setErrors)
		dispatch({ type: users.USER_LOGGED_IN_ERROR, payload: error })
	}).finally(() => {
		setSubmitting(false)
	})
}

// export const asyncAction = (payload) => {
// 	console.log(payload)
// 	return ((dispatch) => {
// 		dispatch({
// 			type: "ASYNC_ACTION",
// 			payload: {
// 				message: "started async",
// 				isLoading: true,
// 			}
// 		})
// 		setTimeout(() => {
// 			dispatch({
// 				type: "ASYNC_ACTION",
// 				payload: {
// 					message: "finnish async after 2s",
// 					isLoading: false
// 				}
// 			})
// 		}, 2000)
// 	})
// }

// try {
// 	const res = await authService.login(values)
// 	dispatch({
// 		type: USER_LOGGED_IN,
// 		payload:res.data
// 	})
// } catch (error) {
// 	HandleError(error, setErrors)
// } finally {
// 	setSubmitting(false)
// }

export const forgotPasswordAction = (data, setErrors, setSubmitting) => async dispatch => {
	dispatch({ type: users.FORGET_PASSWORD_STARTED })
	try {
		await authService.forgot_password(data)
		cogoToast.success("An email with reset password link is sendet to your email address.", { position: "top-center" })
		dispatch({ type: users.FORGET_PASSWORD_SUCCESS })
		history.push("/login")
	} catch (error) {
		HandleError(error, setErrors)
	}
	finally {
		setSubmitting(false)
	}
}

export const logOutAction = (payload) => ({
	type: users.USER_LOGGED_OUT,
	payload
})

// return (async (dispatch)=>{
// 	try {
// 		const res = await userService.login(payload)
// 		dispatch({

// 		})

// 	} catch (error) {
// 		HandleError(error)
// 	}
// })

// return ({
// 	type: "LOGIN_ERROR",
// 	payload: {
// 		login_error: true
// 	}
// })
// }

// const login = formValues =>() {
// 	try {
// 		const response = await userService.login(formValues)
// 		userService.setLoggedInUser(response.data)
// 		dispatch({ type: USER_LOGGED_IN, payload: response.data })
// 	} catch (err) {
// 		HandleError(err)
// 	}
// }


export const resetPassword = (formValues, setSubmitting, setErrors) => async dispatch => {
	try {
		await authService.reset_password(formValues)
		cogoToast.success("Your password has been reset.", { position: "bottom-right", heading: "Success!" })
		history.push("/login")
	} catch (error) {
		HandleError(error, setErrors)
	}
	finally {
		setSubmitting(false)
	}
}

// export { forgotPassword, resetPassword}
