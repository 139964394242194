import Axios from "axios";
import API from "./APIService";
import BaseService from "./BaseService";

class EmployeeResumesService extends BaseService {
	component = null;

	constructor(component = null) {
		super();
		this.component = component;
	}

	baseServicePath = () => {
		return API.url("employees");
	};

	getResumes(employeeId, params = {}) {
		const url = `${this.baseServicePath()}/${employeeId}/resumes?${params}`;
		return Axios.get(url, params);
	}
}

export default EmployeeResumesService;
