import cogoToast from "cogo-toast"
import ClientsService from "../services/ClientsService"
import history from "../routes/history"
import HandleError from "../services/HandleError"
import { clients } from "./types"
import getTransformedFilters from "../helpers/filterTransformer"
import Axios from "axios"

const clientsService = new ClientsService(this)

export const getClientsAction = ({ pageSize, sorted, filters, page }) => async dispatch => {
	const query = `per_page=${pageSize}&${getTransformedFilters(filters)}`
	dispatch({ type: clients.GET_CLIENTS_STARTED })
	try {
		const response = await clientsService.index(page, query)
		dispatch({ type: clients.GET_CLIENTS_SUCCESS, payload: response.data })
	} catch (err) {
		HandleError(err)
		dispatch({ type: clients.GET_CLIENTS_ERROR })
	}
}

export const createClientAction = (values, setSubmitting, setErrors) => async dispatch => {

	dispatch({ type: clients.CREATE_CLIENT_STARTED })
	try {
		const response = await clientsService.store(values)
		dispatch({ type: clients.CREATE_CLIENT_SUCCESS, payload: { ...response.data } })
		cogoToast.success("Client created successfully.", { position: "top-center" })
		history.push("/clients")
	} catch (err) {
		HandleError(err, setErrors)
		dispatch({ type: clients.CREATE_CLIENT_ERROR })
	} finally {
		setSubmitting(false)
	}

}

export const updateClientAction = (id, values, setSubmitting, setErrors) => async dispatch => {

	dispatch({ type: clients.UPDATE_CLIENT_STARTED })
	try {
		const response = await clientsService.update(id, values)
		dispatch({ type: clients.UPDATE_CLIENT_SUCCESS, payload: { ...response.data } })
		cogoToast.success("Client saved successfully.", { position: "top-center" })
		history.push("/clients")
	} catch (err) {
		HandleError(err, setErrors)
		dispatch({ type: clients.UPDATE_CLIENT_ERROR })
	} finally {
		setSubmitting(false)
	}

}

export const showClientAction = (id, handleFinish) => async dispatch => {

	dispatch({ type: clients.SHOW_CLIENT_STARTED })
	try {
		const response = await clientsService.show(id)
		dispatch({ type: clients.SHOW_CLIENT_SUCCESS, payload: { ...response.data.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: clients.SHOW_CLIENT_ERROR })
	} finally {
		handleFinish()
	}
}

export const deleteClientAction = (formData, handleFinish) => async dispatch => {
	console.log("CLIENT", formData)

	dispatch({ type: clients.DELETE_CLIENT_STARTED })
	// const { id } = formData
	const id = formData;
	try {
		await clientsService.delete(id)
		handleFinish()
		dispatch({ type: clients.DELETE_CLIENT_SUCCESS, payload: { id } })
		cogoToast.success("Client deleted successfully.", { position: "top-center" })
	} catch (err) {
		handleFinish()
		HandleError(err)
		dispatch({ type: clients.DELETE_CLIENT_ERROR })
	}
}

export const searchClients = (inputValue) => async () => {
	try {
		const response = await clientsService.search(inputValue || " ")
		return response.data.map((item) => ({
			id: item.id,
			full_name: item.full_name
		}))
	} catch (error) {
		HandleError(error)
	}
}

export const FilterClients = (inputValue) => async dispatch => {
	dispatch({ type: clients.GET_CLIENTS_STARTED })
	try {
		const response = await Axios.get(`${process.env.REACT_APP_API_URL}/clients?term=${inputValue}`)
		dispatch({ type: clients.GET_CLIENTS_SUCCESS, payload: { ...response} })
	} catch (error) {
		HandleError(error);
		dispatch({ type: clients.GET_CLIENTS_ERROR })
	}
}

export const setClientsFiltersAction = ({ pageSize, sorted, filters, page }) => dispatch => {
	dispatch({
		type: clients.SET_CLIENT_FILTERS,
		payload: filters
	})
	dispatch(getClientsAction({ pageSize, sorted, filters, page }))
}
