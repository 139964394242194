// Each component has it's different submit method
export const methods = {
	EmployeeEditPage: "updateEmployee",
	EmployeeCreatePage: "createEmployee",
	ResumeEditPage: "updateResume",
	ResumeCreatePage: "createResume",
	EmployeeResumesEditPage: "updateResumeFromEmployee",
	EmployeeResumesCreatePage: "createResumeFromEmployee",
	ClientResumesEditPage: "updateResumeFromClient",
	ClientResumesCreatePage: "createResumeFromClient"
}

// Employee fields that are going to be cleared when changing the employee in the dropdown
export const fields = [
	"employee_level",
	"first_name",
	"last_name",
	"phone",
	"job_title",
	"summary",
	"email",
	"birthday",
	"birth_place",
	"country",
	"educations",
	"experiences",
	"courses",
	"abilities",
	"languages",
	"links",
]
