import API from "./APIService"
import BaseService from "./BaseService"

class LanguagesService extends BaseService {
	component = null

	constructor(component = null) {
		super()
		this.component = component
	}

	baseServicePath = () => {
		return API.url("languages")
	}
}

export default LanguagesService
