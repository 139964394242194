import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {  Route, Redirect } from "react-router-dom"

const PublicRoute = ({component: Component, isAuthenticated, ...rest}) => (
	<Route {...rest} render={(props)=> !isAuthenticated ? ( <Component {...props}/> ) : (<Redirect to='/dashboard' />)} />
)


const mapStateToProps = (state) =>({ isAuthenticated: !!state.auth.token } )

PublicRoute.propTypes = {
	component: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(PublicRoute)
