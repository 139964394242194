import { skills } from "../actions/types"

const defaultSkillsData = {
	data: [],
	redirectUrl: null,
	isLoading: true,
	hasError: false,
	error: null,
	isShowing: false,
	isLoadingActions: false,
	isSavingPermissions: false,
	isRedirecting: false,
	isExporting: false,
	isDeleting: false,
	isRestoring:false,
	isValidating:false,
	filters: [],
	meta: {
		pagination: {
			total: 0,
			count: 0,
			per_page: 15,
			current_page: 1,
			total_pages: 1,
		}
	}
}

const skillsReducer = (state = defaultSkillsData, action = []) => {
	switch (action.type) {

		case skills.GET_SKILLS_STARTED:
			return { ...state, isLoading: true }

		case skills.GET_SKILLS_SUCCESS:
			if (action.payload)
				return { ...state, ...action.payload, isLoading: false }
			return state

		case skills.GET_SKILLS_ERROR:
			return { ...state, isLoading: false, hasError: true }

		case skills.SHOW_SKILLS_STARTED:
			return { ...state, isShowing: true }

		case skills.SHOW_SKILLS_SUCCESS:
			if (!action.payload.id)
				return state
			if (state.data.find(item => (item.id === action.payload.id)))
				return { ...state, isShowing: false, data: state.data.map(item => (item.id === action.payload.id ? action.payload : item)) }
			return { ...state, data: [...state.data, action.payload], isShowing: false }

		case skills.SHOW_SKILLS_ERROR:
			return { ...state, isShowing: false, hasError: true }

		case skills.CREATE_SKILLS_STARTED:
			return { ...state, isSaving: true }

		case skills.CREATE_SKILLS_SUCCESS:
			if (action.payload)
				return {
					...state,
					data: [...state.data, { ...action.payload.data }],
					isSaving: false
				}
			return state

		case skills.CREATE_SKILLS_ERROR:
			return { ...state, isSaving: false, hasError: true }

		case skills.DELETE_SKILLS_STARTED:
			return {...state, isDeleting:true}

		case skills.DELETE_SKILLS_SUCCESS:
			if (action.payload)
				return {...state, data: state.data.filter(item => (item.id !== action.payload.id)), isDeleting: false}
			return state

		case skills.DELETE_SKILLS_ERROR:
			return { ...state, isDeleting:false, hasError:true }
		default:
			return state
	}
}


export default skillsReducer
