import { employeeResumes } from "../actions/types"

const defaultTemplatesData = {
	data: [],
	isLoading: true,
	isShowing: true,
	hasError: false,
	per_page: 15,
	current_page: 1,
	filters: [],
	isLoadingActions:false,
	meta: {
		pagination: {
			total: 0,
			count: 0,
			per_page: 15,
			current_page: 1,
			total_pages: 1,
		}
	}
}


const employeeResumesReducer = (state = defaultTemplatesData, action) => {
	switch (action.type) {
		case employeeResumes.GET_EMPLOYEE_RESUMES_STARTED:
			return { ...state, isLoading: true }

		case employeeResumes.GET_EMPLOYEE_RESUMES_SUCCESS:
			return { ...state, ...action.payload, isLoading: false }

		case employeeResumes.GET_EMPLOYEE_RESUMES_ERROR:
			return { ...state, isLoading: false }
		case employeeResumes.DELETE_EMPLOYEE_RESUMES_STARTED:
			return { ...state, isLoading: true }

		case employeeResumes.DELETE_EMPLOYEE_RESUMES_SUCCESS:
			if (action.payload)
				return { ...state, data: state.data.filter(item => (item.id !== action.payload.id)), isLoading: false }
			return state

		case employeeResumes.DELETE_EMPLOYEE_RESUMES_ERROR:
			return { ...state, isLoading: false }
		default:
			return state
	}
}

export default employeeResumesReducer
