import cogoToast from "cogo-toast"
import SkillsService from "../services/SkillsService"
import history from "../routes/history"
import HandleError from "../services/HandleError"
import { skills } from "./types"
import getTransformedFilters from "../helpers/filterTransformer"
import Axios from "axios"

const skillService = new SkillsService(this)

export const getSkillsAction = ({ pageSize, sorted, filters, page }) => async dispatch => {
	const query = `per_page=${pageSize}&${getTransformedFilters(filters)}`
	dispatch({ type: skills.GET_SKILLS_STARTED })
	try {
		const response = await skillService.index(page, query)
		dispatch({ type: skills.GET_SKILLS_SUCCESS, payload: { ...response.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: skills.GET_SKILLS_ERROR })
	}
}

export const createSkillAction = (values, setSubmitting, setErrors) => async dispatch => {

	dispatch({ type: skills.CREATE_SKILLS_STARTED })
	try {
		const response = await skillService.store(values)
		dispatch({ type: skills.CREATE_SKILLS_SUCCESS, payload: { ...response.data } })
		cogoToast.success("Skill created successfully.", { position: "top-center" })
		history.push(`/areas/${values.area_id}/skills`)
	} catch (err) {
		HandleError(err, setErrors)
		dispatch({ type: skills.CREATE_SKILLS_ERROR })
	} finally {
		setSubmitting(false)
	}

}

export const updateSkillAction = (id, values, setSubmitting, setErrors) => async dispatch => {

	dispatch({ type: skills.UPDATE_SKILLS_STARTED })
	try {
		const response = await skillService.update(id, values)
		dispatch({ type: skills.UPDATE_SKILLS_SUCCESS, payload: { ...response.data } })
		cogoToast.success("Skill saved successfully.", { position: "top-center" })
		history.push(`/areas/${values.area_id}/skills`)
	} catch (err) {
		HandleError(err, setErrors)
		dispatch({ type: skills.UPDATE_SKILLS_ERROR })
	} finally {
		setSubmitting(false)
	}

}

export const showSkillAction = (id, handleFinish) => async dispatch => {

	dispatch({ type: skills.SHOW_SKILLS_STARTED })
	try {
		const response = await skillService.show(id)
		dispatch({ type: skills.SHOW_SKILLS_SUCCESS, payload: { ...response.data.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: skills.SHOW_SKILLS_ERROR })
	} finally {
		handleFinish()
	}
}

export const deleteSkillAction = (id, handleFinish) => async dispatch => {

	dispatch({ type: skills.DELETE_SKILLS_STARTED })
	try {
		await skillService.delete(id)
		handleFinish()
		dispatch({ type: skills.DELETE_SKILLS_SUCCESS, payload: { id } })
		cogoToast.success("Skill deleted successfully.", { position: "top-center" })
	} catch (err) {
		handleFinish()
		HandleError(err)
		dispatch({ type: skills.DELETE_SKILLS_ERROR })
	}
}

export const searchSkills = (inputValue, area_id) => async () => {
	try {
		const response = await skillService.search(inputValue || " ", "",area_id)
		console.log(response.data)
		return response.data.map((item) => ({
			id: item.id,
			name: item.name
		}))
	} catch (error) {
		HandleError(error)
	}
}

//get skills related to area 
export const getSkillsArea = ({AreaID, pageSize, filters, page }) => async dispatch => {
	console.log("pageSize", pageSize);
	try {
		const query = `per_page=${pageSize}&${getTransformedFilters(filters)}&page=${page}`
		dispatch({ type: skills.GET_SKILLS_STARTED })
		const response = await Axios.get(`${process.env.REACT_APP_API_URL}/areas/${AreaID}/skills?${query}`)
		console.log({res:response.data})
		dispatch({ type: skills.GET_SKILLS_SUCCESS, payload: { ...response.data} })

	} catch (error) {
		HandleError(error);
		dispatch({ type: skills.GET_SKILLS_ERROR })
	}
}

// for search by name in backedn 
export const FilterSkills = (inputValue , areaID) => async dispatch => {
	dispatch({ type: skills.GET_SKILLS_STARTED })
	try {
		const response = await Axios.get(`${process.env.REACT_APP_API_URL}/skills?term=${inputValue}&parent_id=${areaID}`)
		console.log({response})
		dispatch({ type: skills.GET_SKILLS_SUCCESS, payload: { ...response} })
	} catch (error) {
		HandleError(error);
		dispatch({ type: skills.GET_SKILLS_ERROR })
	}
}