import React, { Component } from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { FormattedMessage } from "react-intl"
import SkillForm from "../../components/forms/SkillForm"
import MaterialIcon from "../../components/elements/MaterialIcon"
import history from "../../routes/history"

class SkillCreatePage extends Component {

	render() {
		return (
			<div className="clients-create-page private-page">
				<div className="top-actions-row">
					<Link to={`${history.location.pathname === '/areas/skill/create' ? '/areas' : `/areas/${this.props.match.params.id}/skills`}`}  className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /> <FormattedMessage id="buttons.back_to_skill" /> </Link>
				</div>
				<Card className="clients-card fadeInLoadUp">
					<Card.Body>
						<SkillForm AreaID={this.props.match.params.id} />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

SkillCreatePage.propTypes = {
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(SkillCreatePage)
