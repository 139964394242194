import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {  Route, Redirect } from "react-router-dom"
import Layout from "../components/layout/Layout"

const PrivateRoute = ({ component: Component, path, isAuthenticated,name, ...rest}) => (
	<Route {...rest} render={(props)=> isAuthenticated ?
		<>
			<Layout childComponent= {<Component {...props} name={name} />}/>
		</>
		: <Redirect to='/login' />} />
)

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.auth.token
})

PrivateRoute.propTypes = {
	component:PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
	name:PropTypes.string.isRequired,
	path:PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(PrivateRoute)
