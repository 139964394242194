import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { Card } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import MaterialIcon from "../../components/elements/MaterialIcon"
import { getAreaSkillsAction } from "../../actions/areaSkillsActions"
import history from "../../routes/history"
import AreasTable from "../../components/tables/AreasTable"
import SkillsTable from "../../components/tables/SkillsTable"

class AreaSkillsPage extends Component {

	state = {
		itemAreaSkillsIndex: -1
	}

	// componentDidMount() {
    //     console.log(this.props);
	// 	const { match, history } = this.props

	// 	if (!match.params.id)
	// 		history.push("/areas")

	// 	this.props.getAreaSkillsAction(match.params.id, this.handleFinish)
		
	// }
	componentDidMount() {
		// this.props.getAreaSkillsAction({ pageSize: 15, sorted: null, filters: [], page: 1 })
	}


	handleFinish = () => {
		const { area_skills, match } = this.props
		// const itemAreaSkillsIndex = area_skills.data.findIndex(item => (item.id === parseInt(match.params.id, 10)))

		// this.setState({ itemAreaSkillsIndex })
	}



	render() {
		return (
			<div>
				<div className="top-actions-row">
					<Link to="skills/create" className="btn btn-primary btn-sm btn-roundet btn-dropshadow"><MaterialIcon icon="add" /><FormattedMessage id="buttons.new_skill" /></Link>
				</div>
				<Card className="fadeInLoadUp" >
					<Card.Body>
						<SkillsTable />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

AreaSkillsPage.propTypes = {
	getAreaSkillsAction: PropTypes.func.isRequired,
	area_skills: PropTypes.shape({
		isLoading: PropTypes.bool,
		isExporting: PropTypes.bool,
		hasError: PropTypes.bool,
		filters: PropTypes.arrayOf(PropTypes.object),
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number.isRequired
			})
		}),
		data: PropTypes.array
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string
		})
	}).isRequired,
}

const mapStateToProps = (state) => ({
	area_skills: state.area_skills
})

export default connect(mapStateToProps, { getAreaSkillsAction })(AreaSkillsPage)
