import axios from "axios"
import {users} from "../actions/types"
import AuthService from "../services/AuthService"

const defaultUserLoginData = {
	user: null,
	token:null,
	roles: null,
	permissions:null
}

const authService = new AuthService(null)

const userReducer = (state = defaultUserLoginData, action = {}) => {

	switch (action.type) {

	case "USER_LOGGED_IN": {
		if (action.payload && action.payload.token) {

			axios.defaults.headers.common["X-localization"] = (action.payload.user && action.payload.user.language) ? action.payload.user.language:"it"
			
			axios.defaults.headers.common.Authorization = `Bearer ${action.payload.token}`
			
			localStorage.setItem("loggedInUserData", JSON.stringify(action.payload))
			
			return { 
				...state, 
				user: action.payload.user, 
				token: action.payload.token,
				roles: action.payload.user.roles, 
				permissions: action.payload.user.permissions
			}
		}
		return defaultUserLoginData
	}
	case users.UPDATE_USER_PROFILE_STARTED:
		return state

	case users.UPDATE_USER_PROFILE_SUCCESS:{

		const userLoginData = authService.getLoggedInUser()

		axios.defaults.headers.common["X-localization"] = (action.payload && action.payload.language) ? action.payload.language : "it"

		userLoginData.user = action.payload
		authService.setLoggedInUser(userLoginData)	
		return { ...state, user: action.payload }

	}


	case users.UPDATE_USER_PROFILE_ERROR:
		return state

	default:
		return state
	}
} 



export default userReducer
