import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import { SingleDatePicker } from "react-dates";
import Select from "react-select";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { monthAndYearDropdown } from "../../../helpers/dateUtils";
import {
	createEmployeeAction,
	updateEmployeeAction,
} from "../../../actions/employeesActions";
import { searchEmployeeLevels } from "../../../actions/employeeLevelActions";
import FormikInput from "../../inputs/FormikInput";
import MaterialIcon from "../../elements/MaterialIcon";
import history from "../../../routes/history";

const EmployeeGeneral = ({
	isSubmitting,
	errors,
	touched,
	handleChange,
	values,
	component,
	setFieldValue,
	setFieldTouched,
	searchEmployeeLevels,
}) => {
	const [focused, setFocus] = useState(false);
	const [birthday, setBirthday] = useState(null);
	const [employeeLevelOptions, setEmployeeLevelOptions] = useState(null);
	const [monthSelected, setMonthSelected] = useState(null);
	const [yearSelected, setYearSelected] = useState(null);
	const countriesCode = [
		{ label: "Albania", value: "Albania" },
		{ label: "Kosovo", value: "Kosovo" },
		{ label: "Macedonia", value: "Macedonia" },
		{ label: "Egypt", value: "Egypt" },
	];

	useEffect(() => {
		setFieldValue("summary", "");
		setFieldValue("birth_place", "");
		setFieldValue("address", "");
		const getDefaultEmployeeLevelOptions = async () => {
			const employeeLevelOptions = await searchEmployeeLevels("");
			setEmployeeLevelOptions(employeeLevelOptions);
		};
		getDefaultEmployeeLevelOptions();
		if (
			!window.location.href.includes("employees") &&
			!window.location.href.includes("clients")
		) {
			setFieldValue("tariff", "");
		}
	}, []);

	const onBirthdayChange = (date) => {
		console.log("date", date);
		if (date) {
			setFieldValue("birthday", date.format("YYYY-MM-DD"));
			setBirthday(date);
		} else {
			setFieldValue("birthday", date);
			setBirthday(date);
		}
	};

	const handleChangeEmployeeLevel = (value) => {
		setFieldValue("employee_level", value);
		if (value !== null) {
			setFieldValue("employee_level_id", value.id);
		} else {
			setFieldValue("employee_level", "");
			setFieldValue("employee_level_id", null);
			setFieldTouched("employee_level", true);
		}
	};

	const handleCountriesChange = (e) => {
		setFieldValue(`country`, e.value);

		if (!e) {
			setFieldTouched(`country`, true);
		}
	};

	return (
		<>
			{!isSubmitting && (
				<div className="inputs-wrapper form__short">
					<Form.Group
						controlId="input_employee_level_id"
						className="floating-label"
					>
						<AsyncSelect
							id="employee_level_id"
							cacheOptions
							loadOptions={debounce(searchEmployeeLevels, 100)}
							defaultOptions={employeeLevelOptions}
							isClearable
							getOptionValue={(item) => item.id}
							getOptionLabel={(item) => item.name}
							onChange={(value) =>
								handleChangeEmployeeLevel(value)
							}
							onBlur={() =>
								setFieldTouched("employee_level", true)
							}
							value={values.employee_level}
							placeholder="Employee level"
							noOptionsMessage={() => "Type to search"}
							className={`select-input ${
								!!errors.employee_level_id &&
								touched.employee_level_id &&
								"is-invalid"
							}`}
							isRequired
						/>
						{/* <Form.Control.Feedback type='invalid'>{errors.employee_level}</Form.Control.Feedback> */}
						<div
							className={`select-input ${
								errors.employee_level && "is-invalid"
							}`}
						>
							{" "}
						</div>
						<Form.Control.Feedback type="invalid">
							{errors.employee_level}
						</Form.Control.Feedback>
					</Form.Group>
					<div className="Resume_Image">
						<label htmlFor="profile">
							<MaterialIcon icon="add_a_photo" />
							{values.profile_image && (
								<img
									src={
										typeof values.profile_image === "string"
											? values.profile_image
											: URL.createObjectURL(
													values.profile_image
											  )
									}
									alt="Resume_image"
								/>
							)}
						</label>
						<input
							id="profile"
							hidden
							type="file"
							name="Profile_pic"
							onChange={(e) =>
								setFieldValue(
									"profile_image",
									e.target.files[0]
								)
							}
						/>
					</div>
					<FormikInput
						type="text"
						name="first_name"
						label="First Name"
						error={errors.first_name}
						required
					/>
					<FormikInput
						type="text"
						name="last_name"
						label="Last Name"
						error={errors.last_name}
						required
					/>
					{!history.location.pathname.includes("clients") &&
						!history.location.pathname.includes("employees") && (
							<FormikInput
								type="text"
								name="phone"
								label="Phone"
								error={errors.phone}
								required
							/>
						)}
					<FormikInput
						type="text"
						name="job_title"
						label="Job title"
						error={errors.job_title}
						required
					/>

					<Row>
						<Col className="m-a col-12">
							<Form.Group>
								<Form.Label>Summary</Form.Label>
								<Editor
									apiKey={process.env.REACT_APP_TINY_API_KEY}
									value={values.summary}
									init={{
										plugins:
											"paste save code visualblocks visualchars image link media codesample table charmap hr lists advlist anchor autolink preview imagetools noneditable help charmap quickbars",
										menubar: false,
										toolbar:
											"undo redo | styleselect | fontselect fontsizeselect formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
										toolbar_mode: "sliding",
									}}
									onEditorChange={(e) =>
										setFieldValue("summary", e)
									}
								/>
								{/* <div className={`select-input ${errors.summary && "is-invalid"}`}> </div>
							<Form.Control.Feedback type='invalid'>{errors.summary}</Form.Control.Feedback> */}
							</Form.Group>
						</Col>
					</Row>

					<FormikInput
						type="email"
						name="email"
						label="Email"
						error={errors.email}
					/>
					{!history.location.pathname.includes("clients") &&
						!history.location.pathname.includes("employees") && (
							<>
								<Row>
									<Col className="calendar">
										<Form.Group
											controlId="input_birthday"
											className={`select-input ${
												errors.birthday &&
												touched.birthday &&
												"is-invalid"
											} floating-label`}
										>
											<SingleDatePicker
												placeholder=""
												displayFormat="DD/MM/YYYY"
												numberOfMonths={1}
												date={
													birthday ||
													(values &&
														values.birthday &&
														moment(values.birthday))
												}
												id="birthday"
												showDefaultInputIcon
												onDateChange={(date) =>
													onBirthdayChange(date)
												}
												focused={focused}
												// navPrev={<div></div>}
												navNext={
													monthSelected === 0 &&
													yearSelected ===
														(2024 ||
															2025 ||
															2026) ? (
														<div></div>
													) : (
														""
													)
												}
												// onNextMonthClick={}
												// maxDate={new Date()}
												isOutsideRange={() => false}
												onFocusChange={({ focused }) =>
													setFocus(focused)
												}
												renderMonthElement={({
													month,
													onMonthSelect,
													onYearSelect,
												}) => {
													setMonthSelected(
														month.month()
													);
													setYearSelected(
														month.year()
													);
													return monthAndYearDropdown(
														month,
														onMonthSelect,
														onYearSelect
													);
												}}
												required
												readOnly={true}
											/>

											<Form.Label
												className={`${
													values.birthday &&
													"label-focued"
												} select-input is-invalid`}
											>
												Birthday
											</Form.Label>

											<Form.Control.Feedback type="invalid">
												{errors.birthday}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
								<FormikInput
									type="text"
									name="birth_place"
									label="Birth Place"
								/>
								<FormikInput
									type="text"
									name="address"
									label="Address"
								/>
							</>
						)}
					<Row>
						<Col className="calendar">
							<Form.Group
								controlId="input_country"
								className={`select-input ${
									!!errors.country &&
									touched.country &&
									"is-invalid"
								} floating-label`}
							>
								<Select
									// menuPlacement="auto"
									options={countriesCode}
									onChange={(e) => handleCountriesChange(e)}
									value={countriesCode.find(
										(item) => item.label === values.country
									)}
									placeholder="Country"
									className="select-input mb-4"
									isRequired
								/>
								{values.country && (
									<Form.Label
										className={
											values.country &&
											"label-focued select-input is-invalid"
										}
									>
										Country
									</Form.Label>
								)}
								<div
									className={`select-input ${
										errors.country && "is-invalid"
									}`}
								>
									{" "}
								</div>
								<Form.Control.Feedback type="invalid">
									{errors.country}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};

EmployeeGeneral.propTypes = {
	values: PropTypes.shape({
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		phone: PropTypes.string,
		job_title: PropTypes.string,
		tariff: PropTypes.string,
		// summary: PropTypes.string,
		email: PropTypes.string,
		birthday: PropTypes.string,
		// birth_place: PropTypes.string,
		country: PropTypes.string,
		// address: PropTypes.string,
		employee_level_id: PropTypes.shape({}),
		employee_level: PropTypes.shape({}),
		educations: PropTypes.array,
		experiences: PropTypes.array,
		abilities: PropTypes.array,
	}).isRequired,
	errors: PropTypes.shape({
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		phone: PropTypes.string,
		job_title: PropTypes.string,
		tariff: PropTypes.string,
		// summary: PropTypes.string,
		email: PropTypes.string,
		birthday: PropTypes.string,
		// birth_place: PropTypes.string,
		country: PropTypes.string,
		// address: PropTypes.string,
		employee_level_id: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		first_name: PropTypes.bool,
		last_name: PropTypes.bool,
		phone: PropTypes.bool,
		job_title: PropTypes.bool,
		tariff: PropTypes.bool,
		// summary: PropTypes.bool,
		email: PropTypes.bool,
		birthday: PropTypes.bool,
		// birth_place: PropTypes.bool,
		country: PropTypes.bool,
		employee_level_id: PropTypes.bool,
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	searchEmployeeLevels: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	employees: state.employees,
});

export default connect(mapStateToProps, {
	updateEmployeeAction,
	createEmployeeAction,
	searchEmployeeLevels,
})(EmployeeGeneral);
