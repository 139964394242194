import { Field } from "formik"
import { Form } from "react-bootstrap"
import React from "react"

const FormikInput = (props) => {
	return (
		<Field {...props}>
			{({ field, form: { touched, errors }, meta }) => {
				// console.log("FIELD ERRORS", props)
				return <Form.Group controlId={`input_${field.name}`} className="floating-label">
					<Form.Control {...field} required={props.required} />
					<Form.Label className={props.error && "select-input is-invalid"}>{props.label}</Form.Label>
					<Form.Control.Feedback type="invalid">{props.error}</Form.Control.Feedback>
				</Form.Group>
			}

			}
		</Field>
	)
}

export default FormikInput
