import { Form as FormLink, withFormik } from "formik"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Button, Form } from "react-bootstrap"
import { connect } from "react-redux"
import * as Yup from "yup"
import {FormattedMessage} from "react-intl"
import { createAreaAction, getAreasAction } from "../../actions/areasActions"
import { createSkillAction } from "../../actions/skillsActions"
import MaterialIcon from "../elements/MaterialIcon"
import Axios from "axios"
import cogoToast from "cogo-toast"
 

const NewAreaSkill = ({ areas, isSubmitting, errors, touched, handleChange, values, setFieldValue, setFieldTouched }) => {
    // console.log("values", values);
	console.log("areas", areas);
	// idArea = areas.data[areas.data.length - 1].id
	// console.log("idArea", idArea);
	return (
		<FormLink className='client-form form__loading form__short private-form'>
			{isSubmitting && <div className="loading-overlay">
				<h5>Loading...</h5>
			</div>}
			<div className="inputs-wrapper">
				<Form.Group controlId="input_name" className="floating-label">
					<Form.Control type="text" value={values.name} onChange={handleChange} name="name" isInvalid={!!errors.name && touched.name} />
					<Form.Label>Name Area</Form.Label>
					<Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
				</Form.Group>
                <Form.Group controlId="input_name" className="floating-label">
                    <Form.Control type="text" value={values.nameSkill} onChange={handleChange} name="nameSkill" isInvalid={!!errors.nameSkill && touched.nameSkill} />
                    <Form.Label>Name Skill</Form.Label>
                    <Form.Control.Feedback type="invalid">{errors.nameSkill}</Form.Control.Feedback>
                </Form.Group>
			</div>
			<div className="submit-wrapper">
				<Button type="submit" variant="success save" disabled={isSubmitting}><MaterialIcon icon="save" /> <FormattedMessage id="buttons.save_area" /> </Button>
			</div>
		</FormLink>
	)
}

const Schema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    nameSkill: Yup.string().required("This field is required")
})

const FormikApp = withFormik({
	mapPropsToValues({ name, area, skill, nameSkill }) {
		if (area && skill) {
			return {
				name: area.name || "",
                nameSkill: skill.name || ""
			}
		}
		return {
			name: name || "",
            nameSkill: nameSkill || ""
		}
	},
	validationSchema: Schema,
	enableReinitialize: true,
	handleSubmit (values, { props, setSubmitting, setErrors }) {
		var idArea;
        console.log("values", values);
		
        const getId = async () => {            
		   	await props.createAreaAction(values, setSubmitting, setErrors)
            await Axios.get(`${process.env.REACT_APP_API_URL}/areas?term`).then(res => {
                // totalPages = res.data.meta.pagination.total_pages
				idArea = res.data[res.data.length - 1].id
            
			 console.log("res", res);
             console.log("idArea",idArea );
            })
			 const data = {}
			 data.area_id = idArea
			 data.name = values.nameSkill

			 Axios.get(`${process.env.REACT_APP_API_URL}/areas`).then(async res => {
				const skillsNames = [];
				res.data.data.map(areasSkills => areasSkills.skills.map(skillsuniq => { skillsNames.push(skillsuniq.name)}))
				if(!skillsNames.includes(data.name)) {
					await props.createSkillAction(data, setSubmitting, setErrors)
	
				} else {
					cogoToast.error('This Skill is already exist', { position: "top-right" })
					setSubmitting(false)
				}
			})
			 
        }
	
		getId()
		
		console.log("idArea", idArea);
        console.log("idArea", idArea);
  

	},
})(NewAreaSkill)

NewAreaSkill.propTypes = {
	values: PropTypes.shape({
		name: PropTypes.string,
        nameSkill: PropTypes.string
	}).isRequired,
	errors: PropTypes.shape({
		name: PropTypes.string,
        nameSkill: PropTypes.string
	}).isRequired,
	touched: PropTypes.shape({
        name: PropTypes.bool,
        nameSkill: PropTypes.bool
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	areas: state.areas,
    skills: state.skills
})
export default connect(mapStateToProps, { createAreaAction, createSkillAction })(FormikApp)
