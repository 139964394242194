import React from "react"
import { Navbar } from "react-bootstrap"
import PropTypes from "prop-types"
import { connect } from "react-redux"

const DashboardHeader = ({ asideOpen, onToggleSidebar, auth }) => {
	const classOne = asideOpen ? "asideOpen" : "asideClose"
	return (
		<Navbar className='dashboard-header '>
			<div className='navbar-left mr-auto'>
				<button id="side-btn" className={`btn ${classOne}`} type='button' data-toggle="collapse" data-target="#sidebar" aria-expanded="false" onClick={onToggleSidebar} aria-controls="sidebar" >
					<img title="Menu wrapper" alt="menu-wrapper-logo" src="/images/menu-wrapper.png" />
				</button>
			</div>
		</Navbar>
	)
}

DashboardHeader.propTypes = {
	asideOpen: PropTypes.bool.isRequired,
	onToggleSidebar: PropTypes.func.isRequired,
	auth: PropTypes.shape({
		user: PropTypes.any
	}).isRequired
}

const mapStateToProps = (state) => ({
	auth: state.auth
})

export default connect(mapStateToProps, {})(DashboardHeader)
