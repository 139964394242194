import React from "react"
import PropTypes from "prop-types"
import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { FormattedMessage } from "react-intl"
import MaterialIcon from "./MaterialIcon"
import getStaticUrl from "../../helpers/api"
import cogoToast from "cogo-toast"

const ArchiveActionCell = ({ cellProps, setShowDeleteModal , setArchiveModal, fromEmployee, newResume=true , Route }) => {

	return (
		<ButtonToolbar aria-label="Actions" className="table-actions-wrapper user-table-actions">
				<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-delete-${cellProps.index}`}><FormattedMessage id="buttons.unarchive_resume" /></Tooltip>}>
					<Button variant="success" onClick={() => setArchiveModal(cellProps.index)}  ><MaterialIcon icon="unarchive" /></Button>
				</OverlayTrigger>
				<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-delete-${cellProps.index}`}><FormattedMessage id="buttons.delete_resume" /></Tooltip>}>
					<Button variant="danger" onClick={() => setShowDeleteModal(cellProps.index)}  ><MaterialIcon icon="delete" /></Button>
				</OverlayTrigger>
		</ButtonToolbar>
	)
}

ArchiveActionCell.propTypes = {
	cellProps: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
		index: PropTypes.number,
	}).isRequired,
	setShowDeleteModal: PropTypes.func.isRequired,
}

export default ArchiveActionCell
