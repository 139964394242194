import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { IntlProvider } from "react-intl"
import flattenMessages from "./helpers/flattenMessages"
import locales from "./locales"

const IntlWrapper = ({ children, lang}) => (
	<IntlProvider locale={lang} messages={flattenMessages(locales[lang])}>
		{children}
	</IntlProvider>
)

IntlWrapper.propTypes = {
	lang: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}
const mapStateToProps = (state) => ({
	auth: state.auth,
	lang: (state.auth && state.auth.user && state.auth.user.language) ? state.auth.user.language : "en"
})
export default connect(mapStateToProps, {})(IntlWrapper)
