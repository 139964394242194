import { methods } from "../enum/EmployeeData"
import { unset } from "lodash"

class EmployeeDataResolver {
	// Find the method which we need to submit through the prop component
	resolveSubmit = (component, data, id, props, formikActions, resume, datesError) => {
		const method = methods[component]
		this[method](data, id, props, formikActions, resume, datesError)
	}

	// We are submitting from employee edit page
	updateEmployee = (data, id, props, formikActions) => {
		unset(data, "resumes");
		let formData = new FormData();
		formData.append('data' ,JSON.stringify(data) );
		formData.append('profile_image' ,data.profile_image );

		props.updateEmployeeAction(id, formData, formikActions.setSubmitting, formikActions.setErrors)
	}

	// We are submitting from employee create page
	createEmployee = (data, id, props, formikActions) => {
		unset(data, "resumes");
		let formData = new FormData();
		formData.append('data' ,JSON.stringify(data) );
		formData.append('profile_image' ,data.profile_image );
		
		props.createEmployeeAction(formData, formikActions.setSubmitting, formikActions.setErrors)
	}

	// We are submitting from resume update page
	updateResume = (data, id, props, formikActions, resume, datesError) => {
		unset(data, "resumes")
		// console.log("DATA", data)
	let formData = new FormData();
		formData.append("client_id", data.client.id);
		formData.append('employee_id',data.employee.id);
		formData.append('data' ,JSON.stringify(data) );
		formData.append('profile_image' ,data.profile_image )

		// const toStore = {
		// 	client_id: data.client.id,
		// 	employee_id: data.employee.id,
		// 	data: JSON.stringify(data)
		// }

		props.updateResumeAction(datesError, resume.id, formData, formikActions.setSubmitting, formikActions.setErrors , props)
	}

	// We are submitting from resume create page
	createResume = (data, id, props, formikActions, datesError) => {
		unset(data, "resumes")
		console.log({ResuData:data})
		let formData = new FormData();
		formData.append("client_id", data.client.id);
		formData.append('employee_id',data.employee.id);
		formData.append('data' ,JSON.stringify(data) );
		formData.append('profile_image' ,data.profile_image )

		// const toStore = {
		// 	client_id: data.client.id,
		// 	employee_id: data.employee.id,
		// 	data: JSON.stringify(data)
		// }

		props.createResumeAction(formData, data.client.id, "clients", datesError)
	}

	updateResumeFromEmployee = (data, id, props, formikActions) => {

	}

	// We are creating a resume directly from the employee page
	createResumeFromEmployee = (data, id, props, formikActions) => {
		unset(data, "resumes")
		// console.log(data);
		let formData = new FormData();
		formData.append("client_id", data.client.id);
		formData.append('employee_id',id);
		formData.append('data' ,JSON.stringify(data) );
		formData.append('profile_image' ,data.profile_image )
		// const toStore = {
		// 	client_id: data.client.id,
		// 	employee_id: id,
		// 	data: JSON.stringify(data)
		// }

		props.createResumeAction(formData, id, "employees")
	}

	updateResumeFromClient = (data, id, props, formikActions) => {

	}

	// We are creating a resume directly from the client page
	createResumeFromClient = (data, id, props, formikActions) => {
		unset(data, "resumes");
		let formData = new FormData();
		formData.append("client_id", id);
		formData.append('employee_id',data.id);
		formData.append('data' ,JSON.stringify(data) );
		formData.append('profile_image' ,data.profile_image )
		console.log({employee:id })
		// const toStore = {
		// 	client_id: id,
		// 	employee_id: data.id,
		// 	data: JSON.stringify(data)
		// }

		props.createResumeAction(formData, id, "clients")
	}
}

export default new EmployeeDataResolver()
