import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table"
import { connect } from "react-redux"
import { getAreasAction, deleteAreaAction, FilterAreas } from "../../actions/areasActions"
import AreasTableActionsCell from "../elements/AreasTableActionsCell"
import DeleteModal from "../modals/DeleteModal"
import {useHistory} from 'react-router-dom'
import { FilterField } from "../../helpers/GeneralFillter"
import { Col, Row } from "react-bootstrap"
import SkillsAreaDropdown from "../forms/SkillsAreaDropdown"
const AreasTable = ({ areas, getAreasAction, deleteAreaAction  , FilterAreas}) => {
	const history = useHistory()
	let index = -1
	let pageNr = areas.data.length ? areas.meta.pagination.current_page - 1 : 0
	const totalPages = areas.data.length ? areas.meta.pagination.total_pages : 1
	const [showDelete, setShowDeleteModal] = useState(-1);
	const [counterPages, setCounterPages] = useState(100)
	
	const columns = [
		{ Header: "ID", width: 300, filterable: false, accessor: "id", Cell: cellProps => cellProps.value || "" },
		{ Header: "Name", filterable: true, Filter:FilterField, accessor: "name", Cell: cellProps => {
		return(
			<Row className="unique-appeare">
				<Col className="col-lg-6 col-8">
					{cellProps.value || ""}
				</Col>
				<Col className="col-lg-6 col-4">
					<SkillsAreaDropdown Skills={cellProps.original.skills} />
				</Col>
			</Row>
		) 
		}
		},
		{
			Header: "Actions",
			filterable: false,
			width:120,
			Cell: cellProps => <AreasTableActionsCell
				cellProps={cellProps}
				setShowDeleteModal={setShowDeleteModal}
			/>,
		}
	]
	useEffect(()=> {
		pageNr = totalPages - 1
		const { filters } = areas
		getAreasAction({ pageSize: counterPages, sorted: null, filters, page: 1 })		
		if(!areas.data.length) {
			const { filters } = areas
			getAreasAction({ pageSize: counterPages, sorted: null, filters, page: totalPages })
		}
	}, [ counterPages])

	const handleCloseDelete = () => {
		setShowDeleteModal(-1)
	}

	const handlePaginateFetch = (page) => {
		const { filters } = areas;
		getAreasAction({ pageSize: counterPages, sorted: null, filters, page: page + 1 })
	}

	const handleDelete = async() => {
		const { id } = areas.data[index];
		await deleteAreaAction(id, handleCloseDelete)
		const { filters } = areas;
		await getAreasAction({ pageSize: counterPages, sorted: null, filters, page: pageNr + 1})
	}

	if (showDelete >= 0) {
		index = showDelete
	}
	const onRowClick = (state, rowInfo , column , instance) => {
		if (rowInfo && rowInfo.row) {
			setCounterPages(rowInfo.pageSize)
			return {
			onClick: (e) => {
				if(column.Header !== 'Actions'){
					localStorage.setItem('AreaName' , rowInfo.original.name)
					history.push(`/areas/${rowInfo.original.id}/skills`)
				}
			},
			style: {
					'cursor': rowInfo ? 'pointer' : 'inherit',
				}
			}
		}else{
			return {}
		}
	}
	// filter data by serverside
	const FilterData = async (column, value) => {
		if(column.length){
			FilterAreas(column[0].value);
		}else{
			FilterAreas("")
		}
	}
	return (
		<div className="table-style">
			<ReactTable
				data={areas.data}
				loading={areas.isLoading}
				columns={columns}
				defaultPageSize={100}
				pages={totalPages}
				page={pageNr}
				filterable
				manual // for handle pagination
				// defaultFilterMethod={(filter,row) => {
				// 	if(row[filter.id].toLowerCase().includes(filter.value.toLowerCase())){
				// 		return row
				// 	}
				// }}			
				onFilteredChange={FilterData} 
				showPageSizeOptions={true}
				pageSizeOptions= {[5, 10, 15, 20, 25, 50, 100]}
				sortable={false}
				className="striped-highlight cl-ta remove-id unique-filter-table"
				onPageChange={handlePaginateFetch}
				getTdProps= {onRowClick}
			/>

			<DeleteModal
				isShown={showDelete !== -1}
				handleClose={handleCloseDelete}
				title={areas.data[index] ? `Area ${areas.data[index].name}` : ""}
				handleDelete={handleDelete}
				isDeleting={areas.isDeleting}
			/>
		</div>
	)
}

AreasTable.propTypes = {
	getAreasAction: PropTypes.func.isRequired,
	deleteAreaAction: PropTypes.func.isRequired,
	areas: PropTypes.shape({
		data: PropTypes.array,
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number,
				total_pages: PropTypes.number
			})
		}),
		filters: PropTypes.array,
		isLoading: PropTypes.bool,
		hasError: PropTypes.bool,
		isRestoring: PropTypes.bool,
		isDeleting: PropTypes.bool
	}).isRequired
}

const mapStateToProps = (state) => ({
	areas: state.areas
})

export default connect(mapStateToProps, { getAreasAction, deleteAreaAction , FilterAreas})(AreasTable)

