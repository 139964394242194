import { Form as FormLink, withFormik } from "formik"
import PropTypes from "prop-types"
import React, {useEffect, useState} from "react"
import { Form, Col, Row, Button } from "react-bootstrap"
import { connect } from "react-redux"
import * as Yup from "yup"
import {FormattedMessage} from "react-intl"

import debounce from "lodash/debounce"
import AsyncSelect from "react-select/async"

import { updateSkillAction, createSkillAction } from "../../actions/skillsActions"
import { searchAreas } from "../../actions/areasActions"
import MaterialIcon from "../elements/MaterialIcon"
import history from "../../routes/history"
import Axios from "axios"
import cogoToast from "cogo-toast"

const SkillForm = ({ isSubmitting,AreaID, errors, touched, handleChange, values, setFieldValue, setFieldTouched, searchAreas }) => {
	const [areasOptions, setAreasOptions] = useState([])

	useEffect(() => {
		console.log({AreaID})
		const getDefaultAreaOptions = async () => {
			const areasOptions = await searchAreas("")
			setAreasOptions(areasOptions)
			console.log("areasOptions", areasOptions);
			// if(!history.location.pathname.includes('/areas/skill/create')) {
				areasOptions.forEach(e => {
					if (e.id === +AreaID) {
						console.log("dfdf");
						setFieldValue("area", {id:e.id , name: e.name})
					}
				})
				// setFieldValue("area", {id:AreaID , name:localStorage.getItem('AreaName')})
			// }
		}
		getDefaultAreaOptions();
		// setFieldValue("area", AreaID)
		// if(!history.location.pathname.includes('/areas/skill/create')) {
		// 	// areasOptions.map(e => {
		// 	// 	if (e.id === AreaID) {
		// 	// 		setFieldValue("area", {id:e.id , name: e.name})
		// 	// 	}
		// 	// })
		// 	setFieldValue("area", {id:AreaID , name:localStorage.getItem('AreaName')})
		// }
	
	}, [])

	const handleChangeArea = (value) => {
		console.log(value)
		setFieldValue("area", value)

		if (!value) {
			setFieldTouched("area", true)
		}
	}

	return (
		<FormLink className='client-form form__loading form__short private-form'>
			{isSubmitting && <div className="loading-overlay">
				<h5>Loading...</h5>
			</div>}
			<div className="inputs-wrapper">
				<Row>
					<Col>
						<Form.Group controlId="input_area_id " className="floating-label">
							<AsyncSelect
								id="area_id"
								cacheOptions
								loadOptions={debounce(searchAreas, 100)}
								defaultOptions={areasOptions}
								isDisabled={!history.location.pathname.includes('/areas/skill/create') ? true : false}
								isClearable
								getOptionValue={(item) => (item.id)}
								getOptionLabel={(item) => (item.name)}
								onChange={(value) => handleChangeArea(value)}
								onBlur={() => setFieldTouched("area", true)}
								value={!history.location.pathname.includes('/areas/skill/create') ? values.area : values.area}
								placeholder="Area"
								noOptionsMessage={() => ("Type to search")}
								className={`select-input ${(!!errors.area && touched.area) && "is-invalid"}`}
							/>
							<Form.Control.Feedback type='invalid'>{errors.area}</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col>
						<Form.Group controlId="input_name" className="floating-label">
							<Form.Control type="text" value={values.name} onChange={handleChange} name="name" isInvalid={!!errors.name && touched.name} />
							<Form.Label>Name</Form.Label>
							<Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
			</div>
			<div className="submit-wrapper">
				<Button type="submit" variant="success save" disabled={isSubmitting}><MaterialIcon icon="save" /> <FormattedMessage id="buttons.save_skill" /> </Button>
			</div>
		</FormLink>
	)
}

const Schema = Yup.object().shape({
	area: Yup.mixed().required("This field is required"),
	name: Yup.mixed().required("This field is required"),
})

const FormikApp = withFormik({
	mapPropsToValues({ name, skill, area }) {
		if (skill) {
			return {
				name: skill.name || "",
				area: skill.area || ""
			}
		}
		return {
			name: name || "",
			area: area || "",
		}
	},
	validationSchema: Schema,
	enableReinitialize: true,
	handleSubmit(values, { props, setSubmitting, setErrors }) {
		const data = {}
		data.area_id = values.area.id
		data.name = values.name
		Axios.get(`${process.env.REACT_APP_API_URL}/areas`).then(res => {
			const skillsNames = [];
			res.data.data.map(areasSkills => areasSkills.skills.map(skillsuniq => { skillsNames.push(skillsuniq.name)}))
			if(!skillsNames.includes(data.name)) {
				if (props.skill) {
					props.updateSkillAction(props.id, data, setSubmitting, setErrors)
				} else {
					props.createSkillAction(data, setSubmitting, setErrors)
				}

			} else {
				cogoToast.error('This Skill is already exist', { position: "top-right" })
				setSubmitting(false)
			}
		})
	},
})(SkillForm)

SkillForm.propTypes = {
	AreaID:PropTypes.string,
	values: PropTypes.shape({
		area: PropTypes.shape({}),
		name: PropTypes.string,
	}).isRequired,
	errors: PropTypes.shape({
		area: PropTypes.string,
		name: PropTypes.string
	}).isRequired,
	touched: PropTypes.shape({
		area: PropTypes.bool,
		name: PropTypes.bool
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	skills: PropTypes.shape({}).isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	searchAreas: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	skills: state.skills
})
export default connect(mapStateToProps, { updateSkillAction, createSkillAction, searchAreas })(FormikApp)
