import Axios from "axios"
import API from "./APIService"
import BaseService from "./BaseService"

class AreaSkillsService extends BaseService {
	component = null

	constructor(component = null) {
		super()
		this.component = component
	}

	baseServicePath = () => {
		return API.url("areas")
	}

	getSkills(areaId, params={}) {
		const url  = `${this.baseServicePath()}/${areaId}/skills`
		return Axios.get(url,{params})
	}

	// createResumes(clientId, params={}) {
	// 	const url  = `${this.baseServicePath()}/${clientId}/resumes/create`
	// 	return Axios.get(url,{params})
	// }
}

export default AreaSkillsService
