// USERS
export const users = {
	USER_LOGGED_IN: "USER_LOGGED_IN",
	USER_LOGGED_IN_ERROR: "USER_LOGGED_IN_ERROR",
	USER_LOGGED_OUT: "USER_LOGGED_OUT",

	GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
	GET_USERS_STARTED: "GET_USERS_STARTED",
	GET_USERS_ERROR: "GET_USERS_ERROR",

	SHOW_USER_STARTED: "SHOW_USER_STARTED",
	SHOW_USER_SUCCESS: "SHOW_USER_SUCCESS",
	SHOW_USER_ERROR: "SHOW_USER_ERROR",

	FORGET_PASSWORD_STARTED: "FORGET_PASSWORD_STARTED",
	FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
	FORGET_PASSWORD_ERROR: "FORGET_PASSWORD_ERROR",

	CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
	CREATE_USER_STARTED: "CREATE_USER_STARTED",
	CREATE_USER_ERROR: "CREATE_USER_ERROR",

	UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
	UPDATE_USER_STARTED: "UPDATE_USER_STARTED",
	UPDATE_USER_ERROR: "UPDATE_USER_ERROR",

	DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
	DELETE_USER_STARTED: "DELETE_USER_STARTED",
	DELETE_USER_ERROR: "DELETE_USER_ERROR",

	FORCE_DELETE_USER_SUCCESS: "FORCE_DELETE_USER_SUCCESS",
	FORCE_DELETE_USER_STARTED: "FORCE_DELETE_USER_STARTED",
	FORCE_DELETE_USER_ERROR: "FORCE_DELETE_USER_ERROR",

	BLOCK_USER_SUCCESS: "BLOCK_USER_SUCCESS",
	BLOCK_USER_STARTED: "BLOCK_USER_STARTED",
	BLOCK_USER_ERROR: "BLOCK_USER_ERROR",

	UNBLOCK_USER_SUCCESS: "UNBLOCK_USER_SUCCESS",
	UNBLOCK_USER_STARTED: "UNBLOCK_USER_STARTED",
	UNBLOCK_USER_ERROR: "UNBLOCK_USER_ERROR",

	RESTORE_USER_SUCCESS: "RESTORE_USER_SUCCESS",
	RESTORE_USER_STARTED: "RESTORE_USER_STARTED",
	RESTORE_USER_ERROR: "RESTORE_USER_ERROR",

	SET_USER_FILTERS: "SET_USER_FILTERS",
	SET_USER_PER_PAGE: "SET_USER_PER_PAGE",

	EXPORT_USERS_STARTED: "EXPORT_USERS_STARTED",
	EXPORT_USERS_SUCCESS: "EXPORT_USERS_SUCCESS",
	EXPORT_USERS_ERROR: "EXPORT_USERS_ERROR",

	UPLOAD_PROFILE_IMAGE_STARTED: "UPLOAD_PROFILE_IMAGE_STARTED",
	UPLOAD_PROFILE_IMAGE_SUCCESS: "UPLOAD_PROFILE_IMAGE_SUCCESS",
	UPLOAD_PROFILE_IMAGE_ERROR: "UPLOAD_PROFILE_IMAGE_ERROR",

	UPDATE_USER_PROFILE_STARTED: "UPDATE_USER_PROFILE_STARTED",
	UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
	UPDATE_USER_PROFILE_ERROR: "UPDATE_USER_PROFILE_ERROR",

	UPDATE_USER_PERMISSIONS_STARTED: "UPDATE_USER_PERMISSIONS_STARTED",
	UPDATE_USER_PERMISSIONS_SUCCESS: "UPDATE_USER_PERMISSIONS_SUCCESS",
	UPDATE_USER_PERMISSIONS_ERROR: "UPDATE_USER_PERMISSIONS_ERROR",

	SEARCH_USER: "SEARCH_USER",
}

// CLIENTS
export const clients = {
	GET_CLIENTS_STARTED: "GET_CLIENTS_STARTED",
	GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
	GET_CLIENTS_ERROR: "GET_CLIENTS_ERROR",

	CREATE_CLIENT_STARTED: "CREATE_CLIENT_STARTED",
	CREATE_CLIENT_SUCCESS: "CREATE_CLIENT_SUCCESS",
	CREATE_CLIENT_ERROR: "CREATE_CLIENT_ERROR",

	SHOW_CLIENT_STARTED: "SHOW_CLIENT_STARTED",
	SHOW_CLIENT_SUCCESS: "SHOW_CLIENT_SUCCESS",
	SHOW_CLIENT_ERROR: "SHOW_CLIENT_ERROR",

	UPDATE_CLIENT_STARTED: "UPDATE_CLIENT_STARTED",
	UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
	UPDATE_CLIENT_ERROR: "UPDATE_CLIENT_ERROR",

	DELETE_CLIENT_STARTED: "DELETE_CLIENT_STARTED",
	DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS",
	DELETE_CLIENT_ERROR: "DELETE_CLIENT_ERROR",

	FORCE_DELETE_CLIENT_STARTED: "FORCE_DELETE_CLIENT_STARTED",
	FORCE_DELETE_CLIENT_SUCCESS: "FORCE_DELETE_CLIENT_SUCCESS",
	FORCE_DELETE_CLIENT_ERROR: "FORCE_DELETE_CLIENT_ERROR",

	RESTORE_CLIENT_STARTED: "RESTORE_CLIENT_STARTED",
	RESTORE_CLIENT_SUCCESS: "RESTORE_CLIENT_SUCCESS",
	RESTORE_CLIENT_ERROR: "RESTORE_CLIENT_ERROR",

	EXPORT_CLIENTS_STARTED: "EXPORT_CLIENTS_STARTED",
	EXPORT_CLIENTS_SUCCESS: "EXPORT_CLIENTS_SUCCESS",
	EXPORT_CLIENTS_ERROR: "EXPORT_CLIENTS_ERROR",

	SET_CLIENT_FILTERS: "SET_CLIENT_FILTERS",
	SET_CLIENT_PER_PAGE: "SET_CLIENT_PER_PAGE",

	SITES_CLIENTS_STARTED: "SITES_CLIENTS_STARTED",
	SITES_CLIENTS_SUCCESS: "SITES_CLIENTS_SUCCESS",
	SITES_CLIENTS_ERROR: "SITES_CLIENTS_ERROR",
}

export const clientResumes = {
	GET_CLIENT_RESUMES_STARTED: "GET_CLIENT_RESUMES_STARTED",
	GET_CLIENT_RESUMES_SUCCESS: "GET_CLIENT_RESUMES_SUCCESS",
	GET_CLIENT_RESUMES_ERROR: "GET_CLIENT_RESUMES_ERROR",
	CREATE_CLIENT_RESUMES_STARTED: "CREATE_CLIENT_RESUMES_STARTED",
	CREATE_CLIENT_RESUMES_SUCCESS: "CREATE_CLIENT_RESUMES_SUCCESS",
	CREATE_CLIENT_RESUMES_ERROR: "CREATE_CLIENT_RESUMES_ERROR",
	SHOW_CLIENT_RESUMES_STARTED: "SHOW_CLIENT_RESUMES_STARTED",
	SHOW_CLIENT_RESUMES_SUCCESS: "SHOW_CLIENT_RESUMES_SUCCESS",
	SHOW_CLIENT_RESUMES_ERROR: "SHOW_CLIENT_RESUMES_ERROR",
	UPDATE_CLIENT_RESUMES_STARTED: "UPDATE_CLIENT_RESUMES_STARTED",
	UPDATE_CLIENT_RESUMES_SUCCESS: "UPDATE_CLIENT_RESUMES_SUCCESS",
	UPDATE_CLIENT_RESUMES_ERROR: "UPDATE_CLIENT_RESUMES_ERROR",
	DELETE_CLIENT_RESUMES_STARTED: "DELETE_CLIENT_RESUMES_STARTED",
	DELETE_CLIENT_RESUMES_SUCCESS: "DELETE_CLIENT_RESUMES_SUCCESS",
	DELETE_CLIENT_RESUMES_ERROR: "DELETE_CLIENT_RESUMES_ERROR",
}

export const employees = {
	GET_EMPLOYEES_STARTED: "GET_EMPLOYEES_STARTED",
	GET_EMPLOYEES_SUCCESS: "GET_EMPLOYEES_SUCCESS",
	GET_EMPLOYEES_ERROR: "GET_EMPLOYEES_ERROR",
	SHOW_EMPLOYEES_STARTED: "SHOW_EMPLOYEES_STARTED",
	SHOW_EMPLOYEES_SUCCESS: "SHOW_EMPLOYEES_SUCCESS",
	SHOW_EMPLOYEES_ERROR: "SHOW_EMPLOYEES_ERROR",
	CREATE_EMPLOYEES_STARTED: "CREATE_EMPLOYEES_STARTED",
	CREATE_EMPLOYEES_SUCCESS: "CREATE_EMPLOYEES_SUCCESS",
	CREATE_EMPLOYEES_ERROR: "CREATE_EMPLOYEES_ERROR",
	UPDATE_EMPLOYEES_STARTED: "UPDATE_EMPLOYEES_STARTED",
	UPDATE_EMPLOYEES_SUCCESS: "UPDATE_EMPLOYEES_SUCCESS",
	UPDATE_EMPLOYEES_ERROR: "UPDATE_EMPLOYEES_ERROR",
	DELETE_EMPLOYEES_STARTED: "DELETE_EMPLOYEES_STARTED",
	DELETE_EMPLOYEES_SUCCESS: "DELETE_EMPLOYEES_SUCCESS",
	DELETE_EMPLOYEES_ERROR: "DELETE_EMPLOYEES_ERROR",
	ARCHIVE_EMPLOYEES_STARTED: "DELETE_EMPLOYEES_STARTED",
	ARCHIVE_EMPLOYEES_SUCCESS: "DELETE_EMPLOYEES_SUCCESS",
	ARCHIVE_EMPLOYEES_ERROR: "DELETE_EMPLOYEES_ERROR",
	UNARCHIVE_EMPLOYEES_STARTED: "DELETE_EMPLOYEES_STARTED",
	UNARCHIVE_EMPLOYEES_SUCCESS: "DELETE_EMPLOYEES_SUCCESS",
	UNARCHIVE_EMPLOYEES_ERROR: "DELETE_EMPLOYEES_ERROR"
}

export const employeeResumes = {
	GET_EMPLOYEE_RESUMES_STARTED: "GET_EMPLOYEE_RESUMES_STARTED",
	GET_EMPLOYEE_RESUMES_SUCCESS: "GET_EMPLOYEE_RESUMES_SUCCESS",
	GET_EMPLOYEE_RESUMES_ERROR: "GET_EMPLOYEE_RESUMES_ERROR",
	SHOW_EMPLOYEE_RESUMES_STARTED: "SHOW_EMPLOYEE_RESUMES_STARTED",
	SHOW_EMPLOYEE_RESUMES_SUCCESS: "SHOW_EMPLOYEE_RESUMES_SUCCESS",
	SHOW_EMPLOYEE_RESUMES_ERROR: "SHOW_EMPLOYEE_RESUMES_ERROR",
	UPDATE_EMPLOYEE_RESUMES_STARTED: "UPDATE_EMPLOYEE_RESUMES_STARTED",
	UPDATE_EMPLOYEE_RESUMES_SUCCESS: "UPDATE_EMPLOYEE_RESUMES_SUCCESS",
	UPDATE_EMPLOYEE_RESUMES_ERROR: "UPDATE_EMPLOYEE_RESUMES_ERROR",
	DELETE_EMPLOYEE_RESUMES_STARTED: "DELETE_EMPLOYEE_RESUMES_STARTED",
	DELETE_EMPLOYEE_RESUMES_SUCCESS: "DELETE_EMPLOYEE_RESUMES_SUCCESS",
	DELETE_EMPLOYEE_RESUMES_ERROR: "DELETE_EMPLOYEE_RESUMES_ERROR",
}

export const resumes = {
	GET_RESUMES_STARTED: "GET_RESUMES_STARTED",
	GET_RESUMES_SUCCESS: "GET_RESUMES_SUCCESS",
	GET_RESUMES_ERROR: "GET_RESUMES_ERROR",
	SHOW_RESUMES_STARTED: "SHOW_RESUMES_STARTED",
	SHOW_RESUMES_SUCCESS: "SHOW_RESUMES_SUCCESS",
	SHOW_RESUMES_ERROR: "SHOW_RESUMES_ERROR",
	CREATE_RESUMES_STARTED: "CREATE_RESUMES_STARTED",
	CREATE_RESUMES_SUCCESS: "CREATE_RESUMES_SUCCESS",
	CREATE_RESUMES_ERROR: "CREATE_RESUMES_ERROR",
	UPDATE_RESUMES_STARTED: "UPDATE_RESUMES_STARTED",
	UPDATE_RESUMES_SUCCESS: "UPDATE_RESUMES_SUCCESS",
	UPDATE_RESUMES_ERROR: "UPDATE_RESUMES_ERROR",
	DELETE_RESUMES_STARTED: "DELETE_RESUMES_STARTED",
	DELETE_RESUMES_SUCCESS: "DELETE_RESUMES_SUCCESS",
	DELETE_RESUMES_ERROR: "DELETE_RESUMES_ERROR"
}

export const areas = {
	GET_AREAS_STARTED: "GET_AREAS_STARTED",
	GET_AREAS_SUCCESS: "GET_AREAS_SUCCESS",
	GET_AREAS_ERROR: "GET_AREAS_ERROR",
	SHOW_AREAS_STARTED: "SHOW_AREAS_STARTED",
	SHOW_AREAS_SUCCESS: "SHOW_AREAS_SUCCESS",
	SHOW_AREAS_ERROR: "SHOW_AREAS_ERROR",
	CREATE_AREAS_STARTED: "CREATE_AREAS_STARTED",
	CREATE_AREAS_SUCCESS: "CREATE_AREAS_SUCCESS",
	CREATE_AREAS_ERROR: "CREATE_AREAS_ERROR",
	UPDATE_AREAS_STARTED: "UPDATE_AREAS_STARTED",
	UPDATE_AREAS_SUCCESS: "UPDATE_AREAS_SUCCESS",
	UPDATE_AREAS_ERROR: "UPDATE_AREAS_ERROR",
	DELETE_AREAS_STARTED: "DELETE_AREAS_STARTED",
	DELETE_AREAS_SUCCESS: "DELETE_AREAS_SUCCESS",
	DELETE_AREAS_ERROR: "DELETE_AREAS_ERROR",
	SEARCH_SKILLS: "SEARCH_SKILLS",

	SEARCH_SKILLS_SUCCESS:"SEARCH_SKILLS_SUCCESS",
	SEARCH_SKILLS_STARTED:"SEARCH_SKILLS_STARTED",
	SEARCH_SKILLS_ERROR:"SEARCH_SKILLS_ERROR",
}

export const areasSkills = {
	GET_AREAS_SKILLS_STARTED: "GET_AREAS_SKILLS_STARTED",
	GET_AREAS_SKILLS_SUCCESS: "GET_AREAS_SKILLS_SUCCESS",
	GET_AREAS_SKILLS_ERROR: "GET_EMPLOYEE_RESUMES_ERROR",
	SHOW_AREAS_SKILLS_STARTED: "SHOW_AREAS_SKILLS_STARTED",
	SHOW_AREAS_SKILLS_SUCCESS: "SHOW_AREAS_SKILLS_SUCCESS",
	SHOW_AREAS_SKILLS_ERROR: "SHOW_AREAS_SKILLS_ERROR",
	UPDATE_AREAS_SKILLS_STARTED: "UPDATE_AREAS_SKILLS_STARTED",
	UPDATE_AREAS_SKILLS_SUCCESS: "UPDATE_AREAS_SKILLS_SUCCESS",
	UPDATE_AREAS_SKILLS_ERROR: "UPDATE_AREAS_SKILLS_ERROR",
	DELETE_AREAS_SKILLS_STARTED: "DELETE_AREAS_SKILLS_STARTED",
	DELETE_AREAS_SKILLS_SUCCESS: "DELETE_AREAS_SKILLS_SUCCESS",
	DELETE_AREAS_SKILLS_ERROR: "DELETE_AREAS_SKILLS_ERROR",
}

export const languages = {
	GET_LANGUAGES_STARTED: "GET_LANGUAGES_STARTED",
	GET_LANGUAGES_SUCCESS: "GET_LANGUAGES_SUCCESS",
	GET_LANGUAGES_ERROR: "GET_LANGUAGES_ERROR",
	SHOW_LANGUAGES_STARTED: "SHOW_LANGUAGES_STARTED",
	SHOW_LANGUAGES_SUCCESS: "SHOW_LANGUAGES_SUCCESS",
	SHOW_LANGUAGES_ERROR: "SHOW_LANGUAGES_ERROR",
	CREATE_LANGUAGES_STARTED: "CREATE_LANGUAGES_STARTED",
	CREATE_LANGUAGES_SUCCESS: "CREATE_LANGUAGES_SUCCESS",
	CREATE_LANGUAGES_ERROR: "CREATE_LANGUAGES_ERROR",
	UPDATE_LANGUAGES_STARTED: "UPDATE_LANGUAGES_STARTED",
	UPDATE_LANGUAGES_SUCCESS: "UPDATE_LANGUAGES_SUCCESS",
	UPDATE_LANGUAGES_ERROR: "UPDATE_LANGUAGES_ERROR",
	DELETE_LANGUAGES_STARTED: "DELETE_LANGUAGES_STARTED",
	DELETE_LANGUAGES_SUCCESS: "DELETE_LANGUAGES_SUCCESS",
	DELETE_LANGUAGES_ERROR: "DELETE_LANGUAGES_ERROR"
}

export const skills = {
	GET_SKILLS_STARTED: "GET_SKILLS_STARTED",
	GET_SKILLS_SUCCESS: "GET_SKILLS_SUCCESS",
	GET_SKILLS_ERROR: "GET_SKILLS_ERROR",
	SHOW_SKILLS_STARTED: "SHOW_SKILLS_STARTED",
	SHOW_SKILLS_SUCCESS: "SHOW_SKILLS_SUCCESS",
	SHOW_SKILLS_ERROR: "SHOW_SKILLS_ERROR",
	CREATE_SKILLS_STARTED: "CREATE_SKILLS_STARTED",
	CREATE_SKILLS_SUCCESS: "CREATE_SKILLS_SUCCESS",
	CREATE_SKILLS_ERROR: "CREATE_SKILLS_ERROR",
	UPDATE_SKILLS_STARTED: "UPDATE_SKILLS_STARTED",
	UPDATE_SKILLS_SUCCESS: "UPDATE_SKILLS_SUCCESS",
	UPDATE_SKILLS_ERROR: "UPDATE_SKILLS_ERROR",
	DELETE_SKILLS_STARTED: "DELETE_SKILLS_STARTED",
	DELETE_SKILLS_SUCCESS: "DELETE_SKILLS_SUCCESS",
	DELETE_SKILLS_ERROR: "DELETE_SKILLS_ERROR"
}
