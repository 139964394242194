import React, { Component } from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { FormattedMessage } from "react-intl"
import LanguageForm from "../../components/forms/LanguageForm"
import MaterialIcon from "../../components/elements/MaterialIcon"

class LanguageCreatePage extends Component {
	render() {
		return (
			<div className="clients-create-page private-page">
				<div className="top-actions-row">
					<Link to="/languages" className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /> <FormattedMessage id="buttons.back_to_language" /> </Link>
				</div>
				<Card className="clients-card fadeInLoadUp">
					<Card.Body>
						<LanguageForm />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

LanguageCreatePage.propTypes = {
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(LanguageCreatePage)
