import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { showSkillAction } from "../../actions/skillsActions"
import SkillForm from "../../components/forms/SkillForm"
import MaterialIcon from "../../components/elements/MaterialIcon"

class SkillEditPage extends Component {

	state = {
		itemSkillsIndex: -1
	}

	componentDidMount() {
		const { match, history } = this.props

		if (!match.params.id)
			history.push("/skills")

		this.props.showSkillAction(match.params.id, this.handleFinish)
	}

	handleFinish = () => {
		const { skills, match, history } = this.props
		const itemSkillsIndex = skills.data.findIndex(item => (item.id === parseInt(match.params.id, 10)))
		if (itemSkillsIndex === -1)
			history.push("/skills")

		this.setState({ itemSkillsIndex })
	}

	render() {
		const { itemSkillsIndex } = this.state
		const { skills, match } = this.props
		const skillData = itemSkillsIndex !== -1 ? skills.data[itemSkillsIndex] : null
		return (
			<div className="skill-edit-page private-page">
				<div className="top-actions-row">
					<Link to={`/areas/${this.props.history.location.state}/skills`} className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /><FormattedMessage id="buttons.back_to_skill" /></Link>
				</div>
				<Card className="skill-card fadeInLoadUp">
					<Card.Body>
						<SkillForm skill={skillData} id={match.params.id} />
					</Card.Body>
				</Card>
			</div>
		)
	}

}
SkillEditPage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			skill_id: PropTypes.string
		})
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func
	}).isRequired,
	showSkillAction: PropTypes.func.isRequired,
	skills: PropTypes.shape({
		isLoading: PropTypes.bool,
		data: PropTypes.array
	}).isRequired,
}
const mapStateToProps = (state) => ({
	skills: state.skills,
})
export default connect(mapStateToProps, { showSkillAction })(SkillEditPage)
