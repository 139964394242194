import { areas } from "../actions/types"

const defaultAreasData = {
	data: [],
	redirectUrl: null,
	isLoading: true,
	hasError: false,
	error: null,
	isShowing: false,
	isLoadingActions: false,
	isSavingPermissions: false,
	isRedirecting: false,
	isExporting: false,
	isDeleting: false,
	isRestoring:false,
	isValidating:false,
	filters: [],
	meta: {
		pagination: {
			total: 0,
			count: 0,
			per_page: 15,
			current_page: 1,
			total_pages: 1,
		}
	}
}

const areasReducer = (state = defaultAreasData, action = []) => {
	switch (action.type) {

	case areas.GET_AREAS_STARTED:
		return { ...state, isLoading: true }

	case areas.GET_AREAS_SUCCESS:
		if (action.payload)
			return { ...state, ...action.payload, isLoading: false }
		return state

	case areas.GET_AREAS_ERROR:
		return { ...state, isLoading: false, hasError: true }

	case areas.SHOW_AREAS_STARTED:
		return { ...state, isShowing: true }

	case areas.SHOW_AREAS_SUCCESS:
		if (!action.payload.id)
			return state
		if (state.data.find(item => (item.id === action.payload.id)))
			return { ...state, isShowing: false, data: state.data.map(item => (item.id === action.payload.id ? action.payload : item)) }
		return { ...state, data: [...state.data, action.payload], isShowing: false }

	case areas.SHOW_AREAS_ERROR:
		return { ...state, isShowing: false, hasError: true }

	case areas.CREATE_AREAS_STARTED:
		return { ...state, isSaving: true }

	case areas.CREATE_AREAS_SUCCESS:
		if (action.payload)
			return {
				...state,
				data: [...state.data, { ...action.payload.data }],
				isSaving: false
			}
		return state

	case areas.CREATE_AREAS_ERROR:
		return { ...state, isSaving: false, hasError: true }

	case areas.DELETE_AREAS_STARTED:
		return {...state, isDeleting:true}

	case areas.DELETE_AREAS_SUCCESS:
		if (action.payload)
			return {...state, data: state.data.filter(item => (item.id !== action.payload.id)), isDeleting: false}
		return state

	case areas.DELETE_AREAS_ERROR:
		return { ...state, isDeleting:false, hasError:true }

		/// search area skills reducers 
		case areas.SEARCH_SKILLS_STARTED:
			return { ...state, isLoading: true }
	
		case areas.SEARCH_SKILLS_SUCCESS:
			if (action.payload)
			return { ...state, ...action.payload, isLoading: false }
			return state
	
		case areas.SEARCH_SKILLS_ERROR:
			return { ...state, isDeleting:false, hasError:true }

	default:
		return state
	}
}


export default areasReducer
