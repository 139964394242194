import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import LoginForm from "../../components/forms/LoginForm"
import PublicFormHeader from "../../components/elements/PublicFormHeader"

const LoginPage=()=> (
	<div className="login-page public-page ">
		<Container fluid>
			<Row noGutters>
				<Col lg={12} sm={12}>
					<div className="login-form-wrapper">
						<PublicFormHeader />
						<h2 className="secondary-title">Login</h2>
						<LoginForm/>
					</div>
				</Col>
				{/*<Col lg={6} sm={12} className="right-col-img">*/}
				{/*	<div className="right-image-cover">*/}
				{/*		<img src="/images/login-cover.jpg" alt="login cover" />*/}
				{/*	</div>*/}
				{/*</Col>*/}
			</Row>
		</Container>
	</div>
)
LoginPage.propTypes = {

}
export default LoginPage
