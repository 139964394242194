import { clientResumes } from "./types";
import ClientResumesService from "../services/ClientResumesService";
import HandleError from "../services/HandleError";
import Axios from "axios";
import getTransformedFilters from "../helpers/filterTransformer";

const clientResumesService = new ClientResumesService(this);

export const getClientResumesAction =
	({ clientId, pageSize, filters, page }) =>
	async (dispatch) => {
		try {
			const query = `&page=${page}per_page=${pageSize}&${getTransformedFilters(
				filters
			)}`;
			dispatch({ type: clientResumes.GET_CLIENT_RESUMES_STARTED });

			const res = await clientResumesService.getResumes(clientId, query);
			dispatch({
				type: clientResumes.GET_CLIENT_RESUMES_SUCCESS,
				payload: res.data,
			});
		} catch (error) {
			HandleError(error);
			dispatch({ type: clientResumes.GET_CLIENT_RESUMES_ERROR, error });
		}
	};

export const createClientResumesAction =
	(clientId, handleFinish) => async (dispatch) => {
		dispatch({ type: clientResumes.CREATE_CLIENT_RESUMES_STARTED });
		try {
			const response = await clientResumesService.createResumes(clientId);
			dispatch({
				type: clientResumes.CREATE_CLIENT_RESUMES_SUCCESS,
				payload: response.data,
			});
		} catch (error) {
			HandleError(error);
			dispatch({
				type: clientResumes.CREATE_CLIENT_RESUMES_ERROR,
				error,
			});
		} finally {
			handleFinish();
		}
	};
