import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { Card } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import MaterialIcon from "../../components/elements/MaterialIcon"
import { getResumesAction } from "../../actions/resumesActions"
import ResumesTable from "../../components/tables/ResumesTable"
class ArchivePage extends Component {
	state={
		search:"",
		SearchResults:{},
		searchToogle: false

	}
	componentDidMount() {
		this.props.getResumesAction({ pageSize: 15, sorted: null, filters: [], page: 1 });
	}
	// updateSearchResult  = (SearchResults) =>{
	// 	this.setState({SearchResults})
	// }
	componentDidUpdate(){
		console.log({state : this.state})
	}
	handelClick() {
		this.setState({searchToogle : !this.state.searchToogle})
		console.log(this.state.searchToogle);
		if(this.state.searchToogle) {
			this.setState({search:''});
		}
	}
	render() {
		return (
			<div>
				<div className="top-actions-row flex-between employee-seacrh-general">
					{/* <Link to="resumes/create" className="btn btn-primary btn-sm btn-roundet btn-dropshadow"><MaterialIcon icon="add" /><FormattedMessage id="buttons.new_resume" /></Link> */}
					{/* <Link to="resumes/create" className="btn btn-primary btn-sm btn-roundet btn-dropshadow"><FormattedMessage id="buttons.new_resume" /></Link> */}
					{/* <div className="border flex-align-center">
						<input id="generalSearch" placeholder="Search" className="pl-2 border-0" onChange={(e) => this.setState({search:e.target.value}) } />
						<label htmlFor='generalSearch' className="mb-0 p-1"><MaterialIcon icon="search" /></label>
					</div> */}

					<div className={`border flex-align-center outer-Table-Search ${this.state.searchToogle && 'open-parent-search-outer'} `}>
						<input id="generalSearch" placeholder="Search" className={`pl-2 border-0 ${this.state.searchToogle && 'open-search'}`} onChange={(e) => this.setState({search:e.target.value}) } />
						<label htmlFor='generalSearch' className="mb-0 p-1" onClick={() => this.handelClick()} ><MaterialIcon icon={`${this.state.searchToogle ? 'close': 'search'}`} /></label>
					</div>					
				</div>
				{/* <div className='Advanced-Search'>
					<AreaSkills updateSearch={this.updateSearchResult} />
				</div> */}
				<Card className="fadeInLoadUp" >
					<Card.Body>
						<ResumesTable SearchRow={this.state.search} SearchResults={this.state.SearchResults} archive={true}  deleted={false}  />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

ArchivePage.propTypes = {
	getResumesAction: PropTypes.func.isRequired,
	resumes: PropTypes.shape({
		isLoading: PropTypes.bool,
		isExporting: PropTypes.bool,
		hasError: PropTypes.bool,
		filters: PropTypes.arrayOf(PropTypes.object),
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number.isRequired
			})
		})
	}).isRequired
}

const mapStateToProps = (state) => ({
	resumes: state.resumes
})

export default connect(mapStateToProps, { getResumesAction })(ArchivePage)
