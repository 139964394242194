import axios from "axios"

class BaseService {

	/**
	 * React Component that is instantiating this service
	 * @type {React.Component}
	 */
	component = null;

	/**
	 * Set react component on object construction, so later can be used by its services
	 * @param component React.Component that is instantiating this service
	 */
	constructor(component) {
		this.component = component
	}

	baseServicePath = () => null

	/**
	 * launch a get request to baseServicePath and returns axios object with error handling defined,
	 * to get results, a .then() block need to be added
	 * @param pageNr to get, default 1
	 */
	index = (pageNr = 1, options = null) => { 
		console.log({path:`${this.baseServicePath()}?page=${pageNr}&${options}`})
		if (this.baseServicePath() != null) {
			if (options) {
				return axios.get(`${this.baseServicePath()}?page=${pageNr}&${options}`)
			}
			return axios.get(`${this.baseServicePath()}?page=${pageNr}`)
		}
		throw new Error("baseServicePath is not defined")
	}

	/**
	 * launch a get request to baseServicePath and returns axios object with error handling defined,
	 * to get results, a .then() block need to be added
	 * @param pageNr to get, default 1
	 */
	search = (term = null,otherFilterStrings="",parent_id= null,deleted = null ,archived = null) => {
		let deletedString  = deleted !== null ? deleted === true ? '&deleted=true' : "&deleted=false" :""
		let archivedString  = archived !== null ? archived === true ? '&archive=true' : "&archive=false" :""

		if (this.baseServicePath() != null) {
			if (parent_id !== null) {
				return axios.get(`${this.baseServicePath()}?term=${term}${otherFilterStrings}&parent_id=${parent_id}${deletedString}${archivedString}`)
			}
			return axios.get(`${this.baseServicePath()}?term=${term}${otherFilterStrings}${deletedString}${archivedString}`)
		}
		throw new Error("baseServicePath is not defined")
	}

	/**
	 * launch a get request to baseServicePath and returns axios object with error handling defined,
	 * to get results, a .then() block need to be added
	 * @param pageNr to get, default 1
	 */
	searchByAttribute = (attribute, value, extra) => {
		if (this.baseServicePath() != null) {
			if(extra) {
				return axios.get(`${this.baseServicePath()}?${attribute}=${value}&${extra}`)
			}
			return axios.get(`${this.baseServicePath()}?${attribute}=${value}`)
		}
		throw new Error("baseServicePath is not defined")
	}

	/**
	 * launch a get request to baseServicePath to get a resource by id and returns axios object with error handling defined,
	 * to get results, a .then() block need to be added
	 * @param id of resource to get
	 */
	show = (id, data = null) => {
		if (data) {
			return axios.get(`${this.baseServicePath()}/${id}`, {params: data})
		}
		if (this.baseServicePath() != null) {
			return axios.get(`${this.baseServicePath()}/${id}`)
		}
		throw new Error("baseServicePath is not defined")
	}

	/**
	 * launch a post request to baseServicePath to store a resource and returns axios object with error handling defined,
	 * to get results, a .then() block need to be added
	 * @param data/form to be stored
	 */
	store = (data, multipart = false,extra="") => {
		let config = {}
		if (multipart) {
			config = {
				headers: {
					"Content-Type": "multipart/form-data;"
				}
			}
		}
		if (this.baseServicePath() != null) {
			return axios.post(this.baseServicePath()+extra, data, config)
		}
		throw new Error("baseServicePath is not defined")
	}

	/**
	 * launch a put request to baseServicePath to update a resource and returns axios object with error handling defined,
	 * to get results, a .then() block need to be added
	 * @param id of resource to be updated
	 * @param data/form to be updated
	 */
	update = (id, data, multipart= false) => {
		if (this.baseServicePath != null) {
			let config = {}
			if (multipart) {
				config = {
					headers: {
						"Content-Type": "multipart/form-data;"
					}
				}
			}
			return axios.put(`${this.baseServicePath()}/${id}`, data, config)
		}
		throw new Error("baseServicePath is not defined")
	}

	/**
	 * launch a delete request to baseServicePath to delete a resource by id and returns axios object with error handling defined,
	 * to get results, a .then() block need to be added
	 * @param id of resource to be deleted
	 */
	delete = (id) => {
		if (this.baseServicePath != null) {
			return axios.delete(`${this.baseServicePath()}/${id}`)
		}
		throw new Error("baseServicePath is not defined")
	}
}

export default BaseService
