import { Form as FormLink, withFormik } from "formik"
import PropTypes from "prop-types"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { connect } from "react-redux"
import * as Yup from "yup"
import {FormattedMessage} from "react-intl"
import { updateClientAction, createClientAction } from "../../actions/clientsActions"
import MaterialIcon from "../elements/MaterialIcon"

const ClientForm = ({ isSubmitting, errors, touched, handleChange, values, setFieldValue, setFieldTouched }) => {

	return (
		<FormLink className='client-form form__loading form__short private-form'>
			{isSubmitting && <div className="loading-overlay">
				<h5>Loading...</h5>
			</div>}
			<div className="inputs-wrapper">
				<Form.Group controlId="input_full_name" className="floating-label">
					<Form.Control type="text" value={values.full_name} onChange={handleChange} name="full_name" isInvalid={!!errors.full_name && touched.full_name} />
					<Form.Label>Full name</Form.Label>
					<Form.Control.Feedback type="invalid">{errors.full_name}</Form.Control.Feedback>
				</Form.Group>
			</div>
			<div className="submit-wrapper">
				<Button type="submit" variant="success save" disabled={isSubmitting}><MaterialIcon icon="save" /> <FormattedMessage id="buttons.save_client" /> </Button>
			</div>
		</FormLink>
	)
}

const Schema = Yup.object().shape({
	full_name: Yup.string().required("This field is required"),
})

const FormikApp = withFormik({
	mapPropsToValues({ full_name, client }) {
		if (client) {
			return {
				full_name: client.full_name || ""
			}
		}
		return {
			full_name: full_name || ""
		}
	},
	validationSchema: Schema,
	enableReinitialize: true,
	handleSubmit(values, { props, setSubmitting, setErrors }) {
		if (props.client) {
			props.updateClientAction(props.id, values, setSubmitting, setErrors)
		} else {
			props.createClientAction(values, setSubmitting, setErrors)
		}
	},
})(ClientForm)

ClientForm.propTypes = {
	values: PropTypes.shape({
		full_name: PropTypes.string
	}).isRequired,
	errors: PropTypes.shape({
		full_name: PropTypes.string
	}).isRequired,
	touched: PropTypes.shape({
		full_name: PropTypes.bool
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	clients: state.clients
})
export default connect(mapStateToProps, { updateClientAction, createClientAction })(FormikApp)
