import React from "react"
import PropTypes from "prop-types"
import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { FormattedMessage } from "react-intl"
import MaterialIcon from "./MaterialIcon"
import getStaticUrl from "../../helpers/api"
import cogoToast from "cogo-toast"


const ClientResumesTableActionsCell = ({ cellProps, setShowDeleteModal , Route }) => {
		//copy resume url
		const CopyURL = () => {
			console.log("cellProps.original.id", cellProps.original.employee_id);
			navigator.clipboard.writeText(`${getStaticUrl()}/pdf/resumes/${cellProps.original.id}/create/create/client`).then(function() {
				cogoToast.success('Coppied successfully' ,{position:'top-right'});
			}, function() {
				cogoToast.error('Please try again' ,{position:'top-right'})
			});
		}
	return (
		<ButtonToolbar aria-label="Actions" className="table-actions-wrapper user-table-actions">
				<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-resumes-${cellProps.index}`}><FormattedMessage id="Copy Resume Link" /></Tooltip>}>
					<button onClick={CopyURL} className="btn btn-success btn-icons" rel="noopener noreferrer"><MaterialIcon icon="content_copy" /></button>
				</OverlayTrigger>

			<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-resumes-${cellProps.index}`}><FormattedMessage id="buttons.download_resume" /></Tooltip>}>
				<a href={`${getStaticUrl()}/pdf/resumes/${cellProps.original.id}/create`} className="btn btn-info btn-icons" rel="noopener noreferrer"><MaterialIcon icon="cloud_download" /></a>
			</OverlayTrigger>

			<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-edit-${cellProps.index}`}><FormattedMessage id="buttons.edit_resume" /></Tooltip>}>
				{/* <Link to={`/resumes/${cellProps.original.id}/edit`} className="btn btn-primary btn-icons"><MaterialIcon icon="edit" /></Link> */}
				<Link to={{pathname:`${Route? Route  : "resumes"}/${cellProps.original.id}/edit` , state:`${Route&&"clients"}`}} className="btn btn-primary btn-icons"><MaterialIcon icon="edit" /></Link>

			</OverlayTrigger>

			<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-delete-${cellProps.index}`}><FormattedMessage id="buttons.delete_resume" /></Tooltip>}>
				<Button variant="danger" onClick={() => setShowDeleteModal(cellProps.index)}  ><MaterialIcon icon="delete" /></Button>
			</OverlayTrigger>

		</ButtonToolbar>
	)
}

ClientResumesTableActionsCell.propTypes = {
	cellProps: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
		index: PropTypes.number,
	}).isRequired,
	setShowDeleteModal: PropTypes.func.isRequired,
}

export default ClientResumesTableActionsCell
