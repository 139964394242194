import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table"
import { connect } from "react-redux"
import { getLanguagesAction, deleteLanguageAction, FilterLanguages } from "../../actions/languagesActions"
import LanguagesTableActionsCell from "../elements/LanguagesTableActionsCell"
import DeleteModal from "../modals/DeleteModal"
import { FilterField } from "../../helpers/GeneralFillter"
import Axios from "axios"
import HandleError from "../../services/HandleError"

const LanguagesTable = ({ languages, getLanguagesAction, deleteLanguageAction  , FilterLanguages}) => {
	let index = -1
	let pageNr = languages.data.length ? languages.meta.pagination.current_page - 1 : 0
	const totalPages = languages.data.length ? languages.meta.pagination.total_pages : 1
	const [showDelete, setShowDeleteModal] = useState(-1)
	const [counterPages, setCounterPages] = useState(100)

	const columns = [
		{ Header: "ID", filterable: false, accessor: "id", Cell: cellProps => cellProps.value || "" },
		{ Header: "Name", filterable: true, Filter:FilterField, accessor: "name", Cell: cellProps => cellProps.value || "" },
		{
			Header: () => <div className="header-actions">Actions</div>,
			filterable: false,
			Cell: cellProps => <LanguagesTableActionsCell
				cellProps={cellProps}
				setShowDeleteModal={setShowDeleteModal}
			/>,
		}
	]
	useEffect(()=> {
		pageNr = totalPages - 1
		const { filters } = languages
		getLanguagesAction({ pageSize: counterPages, sorted: null, filters, page: 1  })

		if(!languages.data.length) {
			const { filters } = languages
			getLanguagesAction({ pageSize: counterPages, sorted: null, filters, page: 1  })
		}
	}, [counterPages])
	const handleCloseDelete = () => {
		setShowDeleteModal(-1)
	}

	const handlePaginateFetch = (page) => {
		const { filters } = languages
		getLanguagesAction({ pageSize: counterPages, sorted: null, filters, page: page + 1 });
	}

	const handleDelete = async () => {
		const { id } = languages.data[index]
		await deleteLanguageAction(id, handleCloseDelete)
	
		console.log("pageNr", pageNr);
			const { filters } = languages
			await getLanguagesAction({ pageSize: counterPages, sorted: null, filters, page: pageNr + 1 });	

	}

	if (showDelete >= 0) {
		index = showDelete
	}

	const onRowClick = (state, rowInfo, column, instance) => {
		if(rowInfo) {
			setCounterPages(rowInfo.pageSize)
			return {
			}
			} else {
				return {}
			}	
	}	

	// filter data by serverside
	const FilterData = async (column, value) => {
		if(column.length){
			
			FilterLanguages(column[0].value); // search by name 
		}else{
			FilterLanguages("")
		}
	}
	console.log("languages.data", languages.data);
	return (
		<div className="table-style">
			<ReactTable
				data={languages.data}
				loading={languages.isLoading}
				columns={columns}
				defaultPageSize={100}
				pages={totalPages}
				page={pageNr}
				manual // for handle pagination
				filterable
				onFilteredChange={FilterData} 
				// defaultFilterMethod={(filter,row) => {
				// 	if(row[filter.id].toLowerCase().includes(filter.value.toLowerCase())){
				// 		return row
				// 	}
				// }}					
				showPageSizeOptions={true}
				pageSizeOptions= {[5, 10, 15, 20, 25, 50, 100]}
				sortable={false}
				className="striped-highlight remove-id unique-filter-table"
				onPageChange={handlePaginateFetch}
				getTdProps= {onRowClick}
			/>

			<DeleteModal
				isShown={showDelete !== -1}
				handleClose={handleCloseDelete}
				title={languages.data[index] ? `Language ${languages.data[index].name}` : ""}
				handleDelete={handleDelete}
				isDeleting={languages.isDeleting}
			/>
		</div>
	)
}

LanguagesTable.propTypes = {
	getLanguagesAction: PropTypes.func.isRequired,
	deleteLanguageAction: PropTypes.func.isRequired,
	languages: PropTypes.shape({
		data: PropTypes.array,
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number,
				total_pages: PropTypes.number
			})
		}),
		filters: PropTypes.array,
		isLoading: PropTypes.bool,
		hasError: PropTypes.bool,
		isRestoring: PropTypes.bool,
		isDeleting: PropTypes.bool
	}).isRequired
}

const mapStateToProps = (state) => ({
	languages: state.languages
})

export default connect(mapStateToProps, { getLanguagesAction, deleteLanguageAction ,FilterLanguages })(LanguagesTable)

