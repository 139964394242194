import React, { useEffect, useRef, useState } from "react";
import MaterialIcon from "../components/elements/MaterialIcon";
// this method for general search of tables  this methods for Front end search not backend 
/**
 * 
 * @param {String} SearchRow --> search word entered by user 
 * @param {Array} TableData --> table data 
 * @param {State} setFillter  --> to set new data in a state
 */

export const GeneralFilter = (SearchRow , TableData ,setFillter) =>{
    if(SearchRow.length){
        let newdata = TableData.data.map(itm => {
            for(let i in itm){
                if(i !== 'client' && i !== 'employee'){
                    if (String(itm[i]).toLowerCase().includes(SearchRow.toLowerCase())) return itm
                }else{
                    if (itm[i].full_name.toLowerCase().includes(SearchRow.toLowerCase())) return itm
                }
                if(i == 'employee_level') {
                    if (itm['employee_level'].name.toLowerCase().includes(SearchRow.toLowerCase())) return itm
                }
                if(i == 'abilities'){
                    return itm['abilities'].map(it => it.skill.name.toLowerCase().includes(SearchRow.toLowerCase()) ? itm : false ).filter(Boolean)
                }
            }
        });
        setFillter(newdata.flat().filter(Boolean))
    }else{
        setFillter(null)
    }
}


export const FilterField =({filter, onChange}) => {
	const [showFiltered, setShowFiltered] = useState(false) //{`${showFiltered ? true : false}`}
    const inputRef = useRef();
    const [inputValue, setInputValue] = useState(null);
    const handelLabel = () => {
        inputRef.current.focus();
        setShowFiltered(!showFiltered);
        console.log("showFiltered", showFiltered);

        if(showFiltered === true) {
            onChange('');
            console.log("onChange", onChange(''));
            setInputValue('');
        }
    }
    const handelInput = (e) => {
        setInputValue(e);
        onChange(e)
    }
    return (
        <div>
        <div className={`border flex-align-center innerTableSearch ${showFiltered && 'open-search-filter'}`}>
        <input id="generalSearchFilter" placeholder="Search"  className={`pl-2 border-0 ${showFiltered && 'open-search'}`} onChange={event => handelInput(event.target.value) } value={inputValue} ref={inputRef} />
        <label htmlFor="generalSearchFilter" className="option-filter" onClick={() => handelLabel()}><MaterialIcon icon= {`${showFiltered ? 'close' : 'search'}`}/></label>
    </div>
        </div>
    )
} 
