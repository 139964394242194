import React from "react"
import PropTypes from "prop-types"

const MaterialIcon = ({ icon }) => (
	<i className="material-icons" >{icon}</i>
)
MaterialIcon.propTypes = {
	icon: PropTypes.string.isRequired
}
export default MaterialIcon
