import API from "./APIService"
import BaseService from "./BaseService"

class AreasService extends BaseService {
	component = null

	constructor(component = null) {
		super()
		this.component = component
	}

	baseServicePath = () => {
		return API.url("areas")
	}

	areaSkills = (id) => {
		return this.show(`${id}/skills`)
	}
}

export default AreasService
