import { combineReducers } from "redux"
import axios from "axios"
import authReducer from "./authReducer"
import clientsReducer from "./clientsReducer"
import employeesReducer from "./employeesReducer"
import resumesReducer from "./resumesReducer"
import areasReducer from "./areasReducer"
import skillsReducer from "./skillsReducer"
import clientResumesReducer from "./clientResumesReducer"
import employeeResumesReducer from "./employeeResumesReducer"
import languagesReducer from "./languagesReducer"

const appReducer = combineReducers({
	auth: authReducer,
	clients: clientsReducer,
	employees: employeesReducer,
	resumes: resumesReducer,
	areas: areasReducer,
	skills: skillsReducer,
	client_resumes: clientResumesReducer,
	employee_resumes: employeeResumesReducer,
	languages: languagesReducer,
})

const rootReducer = (state, action) => {
	if (action.type === "USER_LOGGED_OUT") {
		axios.defaults.headers.common.Authorization = null
		localStorage.clear()
		state = undefined // eslint-disable-line no-param-reassign
	}
	return appReducer(state, action)
}

export default rootReducer
