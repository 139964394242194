import React from "react"
import PropTypes from "prop-types"
import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import {FormattedMessage} from "react-intl"
import MaterialIcon from "./MaterialIcon"

const LanguagesTableActionsCell = ({ cellProps, setShowDeleteModal }) => {
	return (
		<ButtonToolbar aria-label="Actions" className="table-actions-wrapper user-table-actions">

			<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-edit-${cellProps.index}`}><FormattedMessage id="buttons.edit_language" /></Tooltip>}>
				<Link to={`/languages/${cellProps.original.id}/edit`} className="btn btn-primary btn-icons"><MaterialIcon icon="edit" /></Link>
			</OverlayTrigger>

			<OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-delete-${cellProps.index}`}><FormattedMessage id="buttons.delete_language" /></Tooltip>}>
				<Button variant="danger" onClick={() => setShowDeleteModal(cellProps.index)}  ><MaterialIcon icon="delete" /></Button>
			</OverlayTrigger>

		</ButtonToolbar>
	)
}

LanguagesTableActionsCell.propTypes = {
	cellProps: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
		index: PropTypes.number,
	}).isRequired,
	setShowDeleteModal: PropTypes.func.isRequired,
}

export default LanguagesTableActionsCell
