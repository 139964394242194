import cogoToast from "cogo-toast"
// import { SubmissionError } from "redux-form"
import { logOutAction } from "../actions/authActions"
import { store } from "../store"

export default function (error, setErrors) {
	if (!error.response) {
		cogoToast.error("Please make sure you are connected to internet. Can not connect to server!", { 
			position: "top-right",
			 heading: "Information",
			onClick: (hide) => {
				hide();
			} 
		})
	} else {
		switch (error.response.status) {
		case 400:
			if (error.response.data && error.response.data.dublicate) {
				cogoToast.error(error.response.data.dublicate , {
					position: "top-right",
					heading: "Error",
					onClick: (hide) => {
						hide();
					} 
				})
				break
			}
			cogoToast.error("Something went wrong!", { 
				position: "top-right",
				heading: error.response.statusText,
				onClick: (hide) => {
					hide();
				} 
			 })
			break
		case 401:
			store.dispatch(logOutAction())
			cogoToast.error("You must login to see this page.", { 
				position: "top-right",
				heading: "Unauthorized!",
				onClick: (hide) => {
					hide()
				} })
			break
		case 403:
			if (error.response.data && error.response.data.message) {
				cogoToast.error(error.response.data.message, { 
					position: "top-right",
					heading: "Forbidden!",
					onClick: (hide) => {
						hide() 
					} })
				break
			}
			cogoToast.error("You are not allowed to access this page!", { 
				position: "top-right",
				heading: "Forbidden!",
				onClick: (hide) => {
					hide() 
				}
				 })
			break
		case 404:
			cogoToast.error(error.response.data.message||"Not found", { 
				position: "top-right",
				heading: error.response.statusText,
				onClick: (hide) => {
					hide() 
				}			
			})
			break
		case 413:
			cogoToast.error(error.response.data.message, { 
				position: "top-right",
				heading: error.response.statusText,
				onClick: (hide) => {
					hide() 
				}			
			})
			break
		case 422:
			cogoToast.error(error.response.data.message, { 
				position: "top-right",
				onClick: (hide) => {
					hide() 
				}			
			})
			if (error.response.statusText && error.response.data.errors) {
				const errors = {}
				error.response.data.errors.forEach(entityError => {
					errors[entityError.field] = entityError.message
				})
				if (setErrors){
					setErrors(errors)
				}
			}
			break
		default:
			if (error.response.data.message.length > 100) {
				cogoToast.error("Unhandled error!", {
					position: "top-right",
					heading: "Error!",
					onClick: (hide) => {
						hide() 
					}				
				})
			}else {
				cogoToast.error(error.response.data.message, { 
					position: "top-right",
					heading: "Error!",
					onClick: (hide) => {
						hide() 
					}				
				})
			}
			break
		}
	}
}
