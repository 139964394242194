import EmployeeLevelsService from "../services/EmployeeLevelsService"
import HandleError from "../services/HandleError"

const employeeLevelsService = new EmployeeLevelsService(this)

export const searchEmployeeLevels = (inputValue) => async () => {
	try {
		const response = await employeeLevelsService.search(inputValue || " ")
		return response.data.map((item) => ({
			id: item.id,
			name: item.name,
		}))
	} catch (error) {
		HandleError(error)
	}
}
