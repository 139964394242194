export default {
	messages: {
		missing_permissions: "L'unità aziendale senza autorizzazioni non può essere salvata! Seleziona almeno un'autorizzazione!",
		no_roles: "Nessun ruolo trovato",
		role_without_permissions: "Il ruolo senza autorizzazioni non può essere salvato! Seleziona almeno un'autorizzazione!",
		business_unit_without_permissions: "L'unità aziendale senza autorizzazioni non può essere salvata! Seleziona almeno un'autorizzazione!",
		no_permissions: "Nessuna autorizzazione trovata!",
		will_be_deleted: " sarà cancellato",
		will_be_restored: " sarà ripristinato",
		user_password_change: "Se compili i campi sottostanti, la password dell'utente verrà modificata"
	},
	buttons: {
		new_user: "Nuovo utente",
		export_csv: "Esportare CSV",
		save_profile: "Salva il profilo",
		back_to_users: "Torna agli utenti",
		edit_user: "Modifica utente",
		delete_user: "Elimina utente",
		force_delete_user: "Elimina definitivamente l'utente",
		save_user: "Salva utente",
		save_role: "Salva ruolo",
		save_permissions: "Salva le autorizzazioni",
		block_user: "Blocca utente",
		restore_user: "Ripristina utente",
		unblock_user: "Sblocca utente",
		new_role: "Nuovo ruolo",
		back_to_roles: "Torna ai ruoli",
		new_business_unit: "Nuova unità aziendale",
		back_to_business_unit: "Torna all'unità di business",
		save_business_unit: "Salva unità aziendale",
		restore_business_unit: "Ripristina unità di business",
		edit_business_unit: "Modifica unità aziendale",
		delete_business_unit: "Elimina unità aziendale",
		force_delete_business_unit: "Elimina definitivamente unità aziendale",
		upload: "Caricare",
		new_client: "Nuovo cliente",
		back_to_client: "Torna ai clienti",
		new_module: "Nuovo modulo",
		back_to_modules: "Torna ai moduli",
		new_account: "Nuovo account",
		back_to_account: "Torna all'account",
		new_installation: "Nuove installazione",
		back_to_installation: "Torna alle installazioni ",
		new_server: "Nuovo server",
		back_to_server: "Torni ai server",
		change_password: "Cambia la password",
		back_to_report: "Torna al rapporto",
		new_report: "Nuovo rapporto",
		edit_report: "Modifica rapporto",
		delete_report: "Elimina rapporto",
		pause_report: "Pause rapporto",
		activate_report: "Attiva il rapporto",
		new_template: "Nuovo template",
		back_to_templates: "Torno ai templates",
		edit_server: "Modifica server",
		delete_server: "Elimina server",
		send_report_as_email: "Invia rapporto come e-mail",
		transfer_ownership: "Trasferire la proprietà",
		share_record: "Condividi record",
		select_share: "Seleziona la persona da condividere",
		close: "Chiuso",
		archive_report:"Archivio report",
		back_to_reports:"Torna ai rapporti",
		resend_report: "Invia di nuovo il rapporto",
		preview_report: "Anteprima del rapporto",
		record_audit: "Record di audit",
		add_comment: "Aggiungi un commento",
		choose_comments: "Scegli commenti",
		new_comments: "Nuovi commenti",
		remove_comment: "Rimuovi commento",
		update_comments: "Aggiorna i commenti",
		account_sites: "Scegli account e sito per il rapporto",
		report_fields: "Scegli i campi del rapporto",
		add_business_unit_role: "Aggiungi unità aziendale"
	},
	validations: {

	},
	navigations: {
		dashboard: "Pannello",
		users: "Utenti",
		roles: "Ruoli",
		business_units: "Unità di business",
		clients: "Clienti",
		modules: "Moduli",
		exports: "Esportazioni",
		accounts: "Accounts",
		installations: "Installazioni",
		servers: "Server",
		audits: "Audits",
		log_out: "Esci",
		previous: "Precedente",
		next: "Seguente",
		create_business_unit: "Crea business unit",
		create_client: "Crea client",
		edit_client: "Modifica client",
		create_module: "Crea modulo",
		edit_module: "modulo di modifica",
		create_account: "Creare un profilo",
		create_server: "Nuovo Server",
		profile: "Profilo",
		report: "Rapporto",
		clients_orders: "Clienti e ordini",
		reports: "Rapporti",
		settings: "Impostacioni",
		sites: "Siti WEB",
		"email-templates":"Email templates",
		archive: "Archivi"
	},
	labels: {
		account_info: "Informazioni account",
		profile_settings: "Impostazioni del profilo",
		create_user: "Creare un utente",
		edit_user: "Modifica utente",
		edit_password: "Modifica la password",
		user_permissions: "Permesso dell'utente",
		role_permissions: "Permesso dell'ruoli",
		business_unit_permissions: "Permesso dell'unità di business",
		normal_users: "Utenti normali",
		all_users: "Tutti gli utenti",
		blocked_users: "Utenti bloccati",
		deleted_users: "Utenti eliminati",
		roles: "Ruoli",
		permission: "Permesso",
		role_name: "Nome del ruolo",
		parent_business_unit: "Unità di business capogruppo",
		type_to_search: "Digita per cercare",
		business_unit_name: "Nome dell'unità aziendale",
		show_deleted: "Mostra cancellato",
		delete: "Elimina",
		force_delete: "Elimina definitivamente",
		close: "Chiuso",
		restore: "Ristabilire",
		create_role: "Crea ruolo",
		profile_picture: "Immagine del profilo",
		transfer_ownership: "Trasferire la proprietà",
		select_new_owner: "Seleziona nuovo proprietario",
		share_record: "Condividi record",
		select_share: "Seleziona la persona da condividere",
		"email-templates":"Modelli di posta elettronica",
		record_audit: "Record di audit"
	},
	app: {
		loading: "Caricamento in corso..."
	},
	permissions: {
		users: "Utenti",
		roles: "Ruoli",
		business_units: "Unità di business",
		clients: "Clienti",
		modules: "Moduli",
		exports: "Esportazioni",
		accounts: "Accounts",
		installations: "Installazioni",
		servers: "Server",
		audits: "Audits",
		companies: "Aziende",
		permissions: "Permessi",
		reports: "Rapporti",
		template: "Rapporti",
		scheduler: "Rapporti",
		schedulers: "Rapporti",
		tags: "Tags",
		templates: "Rapporti",
		comment_sections: "Sezione commenti",
		records: "Records",
		"email-templates" : "Modelli di posta elettronica"
	}
}
