import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	ButtonToolbar,
	OverlayTrigger,
	Tooltip,
	Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FormattedMessage } from "react-intl";
import MaterialIcon from "./MaterialIcon";
import getStaticUrl from "../../helpers/api";
import cogoToast from "cogo-toast";

const EmployeesTableActionsCell = ({
	cellProps,
	setShowDeleteModal,
	setArchiveModal,
	fromEmployee,
	newResume = true,
	Route,
}) => {
	//copy resume url
	const CopyURL = () => {
		console.log("cellProps.original.id", cellProps.original.id);
		navigator.clipboard
			.writeText(
				`${getStaticUrl()}/pdf/employees/${
					cellProps.original.id
				}/create`
			)
			.then(
				function () {
					cogoToast.success("Coppied successfully", {
						position: "top-right",
					});
				},
				function () {
					cogoToast.error("Please try again", {
						position: "top-right",
					});
				}
			);
	};
	return (
		<ButtonToolbar
			aria-label="Actions"
			className="table-actions-wrapper user-table-actions"
		>
			{Route === "resumes/Atis" && (
				<>
					{/* <OverlayTrigger placement="bottom" overlay={<Tooltip id={`action-tooltip-resumes-${cellProps.index}`}><FormattedMessage id="Copy Resume Link" /></Tooltip>}>
					<button onClick={CopyURL} className="btn btn-success btn-icons" rel="noopener noreferrer"><MaterialIcon icon="content_copy" /></button>
				</OverlayTrigger> */}

					<Dropdown>
						<Dropdown.Toggle
							style={{
								width: 50,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "rgb(19, 132, 150)",
								borderColor: "transparent",
								paddingRight: 5,
							}}
						>
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip
										id={`action-tooltip-new-resume-${cellProps.index}`}
									>
										<FormattedMessage id="buttons.download_resume" />
									</Tooltip>
								}
							>
								<a
									href={`${getStaticUrl()}/pdf/employees/${
										cellProps.original.id
									}/create`}
									className="btn btn-icons"
									rel="noopener noreferrer"
									style={{ color: "white" }}
									onClick={(e) => e.stopPropagation()}
								>
									<MaterialIcon icon="cloud_download" />
								</a>
							</OverlayTrigger>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item
								className="dropdown-item-resumes"
								onClick={() => {
									window.open(
										`${getStaticUrl()}/word/employees/${
											cellProps.original.id
										}/create`,
										"_blank",
										"noopener noreferrer"
									);
								}}
							>
								<MaterialIcon icon="cloud_download" />
								<FormattedMessage id="buttons.download_word" />
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>

					{newResume && (
						<OverlayTrigger
							placement="bottom"
							overlay={
								<Tooltip
									id={`action-tooltip-new-resume-${cellProps.index}`}
								>
									<FormattedMessage id="buttons.new_resume" />
								</Tooltip>
							}
						>
							<Link
								to={`/employees/${cellProps.original.id}/resumes/create`}
								className="btn btn-success btn-icons"
							>
								<MaterialIcon icon="add" />
							</Link>
						</OverlayTrigger>
					)}
					<OverlayTrigger
						placement="bottom"
						overlay={
							<Tooltip
								id={`action-tooltip-edit-${cellProps.index}`}
							>
								<FormattedMessage id="buttons.edit_resume" />
							</Tooltip>
						}
					>
						<Link
							to={`${Route ? Route : "employees"}/${
								cellProps.original.id
							}/edit`}
							className="btn btn-primary btn-icons"
						>
							<MaterialIcon icon="edit" />
						</Link>
					</OverlayTrigger>

					<OverlayTrigger
						placement="bottom"
						overlay={
							<Tooltip
								id={`action-tooltip-delete-${cellProps.index}`}
							>
								<FormattedMessage id="buttons.archive_resume" />
							</Tooltip>
						}
					>
						<Button
							variant="danger"
							onClick={() => setArchiveModal(cellProps.index)}
						>
							<MaterialIcon icon="archive" />
						</Button>
					</OverlayTrigger>
				</>
			)}
		</ButtonToolbar>
	);
};

EmployeesTableActionsCell.propTypes = {
	cellProps: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
		index: PropTypes.number,
	}).isRequired,
	setShowDeleteModal: PropTypes.func.isRequired,
};

export default EmployeesTableActionsCell;
