import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { showResumeAction } from "../../actions/resumesActions";
import MaterialIcon from "../../components/elements/MaterialIcon";
import EmployeeForm from "../../components/forms/EmployeeForm";

class ResumeEditPage extends Component {
	state = {
		itemResumesIndex: -1,
	};

	componentDidMount() {
		const { match, history, location } = this.props;
		if (!match.params.id) history.push("/resumes");

		this.props.showResumeAction(match.params.id, this.handleFinish);
	}
	handleFinish = () => {
		const { resumes, match, history } = this.props;
		const itemResumesIndex = resumes.data.findIndex(
			(item) => item.id === parseInt(match.params.id, 10)
		);
		if (itemResumesIndex === -1) history.push("/resumes");

		this.setState({ itemResumesIndex });
	};

	render() {
		const { itemResumesIndex } = this.state;
		const { resumes } = this.props;
		const resumeData =
			itemResumesIndex !== -1 ? resumes.data[itemResumesIndex] : null;

		return (
			<div className="client-edit-page private-page">
				<div className="top-actions-row">
					<Link
						to={-1}
						className="btn-primary btn-roundet btn-dropshadow btn btn-sm"
					>
						<MaterialIcon icon="arrow_back" />
						<FormattedMessage id="buttons.back_to_resume" />
					</Link>
				</div>
				<Card className="client-card fadeInLoadUp">
					<Card.Body>
						<EmployeeForm
							CurrentNav={this.props.location.state}
							employee={resumeData?.data_formatted}
							id={resumeData?.data_formatted?.id}
							component="ResumeEditPage"
							showEmployee
							showClient
							fromOther={resumeData}
						/>
					</Card.Body>
				</Card>
			</div>
		);
	}
}
ResumeEditPage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
		}),
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
	showResumeAction: PropTypes.func.isRequired,
	resumes: PropTypes.shape({
		isLoading: PropTypes.bool,
		data: PropTypes.array,
	}).isRequired,
};
const mapStateToProps = (state) => ({
	resumes: state.resumes,
});
export default connect(mapStateToProps, { showResumeAction })(ResumeEditPage);
