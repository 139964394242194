import API from "./APIService"
import BaseService from "./BaseService"

class EmployeeLevelsService extends BaseService {
	component = null

	constructor(component = null) {
		super()
		this.component = component
	}

	baseServicePath = () => {
		return API.url("employee-levels")
	}
}

export default EmployeeLevelsService
