import API from "./APIService"
import BaseService from "./BaseService"

class ResumesService extends BaseService {
	component = null

	constructor(component = null) {
		super()
		this.component = component
	}

	baseServicePath = () => {
		return API.url("resumes")
	}
}

export default ResumesService
