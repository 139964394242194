import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MaterialIcon from "../../components/elements/MaterialIcon";
import { getEmployeeResumesAction } from "../../actions/employeeResumesActions";
import EmployeeResumesTable from "../../components/tables/EmployeeResumesTable";
import history from "../../routes/history";

class EmployeeResumesPage extends Component {
	state = {
		itemEmployeeResumesIndex: -1,
		search: "",
		searchToogle: false,
	};

	render() {
		return (
			<div>
				<div className="top-actions-row flex-between">
					<div>
						<Link
							to={
								history.location.pathname.includes("resumes/")
									? "/resumes"
									: "/employees"
							}
							className="btn-primary btn-roundet btn-dropshadow btn btn-sm"
						>
							<MaterialIcon icon="arrow_back" />{" "}
							<FormattedMessage id="buttons.back_to_employee" />{" "}
						</Link>
						<Link
							to={`${history.location.pathname}/create`}
							className="btn-primary btn-roundet btn-dropshadow btn btn-sm ml-3"
						>
							<MaterialIcon icon="add" />{" "}
							<FormattedMessage id="buttons.new_resume" />
						</Link>
					</div>
					{/* <div className="border flex-align-center">
						<input id="generalSearch" placeholder="Search" className="pl-2 border-0" onChange={(e) => this.setState({search:e.target.value}) } />
						<label htmlFor='generalSearch' className="mb-0 p-1"><MaterialIcon icon="search" /></label>
					</div> */}

					<div
						className={`border flex-align-center outer-Table-Search ${
							this.state.searchToogle &&
							"open-parent-search-outer"
						} `}
					>
						<input
							id="generalSearch"
							placeholder="Search"
							className={`pl-2 border-0 ${
								this.state.searchToogle && "open-search"
							}`}
							onChange={(e) =>
								this.setState({ search: e.target.value })
							}
						/>
						<label
							htmlFor="generalSearch"
							className="mb-0 p-1"
							onClick={() =>
								this.setState({
									searchToogle: !this.state.searchToogle,
								})
							}
						>
							<MaterialIcon
								icon={`${
									this.state.searchToogle ? "close" : "search"
								}`}
							/>
						</label>
					</div>
				</div>
				<Card className="fadeInLoadUp">
					<Card.Body>
						<EmployeeResumesTable SearchRow={this.state.search} />
					</Card.Body>
				</Card>
			</div>
		);
	}
}

EmployeeResumesPage.propTypes = {
	getEmployeeResumesAction: PropTypes.func.isRequired,
	employee_resumes: PropTypes.shape({
		isLoading: PropTypes.bool,
		isExporting: PropTypes.bool,
		hasError: PropTypes.bool,
		filters: PropTypes.arrayOf(PropTypes.object),
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number.isRequired,
			}),
		}),
		data: PropTypes.array,
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
		}),
	}).isRequired,
};

const mapStateToProps = (state) => ({
	employee_resumes: state.employee_resumes,
});

export default connect(mapStateToProps, { getEmployeeResumesAction })(
	EmployeeResumesPage
);
