import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import { injectIntl } from "react-intl"
import history from "./history"
import PublicRoute from "./PublicRoute"
import PrivateRoute from "./PrivateRoute"
import DashboardPage from "../pages/DashboardPage"
import LoginPage from "../pages/public-pages/LoginPage"
import NotFoundPage from "../pages/public-pages/NotFoundPage"


import ClientsPage from "../pages/clients/ClientsPage"
import ClientsEditPage from "../pages/clients/ClientsEditPage"
import ClientsCreatePage from "../pages/clients/ClientsCreatePage"
import ClientResumesPage from "../pages/clients/ClientResumesPage"
import ClientResumesCreatePage from "../pages/clients/ClientResumesCreatePage"
import EmployeePage from "../pages/employees/EmployeePage"
import EmployeeEditPage from "../pages/employees/EmployeeEditPage"
import EmployeeCreatePage from "../pages/employees/EmployeeCreatePage"
import EmployeeResumesPage from "../pages/employees/EmployeeResumesPage"
import EmployeeResumesCreatePage from "../pages/employees/EmployeeResumesCreatePage"
import ResumePage from "../pages/resumes/ResumePage"
import ResumeEditPage from "../pages/resumes/ResumeEditPage"
import ResumeCreatePage from "../pages/resumes/ResumeCreatePage"
import AreaPage from "../pages/areas/AreaPage"
import AreaEditPage from "../pages/areas/AreaEditPage"
import AreaCreatePage from "../pages/areas/AreaCreatePage"
import SkillPage from "../pages/skills/SkillPage"
import SkillEditPage from "../pages/skills/SkillEditPage"
import SkillCreatePage from "../pages/skills/SkillCreatePage"
import LanguagePage from "../pages/languages/LanguagePage"
import LanguageEditPage from "../pages/languages/LanguageEditPage"
import LanguageCreatePage from "../pages/languages/LanguageCreatePage"
import AreaSkillsPage from "../pages/areas/AreaSkillsPage"
import Archive from "../pages/Archive/Archive"

const ApplicationRouter = (props) => {
	return (
		<Router history={history}>
			<div className="rooter-wrapper">
				<Switch>

					{/* Initial route when user enters app */}
					<Route path="/" exact render={() => (props.isAuthenticated ? <Redirect to="/dashboard" /> : <Redirect to="/login" />)} />

					<PublicRoute path='/login' exact component={LoginPage} />
					<PrivateRoute path='/dashboard' permission_name="dashboard" exact component={DashboardPage} name={props.intl.formatMessage({ id: "navigations.dashboard" })} />

					<PrivateRoute path='/employees' exact component={EmployeePage} name={props.intl.formatMessage({ id: "navigations.employee" })} />
				 	{/* <PrivateRoute path={['/employees/:id/edit', '/resumes/:id/edit']} exact component={EmployeeEditPage} name={props.intl.formatMessage({ id: "navigations.employee" })} /> */}
				 	<PrivateRoute path={['/employees/:id/edit' , '/resumes/Atis/:id/edit']} exact component={EmployeeEditPage} name={props.intl.formatMessage({ id: "navigations.employee" })} />
					<PrivateRoute path={['/employees/create', '/resumes/create']} exact component={EmployeeCreatePage} name={props.intl.formatMessage({ id: "navigations.employee" })} />
					<PrivateRoute path='/employees/create' exact component={EmployeeCreatePage} name={props.intl.formatMessage({ id: "navigations.employee" })} />
					{/* <PrivateRoute path={['/employees/:id/resumes', '/resumes/:id/employees']} exact component={EmployeeResumesPage} name={props.intl.formatMessage({ id: "navigations.employee_resumes" })} /> */}
					<PrivateRoute path={['/employees/:id/resumes' , '/resumes/Atis/:id/resumes']} exact component={EmployeeResumesPage} name={props.intl.formatMessage({ id: "navigations.employee_resumes" })} />
					<PrivateRoute path={['/employees/:id/resumes/create' , '/resumes/Atis/:id/resumes/create']} exact component={EmployeeResumesCreatePage} name={props.intl.formatMessage({ id: "navigations.employee_resumes" })} />
					<PrivateRoute path='/clients' exact component={ClientsPage} name={props.intl.formatMessage({ id: "navigations.clients" })} />
					<PrivateRoute path='/clients/:id/edit' exact component={ClientsEditPage} name={props.intl.formatMessage({ id: "navigations.clients" })} />
					<PrivateRoute path='/clients/create' exact component={ClientsCreatePage} name={props.intl.formatMessage({ id: "navigations.clients" })} />
					<PrivateRoute path='/clients/:id/resumes' exact component={ClientResumesPage} name={props.intl.formatMessage({ id: "navigations.client_resumes" })} />
					<PrivateRoute path='/clients/:id/resumes/create' exact component={ClientResumesCreatePage} name={props.intl.formatMessage({ id: "navigations.client_resumes" })} />
					<PrivateRoute path='/resumes' exact component={ResumePage} name={props.intl.formatMessage({ id: "navigations.resume" })} />
					<PrivateRoute path='/archive' exact component={Archive} name={props.intl.formatMessage({ id: "navigations.archive" })} />
					<PrivateRoute path={['/resumes/:id/edit' , '/clients/resumes/:id/edit' , "/employees/resumes/:id/edit"]} exact component={ResumeEditPage} name={props.intl.formatMessage({ id: "navigations.resume" })} />
					{/* <PrivateRoute path={['/resumes/:id/edit', '/employees/:id/edit']} exact component={ResumeEditPage} name={props.intl.formatMessage({ id: "navigations.resume" })} /> */}
					<PrivateRoute path='/resumes/create' exact component={ResumeCreatePage} name={props.intl.formatMessage({ id: "navigations.resume" })} />
					<PrivateRoute path='/areas' exact component={AreaPage} name={props.intl.formatMessage({ id: "navigations.area" })} />
					<PrivateRoute path='/areas/:id/edit' exact component={AreaEditPage} name={props.intl.formatMessage({ id: "navigations.area" })} />
					<PrivateRoute path={'/areas/create'} exact component={AreaCreatePage} name={props.intl.formatMessage({ id: "navigations.area" })} />
					
					<PrivateRoute path='/areas/:id/skills' exact component={SkillPage} name={props.intl.formatMessage({ id: "navigations.skill" })} />
					
					<PrivateRoute path='/skills' exact component={SkillPage} name={props.intl.formatMessage({ id: "navigations.skill" })} />
					<PrivateRoute path='/skills/:id/edit' exact component={SkillEditPage} name={props.intl.formatMessage({ id: "navigations.skill" })} />
					{/* <PrivateRoute path='/skills/create' exact component={SkillCreatePage} name={props.intl.formatMessage({ id: "navigations.skill" })} /> */}
					<PrivateRoute path={['/areas/:id/skills/create', '/areas/skill/create']} exact component={SkillCreatePage} name={props.intl.formatMessage({ id: "navigations.skill" })} />
					<PrivateRoute path='/languages' exact component={LanguagePage} name={props.intl.formatMessage({ id: "navigations.language" })} />
					<PrivateRoute path='/languages/:id/edit' exact component={LanguageEditPage} name={props.intl.formatMessage({ id: "navigations.language" })} />
					<PrivateRoute path='/languages/create' exact component={LanguageCreatePage} name={props.intl.formatMessage({ id: "navigations.language" })} />
					<PrivateRoute path='/areaskillspage' exact component={AreaSkillsPage} name={props.intl.formatMessage({ id: "navigations.area" })} />
					<Route component={NotFoundPage} />
				</Switch>
			</div>
		</Router>
	)
}

const mapStateToProps = (state) => ({ isAuthenticated: !!state.auth.token })

ApplicationRouter.propTypes = {
	isAuthenticated: PropTypes.bool.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func
	}).isRequired
}

export default connect(mapStateToProps)(injectIntl(ApplicationRouter))
