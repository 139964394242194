import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { searchSkills } from "../../../actions/skillsActions";
import {
	AllAreaSkills,
	searchAreas,
	searchAreaSkills,
} from "../../../actions/areasActions";
import { get } from "lodash";
import DeleteModal from "../../modals/DeleteModal";
import { skills } from "../../../actions/types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HandleError from "../../../services/HandleError";
import axios from "axios";

const EmployeeAbilities = ({
	isSubmitting,
	errors,
	touched,
	handleChange,
	values,
	setFieldValue,
	setFieldTouched,
	searchSkills,
	searchAreas,
	searchAreaSkills,
	AllAreaSkills,
}) => {
	const levels = [
		{ value: "1", label: "1" },
		{ value: "2", label: "2" },
		{ value: "3", label: "3" },
		{ value: "4", label: "4" },
		{ value: "5", label: "5" },
		{ value: "6", label: "6" },
		{ value: "7", label: "7" },
		{ value: "8", label: "8" },
		{ value: "9", label: "9" },
		{ value: "10", label: "10" },
	];

	const preferabilities = [
		{ value: "wantToWork", label: "want to work", icon: <i className="material-icons" style={{backgroundColor: "#080", color: "#080", borderRadius: "50%", marginRight: "5px", width: '10px', height: "10px", fontSize: "10px"}}>radio_button_unchecked</i>},
		{ value: "neutral", label: "neutral" },
		{ value: "preferNotToWork", label: "prefer not to work" },
	];

	const [skillsOptions, setSkillsOptions] = useState(null);
	const [areasOptions, setAreasOptions] = useState(null);
	const [showDelete, setShowDeleteModal] = useState(false);
	const [Selected, setSelected] = useState({}); // to store what edu user wanna delete
	const [showButtonPlus, setShowButtonPlus] = useState(0);
	useEffect(() => {
		if (values?.abilities?.length > 0) {
			setShowButtonPlus(values.abilities.length - 1);
		} else {
		}
	}, [values?.abilities?.length]);
	useEffect(() => {
		const getDefaultSkillOptions = async () => {
			const skillsOptions = await searchSkills("");

			// if (values && values.abilities && values.abilities.length > 0) {
			// 	values.abilities.map((value) => skillsOptions.map((skills) => {
			// 		if (value.skills && value.skills.name === skills.name) {
			// 			skills.isdisabled = true
			// 		}
			// 		return skills
			// 	}))
			// }
			setSkillsOptions(skillsOptions);
		};
		getDefaultSkillOptions();
		const getDefaultAreaOptions = async () => {
			const areasOptions = await searchAreas("");
			// if (values && values.abilities && values.abilities.length > 0) {
			// 	values.abilities.map((value) => areasOptions.map((area) => {
			// 		if (value.area && value.area.name === area.name) {
			// 			area.isdisabled = true
			// 		}
			// 		return area
			// 	}))
			// }
			setAreasOptions(areasOptions);
		};
		getDefaultAreaOptions();
	}, []);

	useEffect(() => {
		// const getDefaultSkillOptions = async () => {
		// 	const skillsOptions = await searchSkills("")
		// 	if (values && values.abilities && values.abilities.length > 0) {
		// 		values.abilities.map((value) => skillsOptions.map((skill) => {
		// 			if (value.skill && value.skill.name === skill.name) {
		// 				skill.isdisabled = true
		// 			}
		// 			return skill
		// 		}))
		// 	}
		// 	setSkillsOptions(skillsOptions)
		// }

		const getDefaultAreaOptions = async () => {
			const areasOptions = await searchAreas("");
			// if (values && values.abilities && values.abilities.length > 0) {
			// 	values.abilities.map((value) => areasOptions.map((area) => {
			// 		console.log("area", area);
			// 		if (value.area && value.area.name === area.name) {
			// 			area.isdisabled = true
			// 		}
			// 		return area
			// 	}))
			// }
			setAreasOptions(areasOptions);
		};
		// getDefaultSkillOptions()
		getDefaultAreaOptions();
	}, [values.abilities]);

	const handleDrageEnd = (result) => {
		const items = [...values.abilities];
		let itemsIDs = items.map((itm) => itm.id);
		const [removedItem] = items.splice(result.source.index, 1);
		let distnatIndex = result.destination
			? result.destination.index
			: result.source.index;
		items.splice(distnatIndex, 0, removedItem);

		// fore reorder items by id
		for (let i = 0; i < items.length; i++) {
			items[i].id = itemsIDs[i];
		}
		setFieldValue("abilities", items);
	};

	const handleAddAbility = () => {
		if (values?.abilities?.length > 0) {
			setShowButtonPlus(values.abilities.length);
		} else {
			setShowButtonPlus(0);
		}
		const valueFields = values.abilities ? values.abilities : [];

		valueFields.push({
			area: null,
			skill: null,
			level: null,
		});
		const newValueFields = valueFields.map((field) => {
			if (field.area) {
				field.area.isdisabled = true;
			}
			if (field.skill) {
				field.skill.isdisabled = true;
			}
			return field;
		});

		setFieldValue("abilities", [...newValueFields]);

		// to scroll into new skill added
		setTimeout(() => {
			let skills = document.querySelectorAll(".skill-block");
			skills[skills.length - 1].scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		}, 50);
	};

	const handleRemoveData = () => {
		if (values.abilities.length > 0) {
			setShowButtonPlus(showButtonPlus - 1);
		} else {
			setShowButtonPlus(0);
		}
		let valueFields = values[Selected.Type];
		if (valueFields[Selected.id].area) {
			if (
				valueFields[Selected.id].area.isdisabled &&
				valueFields[Selected.id].area.isdisabled == true
			) {
				valueFields[Selected.id].area.isdisabled = false;
			}
		}
		valueFields = valueFields.filter((field, fid) => fid !== Selected.id);
		setFieldValue(Selected.Type, [...valueFields]);
		setShowDeleteModal(false);
	};

	const handleChangeArea = async (id, e) => {
		setFieldValue(`abilities[${id}][area]`, e);
		if (e !== null) {
			setFieldValue(`abilities[${id}][area_id]`, e.id);
			// const skills = await searchAreaSkills(e.id);
			const skills = await AllAreaSkills(e.id);
			console.log({ skills });
			if (values && values.abilities && values.abilities.length > 0) {
				values.abilities.map((value) =>
					skills.map((skill) => {
						if (value.skill && value.skill.name === skill.name) {
							skill.isdisabled = true;
						}
						return skill;
					})
				);
			}
			setSkillsOptions(skills);
			setFieldValue(`abilities[${id}][skill]`, null);
			setFieldValue(`abilities[${id}][skill_id]`, null);
		} else {
			setFieldValue(`abilities[${id}][area]`, null);
			setFieldValue(`abilities[${id}][skill]`, null);
			setFieldValue(`abilities[${id}][skill_id]`, null);
			setFieldTouched(`abilities[${id}][area]`, true);
			const skillsOptions = await searchSkills("");
			setSkillsOptions(skillsOptions);
		}
	};

	const handleChangeSkill = (id, e) => {
		setFieldValue(`abilities[${id}][skill]`, e);

		if (e !== null) {
			setFieldValue(`abilities[${id}][skill_id]`, e.id);
		} else {
			setFieldTouched(`abilities[${id}][skill]`, true);
		}
	};

	const handleChangeSkillLevel = (id, e) => {
		setFieldValue(`abilities[${id}][level_get]`, e);
		setFieldValue(`abilities[${id}][level]`, e.value);

		if (!e) {
			setFieldTouched(`abilities[${id}][level_get]`, true);
		}
	};

	const handleChangePreferability = (id, e) => {
		setFieldValue(`abilities[${id}][preferability]`, e.value);
	}
	const debounceSearch = debounce((e, id) => {
		return searchSkills(e, id);
	}, 100);
	const handleCloseDelete = () => {
		setShowDeleteModal(false);
	};
	return (
		<>
			{showDelete && (
				<DeleteModal
					isShown={showDelete}
					handleClose={handleCloseDelete}
					title={`Skill ${Selected.id + 1}`}
					handleDelete={handleRemoveData}
					// isDeleting={client_resumes.isDeleting}
				/>
			)}
			<Row>
				<Col>
					<h4 className="respnsiveHeader">
						<FormattedMessage id="labels.abilities" />
					</h4>
				</Col>
				{!values?.abilities?.length && (
					<Col className="mb-3">
						<Button
							onClick={handleAddAbility}
							disabled={isSubmitting}
							variant="outline-primary"
							className="btn-plus"
						>
							+
						</Button>
					</Col>
				)}
			</Row>
			<div
				className={`select-input ${errors.abilities && "is-invalid"}`}
			></div>
			<Form.Control.Feedback type="invalid">
				{errors.abilities}
			</Form.Control.Feedback>

			{!isSubmitting && values?.abilities?.length > 0 && (
				<DragDropContext onDragEnd={handleDrageEnd}>
					<Droppable droppableId="employee-data">
						{(provider) => (
							<div
								className="form__large employee-form employee-data"
								{...provider.droppableProps}
								ref={provider.innerRef}
							>
								{values.abilities.map((ability, id) => {
									return (
										<Draggable
											key={id}
											draggableId={id.toString()}
											index={id}
										>
											{(provider) => (
												<div
													className="counter-spacing skill-block"
													{...provider.draggableProps}
													{...provider.dragHandleProps}
													ref={provider.innerRef}
												>
													<Row className="align-items-bottom mb-3">
														{console.log({
															ability,
														})}
														<Col>
															<h5
																style={{
																	color: "#007bff",
																}}
															>
																<FormattedMessage id="labels.ability" />{" "}
																{id + 1}
															</h5>
														</Col>

														<Col className="col-auto ml-auto">
															<Button
																onClick={() => {
																	setSelected(
																		{
																			id,
																			Type: "abilities",
																		}
																	);
																	setShowDeleteModal(
																		true
																	);
																}}
																variant="danger"
															>
																<FormattedMessage id="buttons.remove_ability" />
															</Button>
														</Col>
													</Row>
													<Row>
														<Col className="m-a col-xl-4 col-12">
															<Form.Group
																controlId="input_skill_id"
																className="floating-label"
															>
																<AsyncSelect
																	id="area_id"
																	menuPlacement="auto"
																	cacheOptions
																	loadOptions={
																		searchAreas
																	} //debounce(searchAreas, 100)
																	defaultOptions={
																		areasOptions
																	}
																	isClearable
																	getOptionValue={(
																		item
																	) =>
																		item.id
																	}
																	getOptionLabel={(
																		item
																	) =>
																		item.name
																	}
																	onChange={(
																		e
																	) => {
																		handleChangeArea(
																			id,
																			e
																		);
																	}}
																	value={
																		ability.area
																	}
																	placeholder="Area"
																	className="select-input"
																	isOptionDisabled={(
																		option
																	) =>
																		option.isdisabled
																	}
																/>
															</Form.Group>
														</Col>

														<Col className="m-a col-xl-4 col-12">
															<Form.Group
																controlId="input_skill_id"
																className="floating-label"
															>
																<AsyncSelect
																	id="skill_id"
																	menuPlacement="auto"
																	cacheOptions
																	loadOptions={(
																		e
																	) =>
																		searchSkills(
																			e,
																			ability.area_id
																		)
																	}
																	defaultOptions={
																		ability.area &&
																		ability
																			.area
																			.skills
																			? ability
																					.area
																					.skills
																			: skillsOptions
																	}
																	isClearable
																	getOptionValue={(
																		item
																	) =>
																		item.id
																	}
																	getOptionLabel={(
																		item
																	) =>
																		item.name
																	}
																	onChange={(
																		e
																	) => {
																		handleChangeSkill(
																			id,
																			e
																		);
																	}}
																	value={
																		ability.skill
																	}
																	placeholder="Skill"
																	className="select-input"
																	isOptionDisabled={(
																		option
																	) =>
																		option.isdisabled
																	}
																/>
															</Form.Group>
														</Col>

														<Col className="m-a col-xl-4 col-12">
															<Form.Group
																controlId={`abilities.${id}.level`}
																className="floating-label mb-0"
															>
																<Select
																	menuPlacement="auto"
																	options={
																		values?.employee_level?.name === 'Junior 1' ?  levels.slice(0, 6)
																		: values?.employee_level?.name === 'Junior 2' ? levels.slice(0, 7)
																		: values?.employee_level?.name === 'Intermediate 1' ? levels.slice(0, 8)
																		: values?.employee_level?.name === 'Intermediate 2' ? levels.slice(0, 9)
																		: values?.employee_level?.name === 'Senior 1' ? levels.slice(0, 10)
																		: values?.employee_level?.name === 'Senior 2' ? levels.slice(0, 10) : levels
																	}
																	onChange={(
																		e
																	) =>
																		handleChangeSkillLevel(
																			id,
																			e
																		)
																	}
																	value={
																		ability.level_get
																	}
																	placeholder="Level"
																	className="select-input"
																	isRequired
																/>
															</Form.Group>
														</Col>
														<Col className="m-a col-xl-4 col-12">
															<Form.Group
																controlId={`abilities.${id}.preferability`}
																className="floating-label mb-0"
															>
																<Select
																	menuPlacement="auto"
																	options={preferabilities}
																	onChange={(e) =>
																	handleChangePreferability(id,e)
																	}
																	value={preferabilities.find(
																		(item) => item.label === ability.preferability
																	)}
																	getOptionLabel={e => (
																		<div style={{ display: 'flex', alignItems: 'center' }}>
																		  {e.icon}
																		  <span style={{ marginLeft: 5 }}>{e.label}</span>
																		</div>
																	  )}
																	placeholder="preferability"
																	className="select-input"
																	isRequired
																/>
															</Form.Group>
														</Col> 		
														</Row>
														<Row>							
														{showButtonPlus ===
															id && (
															<Col className="mb-3">
																<Button
																	onClick={
																		handleAddAbility
																	}
																	disabled={
																		isSubmitting
																	}
																	variant="outline-primary"
																	className="btn-plus mt-3"
																>
																	+
																</Button>
															</Col>
														)}
													</Row>
												</div>
											)}
										</Draggable>
									);
								})}
								{provider.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			)}
		</>
	);
};

EmployeeAbilities.propTypes = {
	values: PropTypes.shape({
		abilities: PropTypes.array,
	}).isRequired,
	errors: PropTypes.shape({
		abilities: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		abilities: PropTypes.bool,
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	searchSkills: PropTypes.func.isRequired,
	searchAreas: PropTypes.func.isRequired,
	searchAreaSkills: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	employees: state.employees,
});

// export default connect(mapStateToProps, { searchSkills })(EmployeeAbilities)
export default connect(mapStateToProps, {
	searchAreas,
	searchSkills,
	searchAreaSkills,
	AllAreaSkills,
})(EmployeeAbilities);
