import PropTypes from "prop-types"
import {connect} from "react-redux"
import {Button, Col, Form, Row} from "react-bootstrap"
import {FormattedMessage} from "react-intl"
import AsyncSelect from "react-select/async"
import debounce from "lodash/debounce"
import Select from "react-select"
import React, {useEffect, useState} from "react"
import {searchLanguages} from "../../../actions/languagesActions"
import {get} from "lodash"
import DeleteModal from "../../modals/DeleteModal"


const EmployeeLanguages = ({
							   isSubmitting,
							   errors,
							   touched,
							   handleChange,
							   values,
							   setFieldValue,
							   setFieldTouched,
							   searchLanguages
						   }) => {
	const levels = [
		{value: "1", label: "1"},
		{value: "2", label: "2"},
		{value: "3", label: "3"},
		{value: "4", label: "4"},
		{value: "5", label: "5"},
		{value: "6", label: "6"},
		{value: "7", label: "7"},
		{value: "8", label: "8"},
		{value: "9", label: "9"},
		{value: "10", label: "10"}
	]

	const [languagesOptions, setLanguagesOptions] = useState(null)
	const [showDelete, setShowDeleteModal] = useState(false)
	const [Selected , setSelected] = useState({}) // to store what edu user wanna delete 
    const [showButtonPlus, setShowButtonPlus] = useState(0)
    useEffect(() => {
        if(values?.languages?.length > 0) {
            setShowButtonPlus(values.languages.length - 1)
        } else {
            console.log("Failed showButtonPlus");
        }
    })
	useEffect(() => {
		const getDefaultLanguageOptions = async () => {
			const languagesOptions = await searchLanguages("")
			if (values && values.languages && values.languages.length > 0) {
				values.languages.map((value) => languagesOptions.map((language) => {
					if (value.language && value.language.name === language.name) {
						language.isdisabled = true
					}
					return language
				}))
			}
			setLanguagesOptions(languagesOptions)
		}
		getDefaultLanguageOptions()
	}, [])
	useEffect(() => {
		const getDefaultLanguageOptions = async () => {
			const languagesOptions = await searchLanguages("")
			if (values && values.languages && values.languages.length > 0) {
				values.languages.map((value) => languagesOptions.map((language) => {
					if (value.language && value.language.name === language.name) {
						language.isdisabled = true
					}
					return language
				}))
			}
			setLanguagesOptions(languagesOptions)
		}
		getDefaultLanguageOptions()

	}, [values.languages])

	const handleAddAbility = () => {
		if(values?.languages?.length > 0){
            setShowButtonPlus(values.languages.length);
        } else {
        setShowButtonPlus(0)
        }
		const valueFields = values.languages ? values.languages : []
		console.log("LANGUAGES", valueFields)

		valueFields.push({
			language: null,
			level: null,
		})

		const newValueFields = valueFields.map((field) => {
			if (field.language) {
				console.log("TRUE")
				field.language.isdisabled = true;
			}
			return field
		})

		setFieldValue("languages", [...newValueFields])
		// to scroll into new langugae added 
		setTimeout(() => {
			let  Langs = document.querySelectorAll('.counter-spacing');
			Langs[Langs.length-1].scrollIntoView({block:'center', behavior:'smooth'});
		},50)
	}


	const handleRemoveData = () => {
		if(values.languages.length > 0){
			setShowButtonPlus(showButtonPlus - 1)
			} else {
			setShowButtonPlus(0)
			}
		let valueFields = values[Selected.Type];
		if (valueFields[Selected.id].area) {
			if (valueFields[Selected.id].area.isdisabled && valueFields[Selected.id].area.isdisabled == true) {

				valueFields[Selected.id].area.isdisabled = false
			}
		}
		valueFields = valueFields.filter((field, fid) => fid !== Selected.id);
		setFieldValue(Selected.Type, [...valueFields])
		setShowDeleteModal(false)
	}

	const handleChangeLanguage = (id, e) => {
		setFieldValue(`languages[${id}][language]`, e)
		
		if (e !== null) {
			setFieldValue(`languages[${id}][language_id]`, e.id)
		} else {
			setFieldTouched(`languages[${id}][language]`, true)
		}
	}

	const handleChangeLanguageLevel = (id, e) => {
		setFieldValue(`languages[${id}][level_get]`, e)
		setFieldValue(`languages[${id}][level]`, e.value)

		if (!e) {
			setFieldTouched(`languages[${id}][level_get]`, true)
		}
	}
	const handleCloseDelete = () => {
		setShowDeleteModal(false)
	}
	return (
		<>
			{showDelete&&
				<DeleteModal
				isShown={showDelete}
				handleClose={handleCloseDelete}
				title={`Language ${Selected.id+1}`}
				handleDelete={handleRemoveData}
				// isDeleting={client_resumes.isDeleting}
				/>
			}
			<Row>
				<Col>
					<h4 className="respnsiveHeader"><FormattedMessage id="labels.languages"/></h4>
				</Col>
				{!values?.languages?.length &&  
					<Col className="mb-3">
						<Button onClick={handleAddAbility} disabled={isSubmitting}
								variant="outline-primary" className="btn-plus">+</Button>
					</Col>
				}
			</Row>
			<div className={`select-input ${errors.languages && "is-invalid"}`}></div>
			<Form.Control.Feedback type='invalid'>{errors.languages}</Form.Control.Feedback>

			{!isSubmitting && values?.languages?.length > 0 &&
				<div className="form__large employee-form employee-data">
					<div>
						{
							values.languages.map((language, id) => {
								return (
									<div key={`language-${get(language, "language.name", id)}`} className="counter-spacing">
										<Row className="align-items-bottom mb-3">
											<Col>
												<h5 style={{color: "#007bff"}}><FormattedMessage
													id="labels.language"/> {id + 1}</h5>
											</Col>

											<Col className="col-auto ml-auto">
												<Button
                                                onClick={() =>  {setSelected({id , Type:"languages"}) ; setShowDeleteModal(true)} }
													variant="danger"><FormattedMessage id="buttons.remove_language"/>
												</Button>
											</Col>
										</Row>
										<Row>
											<Col className="m-a col-md-6 col-sm-12">
												<Form.Group controlId="input_language_id" className="floating-label">
													<AsyncSelect
														id="language_id"
														menuPlacement="auto"
														cacheOptions
														loadOptions={searchLanguages} //debounce(searchLanguages, 100)
														defaultOptions={languagesOptions}
														isClearable
														getOptionValue={(item) => (item.id)}
														getOptionLabel={(item) => (item.name)}
														onChange={(e) => {
															handleChangeLanguage(id, e)
														}}
														value={language.language}
														placeholder="Language"
														className="select-input"
														isOptionDisabled={(option) => option.isdisabled}
													/>
												</Form.Group>
											</Col>

											<Col className="m-a col-md-6 col-sm-12 col-12">
												<Form.Group controlId={`languages.${id}.level`}
															className="floating-label mb-0">
													<Select
														menuPlacement="auto"
														options={levels}
														onChange={(e) => handleChangeLanguageLevel(id, e)}
														value={language.level_get}
														placeholder="Level"
														className="select-input"
														isRequired
													/>
												</Form.Group>
											</Col>
                                            {
                                               showButtonPlus === id  &&  											
											<Col className="mb-3">
												<Button onClick={handleAddAbility} disabled={isSubmitting}
														variant="outline-primary" className="btn-plus mt-3">+</Button>
											</Col>
											}
										</Row>
									</div>
								)
							})
						}
					</div>
				</div>}
		</>
	)
}

EmployeeLanguages.propTypes = {
	values: PropTypes.shape({
		languages: PropTypes.array
	}).isRequired,
	errors: PropTypes.shape({
		languages: PropTypes.string
	}).isRequired,
	touched: PropTypes.shape({
		languages: PropTypes.bool
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	searchLanguages: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	employees: state.employees
})

export default connect(mapStateToProps, {searchLanguages})(EmployeeLanguages)
