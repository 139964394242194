import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table"
import { connect } from "react-redux"
import { getSkillsAction, deleteSkillAction, getSkillsArea, FilterSkills } from "../../actions/skillsActions"
import SkillsTableActionsCell from "../elements/SkillsTableActionsCell"
import DeleteModal from "../modals/DeleteModal"
import { searchAreaSkills } from "../../actions/areasActions"
import {useParams} from 'react-router-dom'
import { FilterField } from "../../helpers/GeneralFillter"
const SkillsTable = ({ skills , getSkillsAction, deleteSkillAction  , getSkillsArea , FilterSkills}) => {
	const params = useParams();
	let AreaId = params.id;


	let index = -1;
	let pageNr = skills.data.length ? skills.meta.pagination.current_page - 1 : 0
	let totalPages = skills.data.length ? skills.meta.pagination.total_pages : 1
	const [showDelete, setShowDeleteModal] = useState(-1)
	const [counterPages, setCounterPages] = useState(100)

	console.log("skills.data.length", skills.data.length);
	useEffect(() => {
		getSkillsArea({ AreaID:AreaId , pageSize: counterPages, filters:[], page: 1 })
	},[]);
	useEffect(()=> {
		pageNr = totalPages - 1
		const { filters } = skills
		getSkillsArea({AreaID:AreaId , pageSize: counterPages, filters, page: 1 })
		if(!skills.data.length) {
			const { filters } = skills
			getSkillsArea({AreaID:AreaId , pageSize: counterPages, filters, page: 1 })
		}
	}, [counterPages])

	const columns = [
		{ Header: "ID", filterable: false, accessor: "id", Cell: cellProps => cellProps.value || "" },
		{ Header: "Skill Name", filterable: true , Filter:FilterField, accessor: "name", Cell: cellProps => cellProps.value || "" },
		{ Header: "Area Name", filterable: false, accessor: "area", Cell: cellProps => cellProps.value.name || "" },
		{
			Header:"Actions",
			filterable: false,
			Cell: cellProps => <SkillsTableActionsCell
				cellProps={cellProps}
				setShowDeleteModal={setShowDeleteModal}
			/>,
		}
	]

	const handleCloseDelete = async () => {
		setShowDeleteModal(-1);
	}

	const handlePaginateFetch = (page) => {
		const { filters } = skills
		// getSkillsAction({ pageSize: 15, sorted: null, filters, page: page + 1 });
		getSkillsArea({ AreaID:AreaId , pageSize: counterPages, filters, page: page + 1 })

	}

	const handleDelete = async () => {
		const { id } = skills.data[index]
		await deleteSkillAction(id, handleCloseDelete);
		const { filters } = skills
		// getSkillsAction({ pageSize: 15, sorted: null, filters, page: page + 1 });
		await getSkillsArea({ AreaID:AreaId , pageSize: counterPages, filters, page: pageNr + 1 })

	}

	if (showDelete >= 0) {
		index = showDelete
	}

	const onRowClick = (state, rowInfo , column , instance) => {
		if (rowInfo && rowInfo.row) {
			setCounterPages(rowInfo.pageSize)
			return {
			}
		}else{
			return {}
		}
	}

	// filter data by serverside
	const FilterData = async (column, value) => {
		if(column.length){
			FilterSkills(column[0].value , AreaId);
		}else{
			FilterSkills("",AreaId)
		}
	}
	return (
		<div className="table-style">
		
			<ReactTable
				data={skills.data}
				loading={skills.isLoading}
				columns={columns}
				defaultPageSize={100}
				pages={totalPages}
				page={pageNr}
				filterable
				manual // for handle pagination
				onFilteredChange={FilterData} 
				showPageSizeOptions={true}
				pageSizeOptions= {[5, 10, 15, 20, 25, 50, 100]}
				// sortable={false}
				className="striped-highlight cl-ta remove-id unique-filter-table"
				onPageChange={handlePaginateFetch}
				getTdProps= {onRowClick}
			/>

			<DeleteModal
				isShown={showDelete !== -1}
				handleClose={handleCloseDelete}
				title={skills.data[index] ? `Skill ${skills.data[index].name}` : ""}
				handleDelete={handleDelete}
				isDeleting={skills.isDeleting}
			/>
		</div>
	)
}

SkillsTable.propTypes = {
	getSkillsAction: PropTypes.func.isRequired,
	deleteSkillAction: PropTypes.func.isRequired,
	searchAreaSkills: PropTypes.func.isRequired,
	areaSkills:PropTypes.array.isRequired,
	skills: PropTypes.shape({
		data: PropTypes.array,
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number,
				total_pages: PropTypes.number
			})
		}),
		filters: PropTypes.array,
		isLoading: PropTypes.bool,
		hasError: PropTypes.bool,
		isRestoring: PropTypes.bool,
		isDeleting: PropTypes.bool
	}).isRequired,
}

const mapStateToProps = (state) => ({
	skills: state.skills
})

export default connect(mapStateToProps, { getSkillsAction, deleteSkillAction , searchAreaSkills , getSkillsArea  , FilterSkills})(SkillsTable)

