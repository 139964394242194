import { Form as Formik, withFormik } from "formik"
import PropTypes from "prop-types"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { loginAction } from "../../actions/authActions"

const LoginForm = ({ isSubmitting, errors, touched, handleChange, values }) => (
	<Formik className='login-form public-form'>
		<div className="inputs-wrapper">
			<Form.Group controlId="formEmail">
				<Form.Label>Email address</Form.Label>
				<Form.Control type="email" value={values.email} onChange={handleChange} name="email" isInvalid={!!errors.email && touched.email} />
				<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
			</Form.Group>
			<Form.Group controlId="formPassword">
				<Form.Label>Password</Form.Label>
				<Form.Control type="password" value={values.password} onChange={handleChange} name="password" isInvalid={!!errors.password && touched.password} />
				<Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
			</Form.Group>
		</div>
		{isSubmitting}
		<Button type="submit" size="lg" className="btn-primary mt-3" disabled={isSubmitting} variant="dark">Login</Button>
	</Formik>
)

const Schema = Yup.object().shape({
	email: Yup.string().email("Not a valid email").required("This field is required"),
	password: Yup.string().min(5).required()
})

const FormikApp = withFormik({
	mapPropsToValues({ email, password }) {
		return {
			email: email || "",
			password: password || ""
		}
	},
	validationSchema: Schema,
	handleSubmit(values, { props, setSubmitting, setErrors }) {
		props.loginAction(values, setSubmitting, setErrors)
	}
})(LoginForm)

LoginForm.propTypes = {
	values: PropTypes.shape({
		email: PropTypes.string,
		passwrod: PropTypes.string,
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	errors: PropTypes.shape({}).isRequired,
	touched: PropTypes.shape({}).isRequired
}
const mapStateToProps = (state) => ({})
export default connect(mapStateToProps, { loginAction })(FormikApp)
